import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ErrorSnackbar(props) {
    const { isOpen, content, onClose } = props
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (isOpen) {
            handleClick()
        }
    }, [isOpen])

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        // if (reason === 'clickaway') {
        //     return;
        // }
        setOpen(false);
        onClose()
    };

    return (
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                {content}
            </Alert>
        </Snackbar>
    )
}