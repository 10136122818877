import { Grid, Typography } from "@mui/material";
import "./HomePageAnnouncement.css"
import { useMediaQuery } from 'react-responsive';

export default function HomePageAnnouncement(props) {
    const scrollToNews = props.newsScroll
    const isMobile = useMediaQuery({ query: `(max-width: 768px)` })

    return (
        <Grid className="announcement-wrapper" container spacing={0} style={isMobile ? ({ backgroundImage: 'url("images/homepage-announcement-mobile-background.png")' }) : null}>
            <Grid item xs={12} className="homepage-announcement-content-wrapper">
                <Grid container spacing={0} justifyContent="flex-end">
                    <Grid item md={4} xs={12} data-aos={isMobile ? "fade-down" : "fade-right"} data-aos-duration="1500" className="homepage-announcement-col-right">
                        <img className="homepage-announcement-tam-tuot" alt="Logo tam tuot" src="images/homepage-announcement-tamtuot.png" />
                        <img className="homepage-announcement-grass" alt="Logo co" src="images/homepage-announcement-grass.png" />
                        <img className="homepage-announcement-bird" alt="Logo chim" src="images/homepage-announcement-bird.png" />
                    </Grid>
                    <Grid item md={6} xs={12} data-aos={isMobile ? "fade-up" : "fade-left"} data-aos-duration="1500" className="homepage-announcement-content" style={{ backgroundImage: 'url("images/homepage-announcement-board.png")' }}>
                        <img className="homepage-announcement-board-icon-flowers" alt="Logo bong hoa" src="images/homepage-announcement-flowers.png" />
                        <img className="homepage-announcement-board-icon-bulk" alt="Logo bong den" src="images/homepage-announcement-bulk.png" />
                        <img className="homepage-announcement-board-icon-text" alt="Logo chu" src="images/homepage-announcement-text-icon.png" />
                        <Grid container spacing={isMobile ? 2 : 3}>
                            <Grid item xs={12}>
                                <Typography className="announcement-title">Thông báo từ Ban Tổ chức</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className="announcement-text">
                                    Chưa có thông báo mới,
                                    Hãy chờ thông tin từ Ban Tổ chức bạn nhé!
                                </Typography>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent={isMobile ? "flex-start" : "center"}>
                                <button className="announcement-detail-btn" onClick={scrollToNews}>Xem</button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}