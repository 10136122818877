import "./SubmissionSearchPageHeader.css";
import { Grid, Typography, CardMedia } from "@mui/material";
import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import axios from "axios";
import useMediaQuery from "@mui/material/useMediaQuery";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#FDE6E4",
  boxShadow: 24,
};

export default function SubmissionSearchPageHeader(props) {
  const isMobile = useMediaQuery("(max-width:768px)");
  const isSearched = props.isSearched;
  const getSearchType = props.searchType;
  const getSearchResultData = props.searchResultData;

  const [searchType, updateSearchType] = React.useState("guest-profile");

  const changeSearchType = (e) => {
    updateSearchType(e.target.value);
    getSearchType(e.target.value);
  };

  const [errorModalOpen, updateErrorModalOpen] = React.useState(false);

  const handleErrorModalOpen = () => {
    updateErrorModalOpen(true);
  };

  const handleErrorModalClose = () => {
    updateErrorModalOpen(false);
  };

  const [guestInfo, updateGuestInfo] = React.useState({
    guestName: "",
    guestDob: "",
    guestPhone: "",
  });

  const updateGuestName = (e) => {
    updateGuestInfo((prevState) => ({
      ...prevState,
      guestName: e.target.value,
    }));
  };

  const updateGuestDob = (e) => {
    updateGuestInfo((prevState) => ({
      ...prevState,
      guestDob: e.target.value,
    }));
  };

  const updateGuestPhone = (e) => {
    updateGuestInfo((prevState) => ({
      ...prevState,
      guestPhone: e.target.value,
    }));
  };

  const [guestCode, updateGuestCode] = React.useState("");

  const handleUpdateGuestCode = (e) => {
    updateGuestCode(e.target.value);
  };

  const handleGuestInfoSearch = () => {
    if (!guestInfo.guestName || !guestInfo.guestDob || !guestInfo.guestPhone) {
      handleErrorModalOpen();
      return;
    } else {
      axios
        .get(
          process.env.REACT_APP_SERVER_API_ROOT_URL +
            `/contest-entry/?candidateName=${guestInfo.guestName}&candidateDob=${guestInfo.guestDob}&userPhone=${guestInfo.guestPhone}`
        )
        .then((respone) => {
          getSearchResultData(respone.data.rows);
          isSearched(respone.data.count > 0);
        })
        .catch((error) => console.log(error));
    }
  };

  const handleGuestCodeSearch = (e) => {
    e.preventDefault();
    if (!guestCode) {
      handleErrorModalOpen();
      return;
    } else {
      axios
        .get(
          process.env.REACT_APP_SERVER_API_ROOT_URL +
            `/contest-entry/?code=${guestCode}`
        )
        .then((respone) => {
          getSearchResultData(respone.data.rows);
          isSearched(respone.data.count > 0);
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <React.Fragment>
      <div className="submission-search-page-header-wrapper">
        <img
          className="submission-search-page-search-background"
          alt="submission search page search background"
          src="images/SubmissionSearchPage/search-background.png"
        />
        {isMobile ? (
          <CardMedia
            component="img"
            image="/images/SubmissionSearchPage/bg-top-mobile.png"
            sx={{ position: "relative" }}
          ></CardMedia>
        ) : (
          <CardMedia
            component="img"
            image="/images/Information/bg-top.png"
            sx={{ position: "relative" }}
          ></CardMedia>
        )}
        <Grid
          container
          justifyContent="center"
          rowSpacing={4}
          className="search-box-wrapper"
        >
          <Grid
            item
            xs={11}
            md={6}
            sx={{ textAlign: "center", position: "relative", mt: 3 }}
          >
            <Box
              className="search-title-box"
              sx={{ backgroundImage: "url('/images/SubmitExam/bg-title.png')" }}
            >
              <Typography variant="crocante36" color="yellow.main">
                Tìm kiếm bài tham gia
              </Typography>
            </Box>
          </Grid>
          <img
            className="submission-search-page-search-box-paper-icon"
            alt="submission search page search box paper icon"
            src="images/SubmissionSearchPage/search-box-paper-icon.png"
          />

          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={0}>
              <Grid item xs={10} className="search-box-input-wrapper">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        className="search-type-radio-input-label"
                      >
                        Nội dung tìm kiếm *
                      </FormLabel>
                      <RadioGroup
                        onChange={changeSearchType}
                        row
                        aria-label="gender"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          checked={searchType === "guest-profile"}
                          value="guest-profile"
                          control={<Radio color="yellow" />}
                          label="Thông tin cá nhân"
                          className="search-type-radio-input"
                        />
                        <FormControlLabel
                          checked={searchType === "guest-code"}
                          value="guest-code"
                          control={<Radio color="yellow" />}
                          label="Mã tham gia"
                          className="search-type-radio-input"
                        />
                      </RadioGroup>
                      <span className="search-type-bottom-line"></span>
                    </FormControl>
                  </Grid>
                  {searchType === "guest-profile" && (
                    <Grid item xs={12}>
                      <form>
                        <Grid container justifyContent="center" spacing={2}>
                          <Grid item xs={12} md={4}>
                            <label
                              htmlFor="guest-name"
                              className="search-box-guest-info-input-title"
                            >
                              Họ tên thí sinh *
                            </label>
                            <br></br>
                            <input
                              type="text"
                              onChange={updateGuestName}
                              value={guestInfo.guestName}
                              id="guest-name"
                              name="guest-name"
                              className="search-box-guest-info-input"
                            ></input>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <label
                              htmlFor="guest-dob"
                              className="search-box-guest-info-input-title"
                            >
                              Ngày sinh *
                            </label>
                            <br></br>
                            <input
                              type="date"
                              onChange={updateGuestDob}
                              value={guestInfo.guestDob}
                              id="guest-dob"
                              name="guest-dob"
                              className="search-box-guest-info-input"
                            ></input>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <label
                              htmlFor="guest-phone"
                              className="search-box-guest-info-input-title"
                            >
                              Điện thoại *
                            </label>
                            <br></br>
                            <input
                              type="tel"
                              onChange={updateGuestPhone}
                              value={guestInfo.guestPhone}
                              id="guest-phone"
                              name="guest-phone"
                              className="search-box-guest-info-input"
                            ></input>
                          </Grid>
                        </Grid>
                      </form>
                      <Grid
                        container
                        justifyContent="center"
                        className="search-box-guest-info-search-button-wrapper"
                      >
                        <button
                          onClick={handleGuestInfoSearch}
                          className="search-box-guest-info-search-button"
                        >
                          Tìm kiếm
                        </button>
                      </Grid>
                    </Grid>
                  )}
                  {searchType === "guest-code" && (
                    <Grid item xs={12}>
                      <form>
                        <Grid container justifyContent="flex-start" spacing={4}>
                          <Grid item item xs={12} md={4}>
                            <label
                              htmlFor="guest-code"
                              className="search-box-guest-info-input-title"
                            >
                              Mã tham gia *
                            </label>
                            <br></br>
                            <input
                              type="text"
                              onChange={handleUpdateGuestCode}
                              value={guestCode}
                              id="guest-code"
                              name="guest-code"
                              className="search-box-guest-info-input"
                            ></input>
                          </Grid>
                          <Grid
                            item
                            item
                            xs={12}
                            md={4}
                            display="flex"
                            alignItems="flex-end"
                            justifyContent="center"
                          >
                            <button
                              onClick={handleGuestCodeSearch}
                              className="search-box-guest-info-search-button"
                            >
                              Tìm kiếm
                            </button>
                          </Grid>
                        </Grid>
                      </form>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Modal hideBackdrop open={errorModalOpen} onClose={handleErrorModalClose}>
        <Box sx={style} className="error-modal-box-wrapper">
          <Grid
            container
            justifyContent="center"
            className="error-modal-box-inside-wrapper"
          >
            <img
              onClick={handleErrorModalClose}
              className="error-modal-close-icon"
              alt="submission search page error modal close icon"
              src="images/SubmissionSearchPage/error-modal-close-icon.png"
            />
            <Grid
              item
              xs={12}
              className="error-modal-icon-wrapper"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img
                className="error-modal-icon"
                alt="submission search page error modal icon"
                src="images/SubmissionSearchPage/error-modal-icon.png"
              />
            </Grid>
            <Grid
              item
              xs={12}
              className="error-modal-title-wrapper"
              display="flex"
              justifyContent="center"
            >
              <Typography className="error-modal-title">
                Bạn đang điền thiếu thông tin, vui lòng kiểm tra lại
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
