import {
  Grid,
  listSubheaderClasses,
  Typography,
  Modal,
  Box,
} from "@mui/material";
import "./ListSubmissionPainting.css";
import SubmissionCard from "../../SubmissionCard";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import React from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import IconButton from "@mui/material/IconButton";

export default function ListSubmissionPainting() {
  const [contestEntries, setContestEntries] = React.useState([]);
  const [submissionCardModalOpen, updateSubmissionCardModalOpen] =
    React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:420px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [selectedData, setSelectedData] = React.useState({
    imgPath: [],
    candidateName: "",
    subject: "",
    content: "",
    sense: "",
    candidateSchool: "",
    district: "",
    city: "",
  });

  const handleSubmissionCardModalOpen = (selectedContestEntry) => {
    updateSubmissionCardModalOpen(true);
    setSelectedData((prevState) => ({
      ...prevState,
      imgPath: selectedContestEntry.contestEntriesImages,
      candidateName: selectedContestEntry
        ? selectedContestEntry.candidateName
        : "",
      subject: selectedContestEntry ? selectedContestEntry.subject : "",
      content: selectedContestEntry ? selectedContestEntry.content : "",
      sense: selectedContestEntry ? selectedContestEntry.sense : "",
      district: selectedContestEntry
        ? selectedContestEntry.user.district.name
        : "",
      city: selectedContestEntry ? selectedContestEntry.user.city.name : "",
      candidateSchool: selectedContestEntry
        ? selectedContestEntry.candidateSchool
        : "",
    }));
    axios
      .put(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/contest-entry/count-view-number/${selectedContestEntry.id}`
      )
      .then((res) => {})
      .catch((error) => console.log(error));
  };

  const handleSubmissionCardModalClose = () => {
    updateSubmissionCardModalOpen(false);
  };

  React.useEffect(() => {
    axios
      .get(process.env.REACT_APP_SERVER_API_ROOT_URL + "/exam-types")
      .then((response) => {
        const paintingExam = response.data.rows.find(
          (et) => et.name === "Cùng Kun bảo vệ môi trường"
        );
        axios
          .get(
            process.env.REACT_APP_SERVER_API_ROOT_URL +
              `/contest-entry/get-by-exam-type/${paintingExam.id}`
          )
          .then((res) => {
            if (res.data) {
              setContestEntries(res.data.rows);
            }
          });
      });
  }, []);

  const onNextArrowClick = () => {
    let parent = document.querySelector(
      ".list-submission-painting-writing-wrapper"
    );
    let arrow = parent.querySelector(".control-next");
    arrow.click();
  };

  const onModalNextArrowClick = () => {
    let parent = document.querySelector(
      ".painting-submission-card-modal-title-wrapper"
    );
    let arrow = parent.querySelector(".control-next");
    arrow.click();
  };

  const onModalPrevArrowClick = () => {
    let parent = document.querySelector(
      ".painting-submission-card-modal-title-wrapper"
    );
    let arrow = parent.querySelector(".control-prev");
    arrow.click();
  };

  const contestDisplay = (listContest) => {
    if (listContest.length === 0) {
      return null;
    }
    if (listContest.length <= 3) {
      return [
        <Grid
          container
          key={listContest[0].id}
          justifyContent="center"
          spacing={3}
        >
          {listContest.map((contestEntry, index) => {
            return (
              <Grid
                key={contestEntry.id}
                item
                xs={8}
                md={4}
                onClick={() => handleSubmissionCardModalOpen(contestEntry)}
              >
                <SubmissionCard cardData={contestEntry} />
              </Grid>
            );
          })}
        </Grid>,
      ];
    }
    return [
      <Grid
        container
        key={listContest[0].id}
        justifyContent="center"
        spacing={3}
      >
        {listContest.slice(0, 3).map((contestEntry, index) => {
          return (
            <Grid
              key={contestEntry.id}
              item
              xs={8}
              md={4}
              onClick={() => handleSubmissionCardModalOpen(contestEntry)}
            >
              <SubmissionCard cardData={contestEntry} />
            </Grid>
          );
        })}
      </Grid>,
    ].concat(contestDisplay(listContest.slice(3, listContest.length)));
  };
  const contestDisplayMobile = (listContest) => {
    if (listContest.length === 0) {
      return null;
    }
    if (listContest.length <= 1) {
      return [
        <Grid container key={listContest[0].id} justifyContent="center">
          {listContest.map((contestEntry, index) => {
            return (
              <Grid
                key={contestEntry.id}
                item
                xs={11}
                onClick={() => handleSubmissionCardModalOpen(contestEntry)}
              >
                <SubmissionCard cardData={contestEntry} />
              </Grid>
            );
          })}
        </Grid>,
      ];
    }
    return [
      <Grid container key={listContest[0].id} justifyContent="center">
        {listContest.slice(0, 1).map((contestEntry, index) => {
          return (
            <Grid
              key={contestEntry.id}
              item
              xs={11}
              onClick={() => handleSubmissionCardModalOpen(contestEntry)}
            >
              <SubmissionCard cardData={contestEntry} />
            </Grid>
          );
        })}
      </Grid>,
    ].concat(contestDisplayMobile(listContest.slice(1, listContest.length)));
  };

  const contestEntriesImagesDisplay = (listImage) => {
    if (!Array.isArray(listImage) || !(listImage.length > 0)) {
      return null;
    } else {
      return (
        listImage.length > 0 &&
        listImage.map((img, index) => {
          return (
            <Grid
              key={index}
              item
              xs={12}
              display="flex"
              justifyContent="center"
              className="modal-image-wrapper"
            >
              <TransformWrapper
                initialScale={1}
                minScale={1}
                maxScale={isMobile ? 6 : 5}
              >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <React.Fragment>
                    <div className="tools-wrapper">
                      <IconButton onClick={() => zoomIn()}>
                        <AddCircleIcon />
                      </IconButton>
                      <IconButton onClick={() => zoomOut()}>
                        <RemoveCircleIcon />
                      </IconButton>
                      <IconButton onClick={() => resetTransform()}>
                        <RestartAltIcon />
                      </IconButton>
                    </div>
                    <TransformComponent wrapperStyle={{ overflow: "visible" }}>
                      <div
                        className="contest-entries-image-display-wrapper"
                        style={{
                          height: isTablet
                            ? isMobile
                              ? "300px"
                              : "600px"
                            : "1000px",
                          width: isTablet
                            ? isMobile
                              ? "300px"
                              : "600px"
                            : "1000px",
                        }}
                      >
                        <img
                          id="contest-entries-display-background-frame"
                          alt="contest entries background frame"
                          src="/images/contest-entries-background-frame.png"
                        />
                        <img
                          style={{
                            transform: `rotate(${img.image.rotateDegree}deg)`,
                            msTransform: `rotate(${img.image.rotateDegree}deg)`,
                            WebkitTransform: `rotate(${img.image.rotateDegree}deg)`,
                            msTransform: `rotate(${img.image.rotateDegree}deg)`,
                          }}
                          className="admin-submission-card-modal-img"
                          alt="submission test"
                          src={
                            process.env.REACT_APP_SERVER_ROOT_MEDIA_URL +
                            img.image.path
                          }
                        />
                      </div>
                    </TransformComponent>
                  </React.Fragment>
                )}
              </TransformWrapper>
            </Grid>
          );
        })
      );
    }
  };

  return (
    <Grid
      data-aos="fade-in"
      data-aos-duration="1500"
      container
      justifyContent="center"
      spacing={0}
      className="list-submission-painting-writing-wrapper"
      style={{
        backgroundImage: `url("images/SubmissionSearchPage/list-submission-background-2.png")`,
      }}
    >
      {!isMobile && contestEntries.length > 3 && (
        <img
          data-aos="fade-up"
          data-aos-duration="2000"
          onClick={onNextArrowClick}
          className="list-submission-next-button"
          alt="list submission next button"
          src="images/SubmissionSearchPage/list-submission-next-button.png"
        />
      )}
      {isMobile && contestEntries.length > 1 && (
        <img
          data-aos="fade-up"
          data-aos-duration="2000"
          onClick={onNextArrowClick}
          className="list-submission-next-button"
          alt="list submission next button"
          src="images/SubmissionSearchPage/list-submission-next-button.png"
        />
      )}
      <Grid item xs={12} data-aos="fade-down" data-aos-duration="2000">
        <Typography className="list-submission-painting-writing-title">
          Mô hình, sản phẩm
        </Typography>
      </Grid>
      {contestEntries.length > 0 ? (
        <Grid item xs={12} md={10} data-aos="fade-up" data-aos-duration="2000">
          <Carousel
            infiniteLoop={true}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            selectedItem={contestEntries.length > 3 ? -1 : 0}
          >
            {isMobile
              ? contestDisplayMobile(contestEntries)
              : contestDisplay(contestEntries)}
          </Carousel>
        </Grid>
      ) : (
        <Typography
          data-aos="fade-up"
          data-aos-duration="2000"
          className="contest-entries-blank-text"
        >
          Dữ liệu đang được cập nhật !
        </Typography>
      )}
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        fullScreen={fullScreen}
        open={submissionCardModalOpen}
        onClose={handleSubmissionCardModalClose}
      >
        <DialogContent>
          <Grid
            container
            rowSpacing={2}
            className="submission-card-modal-box-inside-wrapper"
          >
            <Grid
              item
              xs={12}
              className="painting-submission-card-modal-title-wrapper"
            >
              <Grid
                container
                justifyContent="center"
                spacing={isMobile ? 1 : 2}
                style={{ position: "relative" }}
              >
                <Grid item xs={12}>
                  <Typography
                    variant="quicksand18shadow"
                    className="submission-card-modal-title"
                    sx={{ fontWeight: "700" }}
                  >
                    Ảnh mô tả bài thi:
                  </Typography>
                </Grid>
                {selectedData.imgPath.length > 1 && (
                  <img
                    onClick={onModalNextArrowClick}
                    className="submission-modal-next-btn"
                    alt="submissions modal next btn"
                    src="images/homepage-slider-icon-next.png"
                  />
                )}
                {selectedData.imgPath.length > 1 && (
                  <img
                    onClick={onModalPrevArrowClick}
                    className="submission-modal-prev-btn"
                    alt="submissions modal back btn"
                    src="images/homepage-slider-icon-back.png"
                  />
                )}
                <Carousel
                  infiniteLoop={true}
                  showStatus={false}
                  showIndicators={false}
                  showThumbs={false}
                  swipeable={false}
                  width={isTablet ? (isMobile ? 300 : 600) : 1000}
                >
                  {contestEntriesImagesDisplay(selectedData.imgPath)}
                </Carousel>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className="submission-card-modal-title-wrapper"
              display="flex"
            >
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Họ tên thí sinh: {selectedData.candidateName}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              className="submission-card-modal-title-wrapper"
              display="flex"
            >
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Tên chủ đề: {selectedData.subject}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              className="submission-card-modal-title-wrapper"
              display="flex"
            >
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Nội dung tác phẩm: {selectedData.content}
              </Typography>
            </Grid>
            {/* <Grid
              item
              xs={12}
              className="submission-card-modal-title-wrapper"
              display="flex"
            >
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Ý nghĩa: {selectedData.sense}
              </Typography>
            </Grid> */}
            <Grid
              item
              xs={12}
              className="submission-card-modal-title-wrapper"
              display="flex"
            >
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Trường: {selectedData.candidateSchool}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              className="submission-card-modal-title-wrapper"
              display="flex"
            >
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Quận/ Huyện: {selectedData.district}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              className="submission-card-modal-title-wrapper"
              display="flex"
            >
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Tỉnh/ Thành phố: {selectedData.city}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            className="submission-card-modal-close-button"
            onClick={handleSubmissionCardModalClose}
            autoFocus
          >
            Đóng
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
