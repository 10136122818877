import { Grid } from "@mui/material";
import React, { useRef } from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import ArticleIcon from "@mui/icons-material/Article";
import { DataGrid, viVN } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import Paper from "@mui/material/Paper";
import DateTimeConvert from "../../../utils/DateTimeConvert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Carousel } from "react-responsive-carousel";
import SuccessSnackbar from "../Common/SuccessSnackbar";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ListAltIcon from "@mui/icons-material/ListAlt";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContestEntriesNumberCard from "../Common/ContestEntriesNumberCard";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import WeekRoundPrizesNumberCard from "../Common/WeekRoundPrizesNumberCard";
import ErrorSnackbar from "../Common/ErrorSnackbar";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";
import "./ContestEntriesManagement.css";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExcelExport from "../../../utils/ExcelExport";
import RestoreIcon from "@mui/icons-material/Restore";
import Rotate90DegreesCwIcon from "@mui/icons-material/Rotate90DegreesCw";

const gender_vi = {
  male: "Nam",
  female: "Nữ",
};

const result_vi = {
  ACTIVE: "Chưa được chấm điểm",
  PASS: "Đạt",
  FAIL: "Không đạt",
};

const useStyles = makeStyles({
  root: {
    marginTop: "10px",
    "& .MuiOutlinedInput-input": {
      color: "rgba(0, 0, 0, 0.87)",
      fontFamily: "Quicksand",
      fontSize: "16px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.5) !important",
    },
  },
});

export default function ContestEntriesManagement(props) {
  const contestEntriesType = props.type;
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:420px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const [ContestEntries, setContestEntries] = React.useState([]);
  const [examRounds, setExamRounds] = React.useState([]);
  const [Cities, setCities] = React.useState([]);
  const [Districts, setDistricts] = React.useState([]);
  const [listExamTypes, setListExamTypes] = React.useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [backdropOpen, setBackdropOpen] = React.useState(false);

  const downloadImage = e => {
    fetch(e.target.href, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", e.target.attributes.download.value)
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleBackdropClose = () => {
    setBackdropOpen(false);
  };

  const handleBackdropOpen = () => {
    setBackdropOpen(true);
  };

  const [formError, setFormError] = React.useState({
    field: "",
    text: "",
  });

  const [searchForm, setSearchForm] = React.useState({
    createDate: "",
    examRound: "all",
    city: "",
    district: "",
    examCode: "",
    candidateName: "",
    candidateDob: "",
    userPhone: "",
    candidateSchool: "",
    examType: "",
  });

  const handleCreateDateChange = (e) => {
    setSearchForm((prevState) => ({
      ...prevState,
      createDate: e.target.value,
    }));
    setFormError({
      field: "",
      text: "",
    });
  };

  const handleExamRoundChange = (e) => {
    setSearchForm((prevState) => ({
      ...prevState,
      examRound: e.target.value,
    }));
    setFormError({
      field: "",
      text: "",
    });
  };

  const handleCityChange = (e) => {
    setSearchForm((prevState) => ({
      ...prevState,
      city: e.target.value,
    }));

    setFormError({
      field: "",
      text: "",
    });

    setSearchForm((prevState) => ({
      ...prevState,
      district: "",
    }));

    getDistrictsByCity(e.target.value);
  };

  const handleDistrictChange = (e) => {
    setSearchForm((prevState) => ({
      ...prevState,
      district: e.target.value,
    }));

    setFormError({
      field: "",
      text: "",
    });
  };

  const handleExamCodeChange = (e) => {
    setSearchForm((prevState) => ({
      ...prevState,
      examCode: e.target.value,
    }));

    setFormError({
      field: "",
      text: "",
    });
  };

  const handleCandidateNameChange = (e) => {
    setSearchForm((prevState) => ({
      ...prevState,
      candidateName: e.target.value,
    }));

    setFormError({
      field: "",
      text: "",
    });
  };

  const handleCandidateDobChange = (e) => {
    setSearchForm((prevState) => ({
      ...prevState,
      candidateDob: e.target.value,
    }));

    setFormError({
      field: "",
      text: "",
    });
  };

  const handleUserPhoneChange = (e) => {
    setSearchForm((prevState) => ({
      ...prevState,
      userPhone: e.target.value,
    }));

    setFormError({
      field: "",
      text: "",
    });
  };

  const handleCandidateSchoolChange = (e) => {
    setSearchForm((prevState) => ({
      ...prevState,
      candidateSchool: e.target.value,
    }));

    setFormError({
      field: "",
      text: "",
    });
  };

  const handleExamTypeChange = (e) => {
    setSearchForm((prevState) => ({
      ...prevState,
      examType: e.target.value,
    }));

    setFormError({
      field: "",
      text: "",
    });
  };

  const resetSearchForm = () => {
    handleBackdropOpen();
    setSearchForm({
      createDate: "",
      examRound: "",
      city: "",
      district: "",
      examCode: "",
      candidateName: "",
      candidateDob: "",
      userPhone: "",
      candidateSchool: "",
    });

    setFormError({
      field: "",
      text: "",
    });

    axios
      .get(process.env.REACT_APP_SERVER_API_ROOT_URL + `/contest-entry`)
      .then((res) => {
        if (res.data) {
          setContestEntries(res.data.rows);
          handleBackdropClose();
        }
      });
  };

  const [isSearched, setIsSearched] = React.useState(false);

  const handleSubmitSearchForm = (page) => {
    setIsSearched(true);
    if (
      !searchForm.candidateDob &&
      !searchForm.candidateName &&
      !searchForm.candidateSchool &&
      !searchForm.city &&
      !searchForm.createDate &&
      !searchForm.district &&
      !searchForm.examCode &&
      !searchForm.examRound &&
      !searchForm.userPhone &&
      !searchForm.examType
    ) {
      setFormError({
        field: "missing",
        text: "Bạn chưa điền thông tin tìm kiếm, xin hãy kiểm tra lại !",
      });
      return;
    }
    handleBackdropOpen();
    setContestEntries([]);
    setContestEntriesIdArray([]);
    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/contest-entry/admin/search/?type=${contestEntriesType}&createDate=${searchForm.createDate}&examRound=${searchForm.examRound}&city=${searchForm.city}&district=${searchForm.district}&examCode=${searchForm.examCode}&candidateName=${searchForm.candidateName}&candidateDob=${searchForm.candidateDob}&userPhone=${searchForm.userPhone}&candidateSchool=${searchForm.candidateSchool}&examType=${searchForm.examType}&page=${page}`
      )
      .then((respone) => {
        if (respone.data.rows) {
          setContestEntries(respone.data.rows);
          var tempArray = [];
          respone.data.rows.forEach((contestEntry) => {
            tempArray.push(contestEntry.id);
          });
          setContestEntriesIdArray(tempArray);
          setTotalRows(respone.data.count);

          handleBackdropClose();
        }
      })
      .catch((error) => console.log(error));
  };

  const getDistrictsByCity = (cityId) => {
    handleBackdropOpen();
    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/districts/find-by-city/${cityId}`
      )
      .then((respone) => {
        if (respone.data.rows) {
          setDistricts(respone.data.rows);
          handleBackdropClose();
        }
      })
      .catch((error) => console.log(error));
  };

  // begin get detail contest entry

  const [contestEntryDetailInfo, setContestEntryDetailInfo] = React.useState({
    contestEntryId: "",
    contestEntriesImages: [],
    candidateName: "",
    candidateDob: "",
    userPhone: "",
    candidateSchool: "",
    address: "",
    district: "",
    city: "",
    examType: "",
    contestJoinDate: "",
    examRound: {
      id: "",
      firstDoW: "",
      firstDoM: "",
      isDone: "",
      roundSequenceId: "",
      roundSequenceSequence: 0,
    },
    subject: "",
    content: "",
    sense: "",
    views: "",
    status: "",
    examCode: "",
    roundSequence: {
      id: "",
      sequence: "",
      isEndWeekRound: "",
      isFinalRound: "",
      name: "",
    },
  });
  const [submissionCardModalOpen, updateSubmissionCardModalOpen] =
    React.useState(false);

  const handleSubmissionCardModalClose = () => {
    updateSubmissionCardModalOpen(false);
  };

  const onModalNextArrowClick = () => {
    let parent = document.querySelector(
      ".admin-detail-submission-card-modal-title-wrapper"
    );
    let arrow = parent.querySelector(".control-next");
    arrow.click();
  };

  const onModalPrevArrowClick = () => {
    let parent = document.querySelector(
      ".admin-detail-submission-card-modal-title-wrapper"
    );
    let arrow = parent.querySelector(".control-prev");
    arrow.click();
  };

  const clearDetailContestEntry = () => {
    setContestEntryDetailInfo((prevState) => ({
      ...prevState,
      contestEntryId: "",
      contestEntriesImages: [],
      candidateName: "",
      candidateDob: "",
      userPhone: "",
      candidateSchool: "",
      address: "",
      district: "",
      city: "",
      examType: "",
      contestJoinDate: "",
      examRound: {
        id: "",
        firstDoW: "",
        firstDoM: "",
        isDone: "",
        roundSequenceId: "",
        roundSequenceSequence: 0,
      },
      subject: "",
      content: "",
      sense: "",
      views: "",
      status: "",
      examCode: "",
      roundSequence: {
        id: "",
        sequence: "",
        isEndWeekRound: "",
        isFinalRound: "",
        name: "",
      },
    }));
  };

  const getDetailContestEntry = (contestEntryId) => {
    handleBackdropOpen();
    clearDetailContestEntry();
    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/contest-entry/${contestEntryId}`
      )
      .then((respone) => {
        if (respone.data) {
          updateSubmissionCardModalOpen(true);
          setContestEntryDetailInfo((prevState) => ({
            ...prevState,
            contestEntryId: respone.data.id,
            contestEntriesImages: respone.data.contestEntriesImages,
            candidateName: respone.data.candidateName,
            candidateDob: DateTimeConvert.yyyymmddConvert(
              respone.data.candidateDob
            ),
            userPhone: respone.data.user
              ? respone.data.user.phone
              : "<Không có thông tin>",
            candidateSchool: respone.data.candidateSchool,
            address: respone.data.user
              ? respone.data.user.address
              : "<Không có thông tin>",
            district: respone.data.user
              ? respone.data.user.district.name
              : "<Không có thông tin>",
            city: respone.data.user
              ? respone.data.user.city.name
              : "<Không có thông tin>",
            examType: respone.data.examType.name,
            contestJoinDate: DateTimeConvert.yyyymmddConvert(
              respone.data.createdAt
            ),
            examRound: {
              ...prevState.examRound,
              id: respone.data.examRound.id,
              firstDoW: DateTimeConvert.roundResultDateDisplay(
                respone.data.examRound.firstDoW
              ),
              firstDoM: respone.data.examRound.firstDoM,
              isDone: respone.data.examRound.isDone,
              roundSequenceId: respone.data.examRound.roundSequenceId,
              roundSequenceSequence:
                respone.data.examRound.roundSequence.sequence,
            },
            subject: respone.data.subject,
            content: respone.data.content,
            sense: respone.data.sense,
            views: respone.data.view,
            status: respone.data.status,
            examCode: respone.data.code,
            roundSequence: {
              ...prevState.roundSequence,
              id: respone.data.roundSequence?.id,
              sequence: respone.data.roundSequence
                ? respone.data.roundSequence.sequence
                : 0,
              isEndWeekRound: respone.data.roundSequence?.isEndWeekRound,
              isFinalRound: respone.data.roundSequence?.isFinalRound,
              name: respone.data.roundSequence?.name,
            },
          }));
          handleBackdropClose();
        }
      })
      .catch((error) => console.log(error));
  };

  React.useEffect(() => {
    if (contestEntryDetailInfo.contestEntryId) {
      getRoundSequenceInfoForGrading();
    }
  }, [contestEntryDetailInfo.contestEntryId]);
  // end get detail contest entry

  // begin contest entry grading

  const [currentRoundSequence, setCurrentRoundSequence] = React.useState({
    id: "",
    name: "",
    sequence: "",
  });

  const getRoundSequenceInfoForGrading = () => {
    axios
      .get(process.env.REACT_APP_SERVER_API_ROOT_URL + `/round-sequences`)
      .then((respone) => {
        if (respone.data.rows.length > 0) {
          var tempArr = [];
          respone.data.rows.forEach((roundSequence, index) => {
            if (
              roundSequence.sequence >
                contestEntryDetailInfo.roundSequence.sequence &&
              roundSequence.sequence <=
                contestEntryDetailInfo.examRound.roundSequenceSequence
            ) {
              tempArr.push(roundSequence);
            }
          });
          if (tempArr.length > 1) {
            var currentRoundSequence = tempArr.reduce((current, next) => {
              return current.sequence <= next.sequence ? current : next;
            });
            setCurrentRoundSequence((prevState) => ({
              ...prevState,
              id: currentRoundSequence.id,
              name: currentRoundSequence.name,
              sequence: currentRoundSequence.sequence,
            }));
          } else if (tempArr.length === 1) {
            setCurrentRoundSequence((prevState) => ({
              ...prevState,
              id: tempArr[0].id,
              name: tempArr[0].name,
              sequence: tempArr[0].sequence,
            }));
          } else {
            setCurrentRoundSequence((prevState) => ({
              ...prevState,
              id: "",
              name: "",
              sequence: "",
            }));
            return null;
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState({
    isOpen: false,
    content: "",
  });

  const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState({
    isOpen: false,
    content: "",
  });

  const gradingSubmit = (contestEntryId, gradingResult) => {
    handleBackdropOpen();
    if (currentRoundSequence.name === "Vòng tuần" && gradingResult === "PASS") {
      axios
        .get(
          process.env.REACT_APP_SERVER_API_ROOT_URL +
            `/contest-entry/admin/count-week-round-prizes/${contestEntryDetailInfo.examRound.id}`
        )
        .then((res) => {
          if (res.data) {
            if (contestEntryDetailInfo.examType === "Viết chữ đẹp") {
              if (
                res.data.poemWritingNumber >= max_week_round_poem_writing_prizes && false
              ) {
                handleBackdropClose();
                return setErrorSnackbarOpen((prevState) => ({
                  ...prevState,
                  isOpen: true,
                  content:
                    'Chấm điểm không thành công, số lượng bài "Viết chữ đẹp" đạt giải trong tuần đã đạt mức tối đa !',
                }));
              }
            }
            if (contestEntryDetailInfo.examType === "Viết cảm nhận về clip") {
              if (
                res.data.poemWritingNumber >=
                max_week_round_feeling_writing_prizes && false
              ) {
                handleBackdropClose();
                return setErrorSnackbarOpen((prevState) => ({
                  ...prevState,
                  isOpen: true,
                  content:
                    'Chấm điểm không thành công, số lượng bài "Viết cảm nhận về clip" đạt giải trong tuần đã đạt mức tối đa !',
                }));
              }
            }
            if (
              contestEntryDetailInfo.examType === "Viết về việc tốt quanh em"
            ) {
              if (
                res.data.poemWritingNumber >= max_week_round_good_work_prizes && false
              ) {
                handleBackdropClose();

                return setErrorSnackbarOpen((prevState) => ({
                  ...prevState,
                  isOpen: true,
                  content:
                    'Chấm điểm không thành công, số lượng bài "Viết về việc tốt quanh em" đạt giải trong tuần đã đạt mức tối đa !',
                }));
              }
            }
            axios
              .put(
                process.env.REACT_APP_SERVER_API_ROOT_URL +
                  `/contest-entry/admin/grading/?id=${contestEntryId}&roundSequenceId=${
                    gradingResult === "PASS" ? currentRoundSequence.id : ""
                  }&result=${gradingResult}`
              )
              .then((res) => {
                if (res.data) {
                  var index = contestEntriesIdArray.indexOf(res.data.id);
                  if (index >= 0 && index < contestEntriesIdArray.length - 1) {
                    getDetailContestEntry(contestEntriesIdArray[index + 1]);
                  } else if (
                    index === 0 ||
                    index === contestEntriesIdArray.length - 1
                  ) {
                    handleSubmissionCardModalClose();
                  }
                  // begin remove graded contest entries out of init list

                  var tempContestEntries = [...ContestEntries];
                  var tempContestEntriesIdArray = [...contestEntriesIdArray];

                  tempContestEntries.splice(index, 1);
                  tempContestEntriesIdArray.splice(index, 1);

                  setContestEntries(tempContestEntries);
                  setContestEntriesIdArray(tempContestEntriesIdArray);

                  // end remove graded contest entries out of init list
                  if (isSearched) {
                    handleSubmitSearchForm(pageData);
                  } else {
                    getListContestEntriesPerPage(contestEntriesType, pageData);
                  }

                  handleExamRoundSearchForWeekRoundPrize(res.data.examRoundId);

                  setTimeout(() => {
                    setSuccessSnackbarOpen((prevState) => ({
                      ...prevState,
                      isOpen: true,
                      content:
                        "Chấm điểm thành công, kết quả của bài thi đã được cập nhật !",
                    }));

                    handleBackdropClose();
                  }, 1000);
                }
              });
          }
        });
    } else {
      axios
        .put(
          process.env.REACT_APP_SERVER_API_ROOT_URL +
            `/contest-entry/admin/grading/?id=${contestEntryId}&roundSequenceId=${
              gradingResult === "PASS" ? currentRoundSequence.id : ""
            }&result=${gradingResult}`
        )
        .then((res) => {
          if (res.data) {
            var index = contestEntriesIdArray.indexOf(res.data.id);
            if (index >= 0 && index < contestEntriesIdArray.length - 1) {
              getDetailContestEntry(contestEntriesIdArray[index + 1]);
            } else if (
              index === 0 ||
              index === contestEntriesIdArray.length - 1
            ) {
              handleSubmissionCardModalClose();
            }
            // begin remove graded contest entries out of init list

            var tempContestEntries = [...ContestEntries];
            var tempContestEntriesIdArray = [...contestEntriesIdArray];

            tempContestEntries.splice(index, 1);
            tempContestEntriesIdArray.splice(index, 1);

            setContestEntries(tempContestEntries);
            setContestEntriesIdArray(tempContestEntriesIdArray);

            // end remove graded contest entries out of init list
            if (isSearched) {
              handleSubmitSearchForm(pageData);
            } else {
              getListContestEntriesPerPage(contestEntriesType, pageData);
            }

            handleExamRoundSearchForWeekRoundPrize(res.data.examRoundId);

            setTimeout(() => {
              setSuccessSnackbarOpen((prevState) => ({
                ...prevState,
                isOpen: true,
                content:
                  "Chấm điểm thành công, kết quả của bài thi đã được cập nhật !",
              }));

              handleBackdropClose();
            }, 1000);
          }
        });
    }
  };

  const reverseGradingConfirm = (contestEntryId) => {
    if (!contestEntryId) {
      return null;
    }
    handleBackdropOpen();
    axios
      .put(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/contest-entry/admin/admin-reverse-grading/${contestEntryId}`
      )
      .then((res) => {
        if (res.data) {
          var index = contestEntriesIdArray.indexOf(res.data.id);
          if (index >= 0 && index < contestEntriesIdArray.length - 1) {
            getDetailContestEntry(contestEntriesIdArray[index + 1]);
          } else if (
            index === 0 ||
            index === contestEntriesIdArray.length - 1
          ) {
            handleSubmissionCardModalClose();
          }

          // begin remove graded contest entries out of init list

          var tempContestEntries = [...ContestEntries];
          var tempContestEntriesIdArray = [...contestEntriesIdArray];

          tempContestEntries.splice(index, 1);
          tempContestEntriesIdArray.splice(index, 1);

          setContestEntries(tempContestEntries);
          setContestEntriesIdArray(tempContestEntriesIdArray);

          // end remove graded contest entries out of init list
          if (isSearched) {
            handleSubmitSearchForm(pageData);
          } else {
            getListContestEntriesPerPage(contestEntriesType, pageData);
          }

          handleExamRoundSearchForWeekRoundPrize(res.data.examRoundId);

          setTimeout(() => {
            setSuccessSnackbarOpen((prevState) => ({
              ...prevState,
              isOpen: true,
              content:
                "Khôi phục thành công, bài thi đã được chuyển về mục chưa được chấm !",
            }));

            handleBackdropClose();
          }, 1000);
        } else {
          handleBackdropClose();
        }
      });
  };

  // end contest entry grading

  // begin admin exam round management

  const [examRoundManagementModalOpen, setExamRoundManagementModalOpen] =
    React.useState(false);

  const [updateExamRoundForm, setUpdateExamRoundForm] = React.useState({
    examRoundId: "",
    roundSequenceId: "",
  });

  const [listExamRounds, setListExamRounds] = React.useState([]);

  const [examRoundSelected, setExamRoundSelected] = React.useState({
    firstDoW: "",
    firstDoM: "",
    roundSequenceValue: "",
    roundSequenceName: "",
  });

  const getExamRoundInfo = (examRoundId) => {
    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL + `/exam-round/${examRoundId}`
      )
      .then((respone) => {
        if (respone.data) {
          setExamRoundSelected((prevState) => ({
            ...prevState,
            firstDoW: respone.data.firstDoW,
            firstDoM: respone.data.firstDoM,
            roundSequenceName: respone.data.roundSequence.name,
            roundSequenceValue: respone.data.roundSequence.sequence,
          }));
        }
      })
      .catch((error) => console.log(error));
  };

  const [listRoundSequences, setListRoundSequences] = React.useState([]);

  React.useEffect(() => {
    if (
      examRoundSelected.roundSequenceValue &&
      parseInt(examRoundSelected.roundSequenceValue) > 0
    ) {
      axios
        .get(process.env.REACT_APP_SERVER_API_ROOT_URL + `/round-sequences`)
        .then((respone) => {
          if (respone.data.rows.length > 0) {
            setListRoundSequences([]);
            respone.data.rows.map((roundSequence, index) => {
              if (
                parseInt(roundSequence.sequence) >
                parseInt(examRoundSelected.roundSequenceValue)
              ) {
                setListRoundSequences((prevState) => [
                  ...prevState,
                  roundSequence,
                ]);
              }
            });
          }
        })
        .catch((error) => console.log(error));
    }
  }, [examRoundSelected, examRoundSelected.roundSequenceValue]);

  const handleExamRoundManagementModalOpen = () => {
    setExamRoundManagementModalOpen(true);
    axios
      .get(process.env.REACT_APP_SERVER_API_ROOT_URL + `/exam-round`)
      .then((respone) => {
        if (respone.data.rows) {
          setListExamRounds(respone.data.rows);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleExamRoundManagementModalClose = () => {
    setExamRoundManagementModalOpen(false);
  };

  const [updateExamRoundError, setUpdateExamRoundError] = React.useState("");

  const handleUpdateExamRound = () => {
    if (
      !updateExamRoundForm.examRoundId ||
      !updateExamRoundForm.roundSequenceId
    ) {
      setUpdateExamRoundError(
        "Bạn chưa điền đủ thông tin, xin hãy kiểm tra lại !"
      );
      return null;
    }

    axios
      .put(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/exam-round/admin/update-round-sequences/?examRoundId=${updateExamRoundForm.examRoundId}&roundSequenceId=${updateExamRoundForm.roundSequenceId}`
      )
      .then((respone) => {
        if (respone.data) {
          setSuccessSnackbarOpen((prevState) => ({
            ...prevState,
            isOpen: true,
            content: "Cập nhật thành công !",
          }));
          setUpdateExamRoundForm((prevState) => ({
            ...prevState,
            examRoundId: "",
            roundSequenceId: "",
          }));
          setExamRoundSelected((prevState) => ({
            ...prevState,
            firstDoW: "",
            firstDoM: "",
            roundSequenceName: "",
            roundSequenceValue: "",
          }));
          handleExamRoundManagementModalClose();
        }
      })
      .catch((error) => console.log(error));
  };
  // end admin exam round management

  // begin show prev/next Contest entry

  const displayPrevContestEntry = () => {
    const index = contestEntriesIdArray.indexOf(
      contestEntryDetailInfo.contestEntryId
    );
    getDetailContestEntry(contestEntriesIdArray[index - 1]);
  };

  const displayNextContestEntry = () => {
    const index = contestEntriesIdArray.indexOf(
      contestEntryDetailInfo.contestEntryId
    );
    getDetailContestEntry(contestEntriesIdArray[index + 1]);
  };

  // end show prev/next contest entry

  // begin contest entries management features expand

  const [isContestEntriesAdminExpand, setContestEntriesAdminExpand] =
    React.useState(false);

  const [isContestEntriesStatisticExpand, setContestEntriesStatisticExpand] =
    React.useState(false);

  const [isContestEntriesSearchExpand, setContestEntriesSearchExpand] =
    React.useState(false);

  // end contest entries management features expand

  // begin get contest entries statistic

  const [totalContestEntries, setTotalContestEntries] = React.useState(0);

  const [totalFirstRoundContestEntries, setTotalFirstRoundContestEntries] =
    React.useState(0);

  const [totalWeekRoundContestEntries, setTotalWeekRoundContestEntries] =
    React.useState(0);

  const [
    totalUngradedRoundContestEntries,
    setTotalUngradedRoundContestEntries,
  ] = React.useState(0);

  const [totalFailedContestEntries, setTotalFailedContestEntries] =
    React.useState(0);

  const [
    examRoundForContestEntriesStatistic,
    setExamRoundForContestEntriesStatistic,
  ] = React.useState("all");

  const getTotalContestEntriesNumber = async (examRoundId) => {
    handleBackdropOpen();
    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/contest-entry/admin/count-all/?examRoundId=${examRoundId}&type=all`
      )
      .then((res) => {
        if (res.data) {
          setTotalContestEntries(res.data.contestEntriesNumber);
          handleBackdropClose();
        }
      });
  };

  const getTotalFirstRoundContestEntriesNumber = async (examRoundId) => {
    handleBackdropOpen();
    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/contest-entry/admin/count-all/?examRoundId=${examRoundId}&type=round-1`
      )
      .then((res) => {
        if (res.data) {
          setTotalFirstRoundContestEntries(res.data.contestEntriesNumber);
          handleBackdropClose();
        }
      });
  };

  const getTotalWeekRoundContestEntriesNumber = async (examRoundId) => {
    handleBackdropOpen();
    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/contest-entry/admin/count-all/?examRoundId=${examRoundId}&type=round-week`
      )
      .then((res) => {
        if (res.data) {
          setTotalWeekRoundContestEntries(res.data.contestEntriesNumber);
          handleBackdropClose();
        }
      });
  };

  const getTotalUngradeContestEntriesNumber = async (examRoundId) => {
    handleBackdropOpen();
    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/contest-entry/admin/count-all/?examRoundId=${examRoundId}&type=ungraded`
      )
      .then((res) => {
        if (res.data) {
          setTotalUngradedRoundContestEntries(res.data.contestEntriesNumber);
          handleBackdropClose();
        }
      });
  };

  const getTotalFailedContestEntriesNumber = async (examRoundId) => {
    handleBackdropOpen();
    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/contest-entry/admin/count-all/?examRoundId=${examRoundId}&type=fail`
      )
      .then((res) => {
        if (res.data) {
          setTotalFailedContestEntries(res.data.contestEntriesNumber);
          handleBackdropClose();
        }
      });
  };

  React.useEffect(() => {
    if (examRoundForContestEntriesStatistic) {
      getTotalContestEntriesNumber(examRoundForContestEntriesStatistic);
      getTotalFirstRoundContestEntriesNumber(
        examRoundForContestEntriesStatistic
      );
      getTotalWeekRoundContestEntriesNumber(
        examRoundForContestEntriesStatistic
      );
      getTotalUngradeContestEntriesNumber(examRoundForContestEntriesStatistic);
      getTotalFailedContestEntriesNumber(examRoundForContestEntriesStatistic);
    }
  }, [examRoundForContestEntriesStatistic]);
  // end get contest entries statistic

  // begin update all Exam Round to Final Round Sequence

  const updateAllExamRoundsToFinalRoundSequence = () => {
    var maxRSid = "";
    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/round-sequences/admin/max-by-sequence/`
      )
      .then((res) => {
        if (res.data) {
          maxRSid = res.data.id;
        }
        axios
          .get(process.env.REACT_APP_SERVER_API_ROOT_URL + `/exam-round`)
          .then((res) => {
            if (res.data) {
              res.data.rows.map((examRound, index) => {
                if (examRound.roundSequenceId !== maxRSid) {
                  axios
                    .put(
                      process.env.REACT_APP_SERVER_API_ROOT_URL +
                        `/exam-round/admin/update-round-sequences/?examRoundId=${examRound.id}&roundSequenceId=${maxRSid}`
                    )
                    .then((respone) => {
                      console.log(
                        "update all round sequence of exam round to final round successful !"
                      );
                    });
                }
              });
            }
          });
      });
  };

  // end update all Exam Round to Final Round Sequence

  // begin get week round's contest entries prizes

  const [weekRoundPrizeStatisticExpand, setWeekRoundPrizeStatisticExpand] =
    React.useState(false);

  const max_week_round_poem_writing_prizes = 6;
  const max_week_round_feeling_writing_prizes = 8;
  const max_week_round_good_work_prizes = 6;

  const [currentWeekRoundPrize, setCurrentWeekRoundPrize] = React.useState({
    poemWriting: 0,
    feelingWriting: 0,
    goodWork: 0,
  });

  const [
    examRoundForWeekRoundPrizeSearch,
    setExamRoundForWeekRoundPrizeSearch,
  ] = React.useState("");

  const handleExamRoundSearchForWeekRoundPrize = (examRoundId) => {
    handleBackdropOpen();
    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/contest-entry/admin/count-week-round-prizes/${examRoundId}`
      )
      .then((res) => {
        if (res.data) {
          setCurrentWeekRoundPrize((prevState) => ({
            ...prevState,
            poemWriting: res.data.poemWritingNumber,
            feelingWriting: res.data.feelingWritingNumber,
            goodWork: res.data.goodWorkNumber,
          }));
          handleBackdropClose();
        }
      });
  };

  const getInitExamRoundForWeekRoundPrizesSearching = () => {
    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/exam-round/admin/find-latest`
      )
      .then((res) => {
        if (res.data) {
          setExamRoundForWeekRoundPrizeSearch(res.data[0].id);
        }
      });
  };

  React.useEffect(() => {
    if (examRoundForWeekRoundPrizeSearch) {
      handleExamRoundSearchForWeekRoundPrize(examRoundForWeekRoundPrizeSearch);
    }
  }, [examRoundForWeekRoundPrizeSearch]);

  // end get week round's contest entries prizes

  // begin contest entry's image printing

  const componentRef = useRef();

  const handleContestEntryImagePrinting = useReactToPrint({
    content: () => componentRef.current,
  });

  // end contest entry's image printing

  // begin contest entry rotate image

  const rotateContestEntryImage = async (image) => {
    handleBackdropOpen();
    axios
      .put(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/images/rotate/${image.image.id}`
      )
      .then((respone) => {
        if (respone.status === 200) {
          getDetailContestEntry(contestEntryDetailInfo.contestEntryId);
          setSuccessSnackbarOpen((prevState) => ({
            ...prevState,
            isOpen: true,
            content: "Xoay ảnh thành công ! ảnh bài thi đã được cập nhật",
          }));
          setTimeout(handleBackdropClose(), 1000);
        }
      });
  };

  // end contest entry rotate image

  // begin contest entrie images display

  const contestEntriesImagesDisplay = (listImage) => {
    if (!Array.isArray(listImage) || !(listImage.length > 0)) {
      return null;
    } else {
      return (
        listImage.length > 0 &&
        listImage.map((img, index) => {
          return (
            <Grid
              key={index}
              item
              xs={12}
              display="flex"
              justifyContent="center"
              className="modal-image-wrapper"
            >
              <TransformWrapper
                initialScale={1}
                minScale={1}
                maxScale={isMobile ? 6 : 5}
              >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <React.Fragment>
                    <div className="tools-wrapper">
                      <IconButton onClick={() => zoomIn()}>
                        <AddCircleIcon />
                      </IconButton>
                      <IconButton onClick={() => zoomOut()}>
                        <RemoveCircleIcon />
                      </IconButton>
                      <IconButton onClick={() => resetTransform()}>
                        <RestartAltIcon />
                      </IconButton>
                    </div>
                    <div className="rotate-tools-wrapper">
                      Xoay ảnh
                      <IconButton
                        onClick={() => {
                          rotateContestEntryImage(img);
                        }}
                      >
                        <Rotate90DegreesCwIcon />
                      </IconButton>
                    </div>
                    <TransformComponent wrapperStyle={{ overflow: "visible" }}>
                      <div
                        className="contest-entries-image-display-wrapper"
                        style={{
                          height: isTablet
                            ? isMobile
                              ? "300px"
                              : "600px"
                            : "1000px",
                          width: isTablet
                            ? isMobile
                              ? "300px"
                              : "600px"
                            : "1000px",
                        }}
                      >
                        <img
                          id="contest-entries-display-background-frame"
                          alt="contest entries background frame"
                          src="/images/contest-entries-background-frame.png"
                        />
                        <img
                          style={{
                            transform: `rotate(${img.image.rotateDegree}deg)`,
                            msTransform: `rotate(${img.image.rotateDegree}deg)`,
                            WebkitTransform: `rotate(${img.image.rotateDegree}deg)`,
                            msTransform: `rotate(${img.image.rotateDegree}deg)`,
                          }}
                          className="admin-submission-card-modal-img"
                          alt="submission test"
                          src={
                            process.env.REACT_APP_SERVER_ROOT_MEDIA_URL +
                            img.image.path
                          }
                        />
                      </div>
                    </TransformComponent>
                  </React.Fragment>
                )}
              </TransformWrapper>
            </Grid>
          );
        })
      );
    }
  };

  // end contest entrie images display

  // begin contest entries excel export

  const contestEntriesExcelExport = async () => {
    if (ContestEntries.length === 0) {
      return setErrorSnackbarOpen((prevState) => ({
        ...prevState,
        isOpen: true,
        content: "Xuất file Excel không thành công, không có dữ liệu !",
      }));
    }
    handleBackdropOpen();
    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/contest-entry/?type=${contestEntriesType}`
      )
      .then((res) => {
        if (res.data) {
          const exportCallbackResult = (message) => {
            if (message === "success") {
              handleBackdropClose();
              return setSuccessSnackbarOpen((prevState) => ({
                ...prevState,
                isOpen: true,
                content: "Xuất file Excel thành công !",
              }));
            }
          };
          ExcelExport(res.data.rows, contestEntriesType, exportCallbackResult);
        }
      });
  };

  // end contest entries excel export

  // begin remove contest entry round sequence

  const [
    isContestEntryRemoveRoundSequenceModalOpen,
    setIsContestEntryRemoveRoundSequenceModalOpen,
  ] = React.useState(false);

  const handleContestEntryRemoveRoundSequenceModalOpen = () => {
    setIsContestEntryRemoveRoundSequenceModalOpen(true);
  };

  const handleContestEntryRemoveRoundSequenceModalClose = () => {
    setIsContestEntryRemoveRoundSequenceModalOpen(false);
  };

  const handleRemoveContestEntryRoundSequenceConfirm = () => {
    const contestEntryId = contestEntryDetailInfo.contestEntryId;
    handleBackdropOpen();
    axios
      .put(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/contest-entry/admin/admin-remove-round-sequence/${contestEntryId}`
      )
      .then((res) => {
        if (res.data?.id) {
          handleContestEntryRemoveRoundSequenceModalClose();
          var index = contestEntriesIdArray.indexOf(res.data.id);
          if (index >= 0 && index < contestEntriesIdArray.length - 1) {
            getDetailContestEntry(contestEntriesIdArray[index + 1]);
          } else if (
            index === 0 ||
            index === contestEntriesIdArray.length - 1
          ) {
            handleSubmissionCardModalClose();
          }

          // begin remove graded contest entries out of init list

          var tempContestEntries = [...ContestEntries];
          var tempContestEntriesIdArray = [...contestEntriesIdArray];

          tempContestEntries.splice(index, 1);
          tempContestEntriesIdArray.splice(index, 1);

          setContestEntries(tempContestEntries);
          setContestEntriesIdArray(tempContestEntriesIdArray);

          // end remove graded contest entries out of init list
          if (isSearched) {
            handleSubmitSearchForm(pageData);
          } else {
            getListContestEntriesPerPage(contestEntriesType, pageData);
          }

          handleExamRoundSearchForWeekRoundPrize(res.data.examRoundId);

          setTimeout(() => {
            setSuccessSnackbarOpen((prevState) => ({
              ...prevState,
              isOpen: true,
              content:
                "Cập nhật thành công, bài thi đã được chuyển về vòng thi trước đó !",
            }));

            handleBackdropClose();
          }, 1000);
        } else {
          handleBackdropClose();
        }
      });
  };

  // end remove contest entry round sequence

  // begin get initial data

  const [pageData, setPageData] = React.useState(1);

  const [totalRows, setTotalRows] = React.useState(0);

  const [contestEntriesIdArray, setContestEntriesIdArray] = React.useState([]);

  const getTotalContestEntriesRows = (contestEntriesType) => {
    handleBackdropOpen();
    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/contest-entry/admin/count-all/?type=${contestEntriesType}`
      )
      .then((res) => {
        if (res.data) {
          setTotalRows(res.data.contestEntriesNumber);
          setTimeout(() => {
            handleBackdropClose();
          }, 500);
        } else {
          setTimeout(() => {
            handleBackdropClose();
          }, 500);
        }
      });
  };

  const getListContestEntriesPerPage = (contestEntriesType, page) => {
    if (contestEntriesType && page) {
      handleBackdropOpen();
      setContestEntries([]);
      setContestEntriesIdArray([]);
      axios
        .get(
          process.env.REACT_APP_SERVER_API_ROOT_URL +
            `/contest-entry/?type=${contestEntriesType}&page=${page}`
        )
        .then((res) => {
          if (res.data) {
            setContestEntries(res.data.rows);
            var tempArray = [];
            res.data.rows.forEach((contestEntry) => {
              tempArray.push(contestEntry.id);
            });
            setContestEntriesIdArray(tempArray);
            axios
              .get(
                process.env.REACT_APP_SERVER_API_ROOT_URL +
                  `/contest-entry/admin/count-all/?type=${contestEntriesType}`
              )
              .then((res) => {
                if (res.data) {
                  setTotalRows(res.data.contestEntriesNumber);
                  setTimeout(() => {
                    handleBackdropClose();
                  }, 0);
                } else {
                  setTimeout(() => {
                    handleBackdropClose();
                  }, 0);
                }
              });
          } else {
            setTimeout(() => {
              handleBackdropClose();
            }, 0);
          }
        });
    }
  };

  React.useEffect(() => {
    handleBackdropOpen();
    if (contestEntriesType) {
      setPageData(1);
      setContestEntries([]);
      setContestEntriesIdArray([]);
      axios
        .get(
          process.env.REACT_APP_SERVER_API_ROOT_URL +
            `/contest-entry/?type=${contestEntriesType}&page=${
              pageData ? pageData : 1
            }`
        )
        .then((res) => {
          if (res.data) {
            setContestEntries(res.data.rows);
            var tempArray = [];
            res.data.rows.forEach((contestEntry) => {
              tempArray.push(contestEntry.id);
            });
            setContestEntriesIdArray(tempArray);

            updateAllExamRoundsToFinalRoundSequence();

            axios
              .get(process.env.REACT_APP_SERVER_API_ROOT_URL + `/exam-round`)
              .then((res) => {
                if (res.data) {
                  setExamRounds(res.data.rows);
                  axios
                    .get(process.env.REACT_APP_SERVER_API_ROOT_URL + `/cities`)
                    .then((res) => {
                      if (res.data) {
                        setCities(res.data.rows);
                        axios
                          .get(
                            process.env.REACT_APP_SERVER_API_ROOT_URL +
                              `/exam-types/`
                          )
                          .then((res) => {
                            if (res.data) {
                              setListExamTypes(res.data.rows);
                              axios
                                .get(
                                  process.env.REACT_APP_SERVER_API_ROOT_URL +
                                    `/contest-entry/admin/count-all/?type=${contestEntriesType}`
                                )
                                .then((res) => {
                                  if (res.data) {
                                    setTotalRows(res.data.contestEntriesNumber);
                                    handleBackdropClose();
                                  } else {
                                    handleBackdropClose();
                                  }
                                });
                            } else {
                              handleBackdropClose();
                            }
                          });
                      } else {
                        handleBackdropClose();
                      }
                    });
                  getInitExamRoundForWeekRoundPrizesSearching();
                } else {
                  handleBackdropClose();
                }
              });
          } else {
            handleBackdropClose();
          }
        });
    }
  }, [contestEntriesType]);

  const columns = [
    {
      field: "code",
      headerName: "Mã bài thi",
      headerClassName: "super-app-theme--header",
      width: 120,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: "",
      headerName: "Thao tác",
      headerClassName: "super-app-theme--header",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <Button
          fullWidth
          onClick={() => {
            getDetailContestEntry(params.row.id);
          }}
          variant="contained"
          color="info"
          size="small"
        >
          Xem chi tiết
        </Button>
      ),
    },
    {
      field: "candidateName",
      headerName: "Tên thí sinh",
      headerClassName: "super-app-theme--header",
      width: 250,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: "candidateSchool",
      headerName: "Trường học",
      headerClassName: "super-app-theme--header",
      type: "number",
      width: 200,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: "candidateGender",
      headerName: "Giới tính",
      headerClassName: "super-app-theme--header",
      type: "text",
      width: 100,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {gender_vi[params.value]}
        </Typography>
      ),
    },
    {
      field: "createdAt",
      headerName: "Ngày tham gia",
      headerClassName: "super-app-theme--header",
      type: "text",
      width: 120,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {DateTimeConvert.yyyymmddConvert(params.value)}
        </Typography>
      ),
    }
  ];

  const typeTitleDisplay = (type) => {
    switch (type) {
      case "ungraded":
        return "Bài chưa được chấm";
      case "round-1":
        return "Bài đạt vòng 1";
      case "round-week":
        return "Bài đạt vòng tuần";
      case "round-final":
        return "Bài đạt vòng chung kết";
      case "fail":
        return "Bài không đạt";
      default:
        return "";
    }
  };
  // end get initial data
  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 999 }}
        open={backdropOpen}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ArticleIcon fontSize={isMobile ? "medium" : "large"} />
            <Typography
              variant={isMobile ? "body1" : "quicksand18"}
              style={{
                marginLeft: "10px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              Quản lý bài thi - {typeTitleDisplay(contestEntriesType)}
            </Typography>
          </div>
        </Grid>

        {/* <Grid item xs={12}>
                    <Paper elevation={4}>
                        <Accordion expanded={isContestEntriesAdminExpand}
                            onChange={
                                () => { setContestEntriesAdminExpand(!isContestEntriesAdminExpand) }
                            }
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <AdminPanelSettingsIcon fontSize="medium" />
                                    <Typography variant={isMobile ? "body2" : "body1"} style={{ marginLeft: '10px', fontWeight: 'bold' }}>Quản trị viên</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container justifyContent="flex-start" spacing={2}>
                                    <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                                        <Button startIcon={<SettingsIcon />} onClick={handleExamRoundManagementModalOpen} fullWidth={true} size={isMobile ? "medium" : "large"} color="info" variant="contained">Cập nhật vòng thi trong tuần</Button>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Paper>
                </Grid> */}

        <Grid item xs={12}>
          <Paper elevation={4}>
            <Accordion
              expanded={isContestEntriesSearchExpand}
              onChange={() => {
                setContestEntriesSearchExpand(!isContestEntriesSearchExpand);
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ManageSearchIcon fontSize="medium" />
                  <Typography
                    variant={isMobile ? "body2" : "body1"}
                    style={{ marginLeft: "10px", fontWeight: "bold" }}
                  >
                    Tìm kiếm
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {/* <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                                        <Typography variant="body1">Ngày tham gia:</Typography>
                                        <TextField
                                            value={searchForm.createDate} onChange={handleCreateDateChange}
                                            className={classes.root} size="small" color="primary" type="date" fullWidth variant="outlined" />
                                    </Grid> */}
                  <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                    <Typography variant="body1">Tuần tham gia:</Typography>
                    <FormControl
                      className={classes.root}
                      fullWidth
                      size="small"
                    >
                      <Select
                        value={searchForm.examRound}
                        onChange={handleExamRoundChange}
                      >
                        <MenuItem value={"all"}>{"Tất cả các tuần"}</MenuItem>
                        {examRounds?.length > 0 &&
                          examRounds.map((examRound) => {
                            return (
                              <MenuItem key={examRound.id} value={examRound.id}>
                                {DateTimeConvert.roundResultDateDisplay(
                                  examRound.firstDoW
                                )}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                    <Typography variant="body1">Tỉnh/Thành phố:</Typography>
                    <FormControl
                      className={classes.root}
                      fullWidth
                      size="small"
                    >
                      <Select
                        value={searchForm.city}
                        onChange={handleCityChange}
                      >
                        {Cities?.length > 0 &&
                          Cities.map((city) => {
                            return (
                              <MenuItem key={city.id} value={city.id}>
                                {city.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                    <Typography variant="body1">Quận/Huyện:</Typography>
                    <FormControl
                      className={classes.root}
                      fullWidth
                      size="small"
                    >
                      <Select
                        value={searchForm.district}
                        onChange={handleDistrictChange}
                      >
                        {Districts?.length > 0 &&
                          Districts.map((district) => {
                            return (
                              <MenuItem key={district.id} value={district.id}>
                                {district.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                    <Typography variant="body1">Mã bài thi:</Typography>
                    <TextField
                      value={searchForm.examCode}
                      onChange={handleExamCodeChange}
                      className={classes.root}
                      size="small"
                      color="info"
                      type="text"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                    <Typography variant="body1">Tên thí sinh:</Typography>
                    <TextField
                      value={searchForm.candidateName}
                      onChange={handleCandidateNameChange}
                      className={classes.root}
                      size="small"
                      color="info"
                      type="text"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                    <Typography variant="body1">Ngày sinh:</Typography>
                    <TextField
                      value={searchForm.candidateDob}
                      onChange={handleCandidateDobChange}
                      className={classes.root}
                      size="small"
                      color="info"
                      type="date"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                    <Typography variant="body1">SĐT liên lạc:</Typography>
                    <TextField
                      value={searchForm.userPhone}
                      onChange={handleUserPhoneChange}
                      className={classes.root}
                      size="small"
                      color="info"
                      type="tel"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                    <Typography variant="body1">Trường học:</Typography>
                    <TextField
                      value={searchForm.candidateSchool}
                      onChange={handleCandidateSchoolChange}
                      className={classes.root}
                      size="small"
                      color="info"
                      type="text"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                    <Typography variant="body1">Hình thức:</Typography>
                    <FormControl
                      className={classes.root}
                      fullWidth
                      size="small"
                    >
                      <Select
                        value={searchForm.examType}
                        onChange={handleExamTypeChange}
                      >
                        {listExamTypes?.length > 0 &&
                          listExamTypes.map((examType) => {
                            return (
                              <MenuItem key={examType.id} value={examType.id}>
                                {examType.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                  {formError.field && formError.text && (
                    <Grid item xs={12}>
                      <Alert
                        severity="error"
                        style={{ fontSize: `${isMobile ? "13px" : "16px"}` }}
                      >
                        <AlertTitle>
                          <strong>Có lỗi xảy ra</strong>
                        </AlertTitle>
                        {formError.text}
                      </Alert>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  justifyContent="flex-end"
                  style={{ marginTop: "20px" }}
                >
                  {/* <Button startIcon={<RefreshIcon />} onClick={resetSearchForm} variant="contained" color="success" size={isMobile ? "small" : "medium"} style={{ marginRight: `${isMobile ? '15px' : '20px'}` }}>Làm mới</Button> */}
                  <Button
                    startIcon={<SearchIcon />}
                    onClick={() => {
                      setPageData(1);
                      handleSubmitSearchForm(1);
                    }}
                    variant="contained"
                    color="info"
                    size={isMobile ? "small" : "medium"}
                  >
                    Tìm kiếm
                  </Button>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={4}>
            <Accordion
              expanded={isContestEntriesStatisticExpand}
              onChange={() => {
                setContestEntriesStatisticExpand(
                  !isContestEntriesStatisticExpand
                );
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ListAltIcon fontSize="medium" />
                  <Typography
                    variant={isMobile ? "body2" : "body1"}
                    style={{ marginLeft: "10px", fontWeight: "bold" }}
                  >
                    Thống kê toàn bộ bài thi
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography variant="body1">Tuần tham gia:</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl
                          className={classes.root}
                          fullWidth
                          size="small"
                        >
                          <Select
                            value={examRoundForContestEntriesStatistic}
                            onChange={(e) => {
                              setExamRoundForContestEntriesStatistic(
                                e.target.value
                              );
                            }}
                          >
                            <MenuItem value={"all"}>
                              {"Tất cả các tuần"}
                            </MenuItem>
                            {examRounds?.length > 0 &&
                              examRounds.map((examRound) => {
                                return (
                                  <MenuItem
                                    key={examRound.id}
                                    value={examRound.id}
                                  >
                                    {DateTimeConvert.roundResultDateDisplay(
                                      examRound.firstDoW
                                    )}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      Tổng số bài tham gia:{" "}
                      <strong>{totalContestEntries} bài</strong>
                    </Typography>
                  </Grid>
                  <Grid item lg={3} md={4} xs={12}>
                    <ContestEntriesNumberCard
                      text={"Số bài chưa chấm"}
                      number={totalUngradedRoundContestEntries}
                      color="primary"
                    />
                  </Grid>
                  <Grid item lg={3} md={4} xs={12}>
                    <ContestEntriesNumberCard
                      text={"Số bài đạt vòng 1"}
                      number={totalFirstRoundContestEntries}
                      color="success"
                    />
                  </Grid>
                  <Grid item lg={3} md={4} xs={12}>
                    <ContestEntriesNumberCard
                      text={"Số bài đạt vòng tuần"}
                      number={totalWeekRoundContestEntries}
                      color="success"
                    />
                  </Grid>
                  <Grid item lg={3} md={4} xs={12}>
                    <ContestEntriesNumberCard
                      text={"Số bài không đạt"}
                      number={totalFailedContestEntries}
                      color="error"
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={4}>
            <Accordion
              expanded={weekRoundPrizeStatisticExpand}
              onChange={() => {
                setWeekRoundPrizeStatisticExpand(
                  !weekRoundPrizeStatisticExpand
                );
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <EmojiEventsIcon fontSize="medium" />
                  <Typography
                    variant={isMobile ? "body2" : "body1"}
                    style={{ marginLeft: "10px", fontWeight: "bold" }}
                  >
                    Thống kê các bài thi đạt giải tuần
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography variant="body1">Tuần tham gia:</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl
                          className={classes.root}
                          fullWidth
                          size="small"
                        >
                          <Select
                            value={examRoundForWeekRoundPrizeSearch}
                            onChange={(e) => {
                              setExamRoundForWeekRoundPrizeSearch(
                                e.target.value
                              );
                            }}
                          >
                            {examRounds?.length > 0 &&
                              examRounds.map((examRound) => {
                                return (
                                  <MenuItem
                                    key={examRound.id}
                                    value={examRound.id}
                                  >
                                    {DateTimeConvert.roundResultDateDisplay(
                                      examRound.firstDoW
                                    )}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      Tổng số bài thi đạt giải trong tuần:{" "}
                      <strong>
                        {String(
                          currentWeekRoundPrize.poemWriting +
                            currentWeekRoundPrize.feelingWriting +
                            currentWeekRoundPrize.goodWork
                        )
                          .concat("/")
                          .concat(
                            String(
                              max_week_round_poem_writing_prizes +
                                max_week_round_feeling_writing_prizes +
                                max_week_round_good_work_prizes
                            )
                          )}
                      </strong>
                    </Typography>
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <WeekRoundPrizesNumberCard
                      text={"Viết chữ đẹp"}
                      number={currentWeekRoundPrize.poemWriting}
                      maxNumber={max_week_round_poem_writing_prizes}
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <WeekRoundPrizesNumberCard
                      text={"Viết cảm nhận về clip"}
                      number={currentWeekRoundPrize.feelingWriting}
                      maxNumber={max_week_round_feeling_writing_prizes}
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <WeekRoundPrizesNumberCard
                      text={"Viết về việc tốt quanh em"}
                      number={currentWeekRoundPrize.goodWork}
                      maxNumber={max_week_round_good_work_prizes}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="flex-end"
            style={{ marginBottom: "10px" }}
          >
            <Button
              startIcon={<FileDownloadIcon />}
              size="medium"
              variant="contained"
              color="warning"
              sx={{ marginRight: '5px' }}
              //onClick={contestEntriesExcelExport}
            >
              <a style={{ color: '#fff !important' }} target='_blank' href={process.env.REACT_APP_SERVER_API_ROOT_URL + '/contest-entry/admin/download-images' + `?type=${contestEntriesType}&createDate=${searchForm.createDate}&examRound=${searchForm.examRound}&city=${searchForm.city}&district=${searchForm.district}&examCode=${searchForm.examCode}&candidateName=${searchForm.candidateName}&candidateDob=${searchForm.candidateDob}&userPhone=${searchForm.userPhone}&candidateSchool=${searchForm.candidateSchool}&examType=${searchForm.examType}`}>Tải ảnh</a>
            </Button>
            <Button
              startIcon={<FileDownloadIcon />}
              size="medium"
              variant="contained"
              color="success"
              //onClick={contestEntriesExcelExport}
            >
              <a style={{ color: '#fff !important' }} target='_blank' href={process.env.REACT_APP_SERVER_API_ROOT_URL + `/contest-entry/get/export-report?type=${contestEntriesType}&createDate=${searchForm.createDate}&examRound=${searchForm.examRound}&city=${searchForm.city}&district=${searchForm.district}&examCode=${searchForm.examCode}&candidateName=${searchForm.candidateName}&candidateDob=${searchForm.candidateDob}&userPhone=${searchForm.userPhone}&candidateSchool=${searchForm.candidateSchool}&examType=${searchForm.examType}`}>Xuất excel</a>
            </Button>
          </Grid>
          <Paper elevation={4} style={{ padding: "0px" }}>
            <Box
              sx={{
                height: 600,
                width: 1,
                "& .super-app-theme--header": {
                  fontWeight: "bolder",
                  fontSize: "16px",
                  color: "#000000",
                },
              }}
            >
              <DataGrid
                localeText={viVN.components.MuiDataGrid.defaultProps.localeText}
                rows={ContestEntries}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                paginationMode={"server"}
                rowCount={totalRows}
                onPageChange={(page, details) => {
                  setPageData(page + 1);
                  if (isSearched) {
                    handleSubmitSearchForm(page + 1);
                  } else {
                    getListContestEntriesPerPage(contestEntriesType, page + 1);
                  }
                }}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        fullScreen={fullScreen}
        open={submissionCardModalOpen}
        onClose={handleSubmissionCardModalClose}
      >
        <DialogContent>
          <Grid
            container
            rowSpacing={2}
            className="submission-card-modal-box-inside-wrapper"
          >
            <Grid
              item
              xs={12}
              className="admin-detail-submission-card-modal-title-wrapper"
            >
              <Grid
                container
                justifyContent="center"
                spacing={isMobile ? 1 : 2}
                style={{ position: "relative" }}
              >
                <Grid item xs={12}>
                  <Typography
                    variant="quicksand18shadow"
                    className="submission-card-modal-title"
                    sx={{ fontWeight: "700" }}
                  >
                    Ảnh mô tả bài thi:
                  </Typography>
                  <div
                    style={{ margin: "0", padding: "0" }}
                  >
                    {contestEntryDetailInfo.contestEntriesImages.length > 0 &&
                      contestEntryDetailInfo.contestEntriesImages.map(
                        (img, index) => {
                          return (
                            <div>
                              <a
                                href={
                                  process.env
                                    .REACT_APP_SERVER_ROOT_MEDIA_URL +
                                  img.image.path
                                }
                                download={`${contestEntryDetailInfo.examCode}__${img.image.id.slice(0, 8).toUpperCase()}.png`}
                                target='_blank'
                                onClick={e => downloadImage(e)}
                              >{`${contestEntryDetailInfo.examCode}__${img.image.id.slice(0, 8).toUpperCase()}.png`}</a>
                            </div>
                          );
                        }
                      )}
                  </div>
                </Grid>
                {contestEntryDetailInfo.contestEntriesImages.length > 1 && (
                  <img
                    onClick={onModalNextArrowClick}
                    className="submission-modal-next-btn"
                    alt="submissions modal next btn"
                    src={
                      process.env.PUBLIC_URL +
                      "/images/homepage-slider-icon-next.png"
                    }
                  />
                )}
                {contestEntryDetailInfo.contestEntriesImages.length > 1 && (
                  <img
                    onClick={onModalPrevArrowClick}
                    className="submission-modal-prev-btn"
                    alt="submissions modal back btn"
                    src={
                      process.env.PUBLIC_URL +
                      "/images/homepage-slider-icon-back.png"
                    }
                  />
                )}
                <Carousel
                  infiniteLoop={true}
                  showStatus={false}
                  showIndicators={false}
                  showThumbs={false}
                  swipeable={false}
                  width={isTablet ? (isMobile ? 300 : 600) : 1000}
                >
                  {contestEntryDetailInfo.contestEntriesImages.length > 0 &&
                    contestEntriesImagesDisplay(
                      contestEntryDetailInfo.contestEntriesImages
                    )}
                </Carousel>
                <div className="print-container-wrapper">
                  <div
                    ref={componentRef}
                    className="print-container"
                    style={{ margin: "0", padding: "0" }}
                  >
                    {contestEntryDetailInfo.contestEntriesImages.length > 0 &&
                      contestEntryDetailInfo.contestEntriesImages.map(
                        (img, index) => {
                          return (
                            <React.Fragment key={index}>
                              <div className="page-break" />
                              <div>
                                <img
                                  style={{ width: "100%" }}
                                  alt="submission test"
                                  src={
                                    process.env
                                      .REACT_APP_SERVER_ROOT_MEDIA_URL +
                                    img.image.path
                                  }
                                />
                              </div>
                            </React.Fragment>
                          );
                        }
                      )}
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Mã bài thi: {contestEntryDetailInfo.examCode}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Họ tên thí sinh: {contestEntryDetailInfo.candidateName}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Ngày sinh: {contestEntryDetailInfo.candidateDob}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                SĐT liên lạc: {contestEntryDetailInfo.userPhone}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Trường: {contestEntryDetailInfo.candidateSchool}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Địa chỉ liên lạc: {contestEntryDetailInfo.address},{" "}
                {contestEntryDetailInfo.district}, {contestEntryDetailInfo.city}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Tên chủ đề: {contestEntryDetailInfo.subject}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Nội dung tác phẩm: {contestEntryDetailInfo.content}
              </Typography>
            </Grid>
            {/* <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Ý nghĩa: {contestEntryDetailInfo.sense}
              </Typography>
            </Grid> */}
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Hình thức thi: {contestEntryDetailInfo.examType}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Ngày tham gia: {contestEntryDetailInfo.contestJoinDate}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Tuần tham gia: {contestEntryDetailInfo.examRound?.firstDoW}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Lượt xem: {contestEntryDetailInfo.views} lượt
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Kết quả hiện tại:{" "}
                {contestEntryDetailInfo.roundSequence?.id
                  ? contestEntryDetailInfo.roundSequence?.name
                      .concat(" - ")
                      .concat(result_vi[contestEntryDetailInfo.status])
                  : "Vòng 1"
                      .concat(" - ")
                      .concat(result_vi[contestEntryDetailInfo.status])}
              </Typography>
            </Grid>
            {contestEntryDetailInfo.status !== "FAIL" && (
              <Grid
                item
                xs={12}
                className="submission-card-modal-title-wrapper"
              >
                <Typography
                  variant="quicksand18shadow"
                  className="submission-card-modal-title"
                  sx={{ fontWeight: "700" }}
                >
                  Vòng thi hiện tại:{" "}
                  {currentRoundSequence.id
                    ? currentRoundSequence.name
                    : "Chưa mở vòng thi tiếp theo !"}
                </Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        {isMobile ? (
          <DialogActions style={{ padding: "15px" }}>
            <Grid container justifyContent="flex-end" spacing={1}>
              <Grid item>
                <Grid container justifyContent="center" spacing={1}>
                  <Grid item xs={6} display="flex" justifyContent="flex-end">
                    <Button
                      disabled={
                        contestEntriesIdArray.indexOf(
                          contestEntryDetailInfo.contestEntryId
                        ) === 0
                      }
                      startIcon={<ArrowLeftIcon fontSize="large" />}
                      variant="contained"
                      color="primary"
                      size={"small"}
                      onClick={displayPrevContestEntry}
                    >
                      Trước
                    </Button>
                  </Grid>
                  <Grid item xs={6} display="flex" justifyContent="flex-end">
                    <Button
                      disabled={
                        contestEntriesIdArray.indexOf(
                          contestEntryDetailInfo.contestEntryId
                        ) ===
                        contestEntriesIdArray.length - 1
                      }
                      endIcon={<ArrowRightIcon fontSize="large" />}
                      variant="contained"
                      color="primary"
                      size={"small"}
                      onClick={displayNextContestEntry}
                    >
                      Sau
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={1} justifyContent={"flex-end"}>
                  {currentRoundSequence.id &&
                    contestEntriesType !== "fail" &&
                    contestEntryDetailInfo.status !== "FAIL" &&
                    contestEntriesType !== "all" && (
                      <React.Fragment>
                        <Grid item display="flex" justifyContent="flex-end">
                          <Button
                            variant="contained"
                            color="success"
                            size={isMobile ? "small" : "medium"}
                            onClick={() => {
                              gradingSubmit(
                                contestEntryDetailInfo.contestEntryId,
                                "PASS"
                              );
                            }}
                          >
                            Đạt {currentRoundSequence.name}
                          </Button>
                        </Grid>
                        <Grid item display="flex" justifyContent="flex-end">
                          <Button
                            variant="contained"
                            color="error"
                            size={isMobile ? "small" : "medium"}
                            onClick={() => {
                              gradingSubmit(
                                contestEntryDetailInfo.contestEntryId,
                                "FAIL"
                              );
                            }}
                          >
                            Không đạt {currentRoundSequence.name}
                          </Button>
                        </Grid>
                      </React.Fragment>
                    )}
                  <Grid item display="flex" justifyContent="flex-end">
                    {contestEntriesType === "fail" &&
                      contestEntryDetailInfo.status === "FAIL" && (
                        <Button
                          variant="contained"
                          color="success"
                          size={"small"}
                          onClick={() => {
                            reverseGradingConfirm(
                              contestEntryDetailInfo.contestEntryId
                            );
                          }}
                        >
                          Khôi phục
                        </Button>
                      )}
                  </Grid>
                  {contestEntryDetailInfo.status === "PASS" &&
                    contestEntriesType !== "all" &&
                    contestEntriesType !== "fail" && (
                      <Grid item display="flex" justifyContent="flex-end">
                        <Button
                          variant="contained"
                          color="error"
                          size={isMobile ? "small" : "medium"}
                          onClick={
                            handleContestEntryRemoveRoundSequenceModalOpen
                          }
                        >
                          Xóa khỏi {contestEntryDetailInfo.roundSequence.name}
                        </Button>
                      </Grid>
                    )}
                  <Grid item display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="semiBlack"
                      size={isMobile ? "small" : "medium"}
                      onClick={handleSubmissionCardModalClose}
                    >
                      Đóng
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        ) : (
          <DialogActions style={{ padding: "15px" }}>
            <Button
              disabled={
                contestEntriesIdArray.indexOf(
                  contestEntryDetailInfo.contestEntryId
                ) === 0
              }
              startIcon={<ArrowLeftIcon fontSize="large" />}
              variant="contained"
              color="primary"
              size={"medium"}
              onClick={displayPrevContestEntry}
            >
              Trước
            </Button>
            <Button
              disabled={
                contestEntriesIdArray.indexOf(
                  contestEntryDetailInfo.contestEntryId
                ) ===
                contestEntriesIdArray.length - 1
              }
              endIcon={<ArrowRightIcon fontSize="large" />}
              variant="contained"
              color="primary"
              size={"medium"}
              onClick={displayNextContestEntry}
            >
              Sau
            </Button>
            {contestEntriesType === "fail" &&
              contestEntryDetailInfo.status === "FAIL" && (
                <Button
                  variant="contained"
                  color="success"
                  size={"medium"}
                  endIcon={<RestoreIcon fontSize="large" />}
                  onClick={() => {
                    reverseGradingConfirm(
                      contestEntryDetailInfo.contestEntryId
                    );
                  }}
                >
                  Khôi phục
                </Button>
              )}
            <Button
              endIcon={<PrintIcon fontSize="large" />}
              variant="contained"
              color="neutral"
              size={"medium"}
              onClick={handleContestEntryImagePrinting}
            >
              In bài thi
            </Button>
            {currentRoundSequence.id &&
              contestEntriesType !== "fail" &&
              contestEntryDetailInfo.status !== "FAIL" &&
              contestEntriesType !== "all" && (
                <React.Fragment>
                  <Button
                    variant="contained"
                    color="success"
                    size={isMobile ? "small" : "medium"}
                    onClick={() => {
                      gradingSubmit(
                        contestEntryDetailInfo.contestEntryId,
                        "PASS"
                      );
                    }}
                  >
                    Đạt {currentRoundSequence.name}
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    size={isMobile ? "small" : "medium"}
                    onClick={() => {
                      gradingSubmit(
                        contestEntryDetailInfo.contestEntryId,
                        "FAIL"
                      );
                    }}
                  >
                    Không đạt {currentRoundSequence.name}
                  </Button>
                </React.Fragment>
              )}
            {contestEntryDetailInfo.status === "PASS" &&
              contestEntriesType !== "all" &&
              contestEntriesType !== "fail" && (
                <Button
                  variant="contained"
                  color="error"
                  size={isMobile ? "small" : "medium"}
                  onClick={handleContestEntryRemoveRoundSequenceModalOpen}
                >
                  Xóa khỏi {contestEntryDetailInfo.roundSequence.name}
                </Button>
              )}
            <Button
              variant="contained"
              color="semiBlack"
              size={isMobile ? "small" : "medium"}
              onClick={handleSubmissionCardModalClose}
            >
              Đóng
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <SuccessSnackbar
        isOpen={successSnackbarOpen.isOpen}
        content={successSnackbarOpen.content}
        onClose={() => {
          setSuccessSnackbarOpen((prevState) => ({
            ...prevState,
            isOpen: false,
            content: "",
          }));
        }}
      />
      <ErrorSnackbar
        isOpen={errorSnackbarOpen.isOpen}
        content={errorSnackbarOpen.content}
        onClose={() => {
          setErrorSnackbarOpen((prevState) => ({
            ...prevState,
            isOpen: false,
            content: "",
          }));
        }}
      />
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={examRoundManagementModalOpen}
        onClose={handleExamRoundManagementModalClose}
      >
        <DialogContent>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Typography
                style={{ fontWeight: "bold" }}
                align="center"
                variant={isMobile ? "quicksand16" : "quicksand20"}
              >
                Cập nhật vòng thi trong tuần
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                Chọn tuần thi muốn cập nhật:
              </Typography>
              <FormControl className={classes.root} fullWidth size="small">
                <Select
                  value={updateExamRoundForm.examRoundId}
                  onChange={(e) => {
                    setUpdateExamRoundForm((prevState) => ({
                      ...prevState,
                      examRoundId: e.target.value,
                    }));
                    setUpdateExamRoundError("");
                    getExamRoundInfo(e.target.value);
                  }}
                >
                  {listExamRounds.length > 0 &&
                    listExamRounds.map((examround, index) => {
                      return (
                        <MenuItem key={index} value={examround.id}>
                          {DateTimeConvert.roundResultDateDisplay(
                            examround.firstDoW
                          )}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                Vòng thi hiện tại:{" "}
                <strong>{examRoundSelected.roundSequenceName}</strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Chọn vòng thi:</Typography>
              <FormControl className={classes.root} fullWidth size="small">
                <Select
                  value={updateExamRoundForm.roundSequenceId}
                  onChange={(e) => {
                    setUpdateExamRoundForm((prevState) => ({
                      ...prevState,
                      roundSequenceId: e.target.value,
                    }));
                    setUpdateExamRoundError("");
                  }}
                >
                  {listRoundSequences.length > 0 ? (
                    listRoundSequences.map((roundSequence, index) => {
                      return (
                        <MenuItem key={index} value={roundSequence.id}>
                          {roundSequence.name}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value="" disabled={true}>
                      {"Chưa có vòng thi tiếp theo !"}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            {updateExamRoundError && (
              <Grid item xs={12}>
                <Alert
                  severity="error"
                  style={{ fontSize: `${isMobile ? "13px" : "16px"}` }}
                >
                  <AlertTitle>
                    <strong>Có lỗi xảy ra</strong>
                  </AlertTitle>
                  {updateExamRoundError}
                </Alert>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: "15px" }}>
          <Button
            variant="contained"
            color="info"
            size="medium"
            onClick={handleUpdateExamRound}
          >
            Cập nhật
          </Button>
          <Button
            variant="contained"
            color="semiBlack"
            size="medium"
            onClick={handleExamRoundManagementModalClose}
          >
            Đóng
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={isContestEntryRemoveRoundSequenceModalOpen}
        onClose={handleContestEntryRemoveRoundSequenceModalClose}
      >
        <DialogContent>
          <Typography
            color="error"
            style={{
              fontWeight: "bold",
              fontSize: `${isMobile ? "13px" : "16px"}`,
            }}
          >
            Xác nhận xóa bài thi khỏi{" "}
            {contestEntryDetailInfo.roundSequence.name} ? Sau khi xóa, bài thi
            sẽ chuyển về vòng thi trước đó
          </Typography>
        </DialogContent>
        <DialogActions style={{ padding: "15px" }}>
          <Button
            variant="contained"
            color="primary"
            size={isMobile ? "small" : "medium"}
            onClick={handleRemoveContestEntryRoundSequenceConfirm}
          >
            Xác nhận
          </Button>
          <Button
            variant="contained"
            color="semiBlack"
            size={isMobile ? "small" : "medium"}
            onClick={handleContestEntryRemoveRoundSequenceModalClose}
          >
            Hủy
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
