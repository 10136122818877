import "./ExamPageHeader.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { CardMedia } from "@mui/material";

export default function ExamPageHeader() {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <div className="exam-header-wrapper">
      <img
        className="submission-search-page-search-background"
        alt="submission search page search background"
        src="images/SubmissionSearchPage/search-background.png"
      />
      {isMobile ? (
        <CardMedia
          component="img"
          image="/images/SubmissionSearchPage/bg-top-mobile.png"
          sx={{ position: "relative" }}
        ></CardMedia>
      ) : (
        <CardMedia
          component="img"
          image="/images/Information/bg-top.png"
          sx={{ position: "relative" }}
        ></CardMedia>
      )}
    </div>
  );
}
