import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'

let store = (set) => ({
  accessToken: null,
  userId: null,
  username: null,
  userRoleName: null,

  login: (data) => {
    set((state) => ({
      ...state,
      userId: data.userInfo.id,
      username: data.userInfo.username,
      accessToken: data.accessToken,
      userRoleName: data.userInfo.role.name,
    }))
  },
  logout: () => {
    set((state) => ({
      ...state,
      userId: null,
      username: null,
      accessToken: null,
      userRoleName: null,
    }))
  },
})

store = devtools(store) // Allow redux devtool debug
store = persist(store, { name: 'user' }) // Persist to local storage

export default create(store)
