import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import './Header.css';
import {
  Link,
  useLocation
} from "react-router-dom";
import Drawer from '@mui/material/Drawer';
import { useMediaQuery } from 'react-responsive';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '19px',
  backgroundColor: '#FFFFFF',
  width: '100%',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: '0px',
  right: '0px'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '10px',
    paddingRight: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

export default function Header() {
  const location = useLocation();
  const urlPath = location.pathname;
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` })
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const handleMobileClose = () => {
    setMobileOpen(false)
  }

  const handleMobileOpen = () => {
    setMobileOpen(true)
  }

  if (urlPath.slice(0, 6) === '/admin') {
    return null
  } else {
    return (
      <React.Fragment>
        {isMobile ?
          (
            <React.Fragment>
              <AppBar className="mobile-header-wrapper" position="static">
                <div className="mobile-header-container">
                  <IconButton onClick={handleMobileOpen} className="mobile-header-menu-button" color="inherit" size='large' aria-label="upload picture" component="span">
                    <MenuIcon fontSize='large' className='mobile-header-menu-icon' />
                  </IconButton>
                  <img className='mobile-header-doan-hoi-icon' alt="doan hoi icon" src="images/HDDTW.jpg" />
                </div>
              </AppBar>
              <Drawer
                anchor={'left'}
                open={mobileOpen}
                onClose={handleMobileOpen}
                style={{ maxHeight: '100vh !important' }}
              >
                <Grid container display="flex" justifyContent="flex-start" className="header-mobile-menu-wrapper">
                  <img onClick={handleMobileClose} className="mobile-header-close-button" alt="mobile header close button" src="images/mobile-header-close-button.png" />
                  <Grid item xs={12}>
                    <Search>
                      <StyledInputBase
                      />
                      <SearchIconWrapper>
                        <SearchIcon style={{ color: '#42BDB2' }} />
                      </SearchIconWrapper>
                    </Search>
                  </Grid>
                  <Grid item xs={12}>
                    <Link to="/" style={{ textDecoration: "none" }}>
                      <Typography onClick={handleMobileClose} className="mobile-header-link" variant="body1" style={{ color: `${urlPath === '/' ? '#FBED8C' : '#FFFFFF'}` }}>
                        Trang chủ
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Link to="/information" style={{ textDecoration: "none" }}>
                      <Typography onClick={handleMobileClose} className="mobile-header-link" variant="body1" style={{ color: `${urlPath === '/information' ? '#FBED8C' : '#FFFFFF'}` }}>
                        Thông tin
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Link to="/submit-exam" style={{ textDecoration: "none" }}>
                      <Typography onClick={handleMobileClose} className="mobile-header-link" variant="body1" style={{ color: `${urlPath === '/submit-exam' ? '#FBED8C' : '#FFFFFF'}` }}>
                        Nộp bài tham gia
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Link to="/submission-search" style={{ textDecoration: "none" }}>
                      <Typography onClick={handleMobileClose} className="mobile-header-link" variant="body1" style={{ color: `${urlPath === '/submission-search' ? '#FBED8C' : '#FFFFFF'}` }}>
                        Thư viện
                      </Typography>
                    </Link>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Link to="/results" style={{ textDecoration: "none" }}>
                      <Typography onClick={handleMobileClose} className="mobile-header-link" variant="body1" style={{ color: `${urlPath === '/results' ? '#FBED8C' : '#FFFFFF'}` }}>
                        Bảng kết quả
                      </Typography>
                    </Link>
                  </Grid> */}
                  <Grid item xs={12}>
                    <Link to="/" style={{ textDecoration: "none" }}>
                      <Avatar onClick={handleMobileClose} variant="square" alt="Logo chuong trinh" src="images/logo_bhw.png" sx={{ width: 64, height: 93 }} />
                    </Link>
                  </Grid>
                </Grid>
              </Drawer>
            </React.Fragment>
          ) :
          (
            <AppBar className="header-wrapper" position="static" style={{ zIndex: 9 }}>
              <Toolbar>
                <Grid container spacing={1} justifyContent="center">
                  <Grid item xs={10}>
                    <Grid container spacing={0} justifyContent="center">
                      <Grid item xs={1} className="image-wrapper">
                        <Link to="/" style={{ textDecoration: "none" }}>
                          <Avatar variant="square" alt="Logo doan thanh nien" src="images/HDDTW.jpg" sx={{ width: 160, height: 110 }} />
                        </Link>
                      </Grid >
                      <Grid item xs={10} className="header-link-wrapper">
                        <Grid container spacing={8} justifyContent="center">
                          <Grid item>
                            <Link to="/" style={{ textDecoration: "none" }}>
                              <Typography className="header-link" variant="body1" style={{ color: `${urlPath === '/' ? '#FBED8C' : '#FFFFFF'}` }}>
                                Trang chủ
                              </Typography>
                            </Link>
                          </Grid>
                          <Grid item>
                            <Link to="/information" style={{ textDecoration: "none" }}>
                              <Typography className="header-link" variant="body1" style={{ color: `${urlPath === '/information' ? '#FBED8C' : '#FFFFFF'}` }}>
                                Thông tin
                              </Typography>
                            </Link>
                          </Grid>
                          <Grid item>
                            <Link to="/submit-exam" style={{ textDecoration: "none" }}>
                              <Typography className="header-link" variant="body1" style={{ color: `${urlPath === '/submit-exam' ? '#FBED8C' : '#FFFFFF'}` }}>
                                Nộp bài tham gia
                              </Typography>
                            </Link>
                          </Grid>
                          <Grid item>
                            <Link to="/submission-search" style={{ textDecoration: "none" }}>
                              <Typography className="header-link" variant="body1" style={{ color: `${urlPath === '/submission-search' ? '#FBED8C' : '#FFFFFF'}` }}>
                                Thư viện
                              </Typography>
                            </Link>
                          </Grid>
                          {/* <Grid item>
                            <Link to="/results" style={{ textDecoration: "none" }}>
                              <Typography className="header-link" variant="body1" style={{ color: `${urlPath === '/results' ? '#FBED8C' : '#FFFFFF'}` }}>
                                Bảng kết quả
                              </Typography>
                            </Link>
                          </Grid> */}
                        </Grid>
                      </Grid>
                      {/* <Grid item xs={1} className="image-wrapper">
                      <Link to="/" style={{ textDecoration: "none" }}>
                        <Avatar variant="square" alt="Logo doan thanh nien" src="images/logo_bhw.png" sx={{ width: 60, height: 82 }} />
                      </Link>
                    </Grid> */}
                    </Grid >
                  </Grid >
                </Grid >
              </Toolbar >
            </AppBar >
          )
        }
      </React.Fragment >
    )
  }

}