import { Grid, Paper, Typography, Modal, Box } from "@mui/material";
import "./HomePageTopSubmissions.css";
import SubmissionCard from "../../SubmissionCard";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import React from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { makeStyles } from "@material-ui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles({
  modal: {
    borderRadius: "20px !important",
  },
});

export default function HomePageTopSubmissions() {
  const isMobile = useMediaQuery("(max-width:420px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const classes = useStyles();
  const [submissionCardModalOpen, updateSubmissionCardModalOpen] =
    React.useState(false);
  const fullScreen = useMediaQuery("(max-width:420px)");
  const [selectedData, setSelectedData] = React.useState({
    imgPath: [],
    candidateName: "",
    subject: "",
    content: "",
    sense: "",
    candidateSchool: "",
    district: "",
    city: "",
  });

  const handleSubmissionCardModalOpen = (selectedContestEntry) => {
    updateSubmissionCardModalOpen(true);
    setSelectedData((prevState) => ({
      ...prevState,
      imgPath: selectedContestEntry.contestEntriesImages,
      candidateName: selectedContestEntry
        ? selectedContestEntry.candidateName
        : "",
      subject: selectedContestEntry ? selectedContestEntry.subject : "",
      content: selectedContestEntry ? selectedContestEntry.content : "",
      sense: selectedContestEntry ? selectedContestEntry.sense : "",
      district: selectedContestEntry
        ? selectedContestEntry.user.district.name
        : "",
      city: selectedContestEntry ? selectedContestEntry.user.city.name : "",
      candidateSchool: selectedContestEntry
        ? selectedContestEntry.candidateSchool
        : "",
    }));
    axios
      .put(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/contest-entry/count-view-number/${selectedContestEntry.id}`
      )
      .then((res) => {})
      .catch((error) => console.log(error));
  };

  const handleSubmissionCardModalClose = () => {
    updateSubmissionCardModalOpen(false);
  };

  const [totalContestEntry, setTotalContestEntry] = React.useState(0);

  const onWeekNextArrowClick = () => {
    let parent = document.querySelector(".week-top-sumission-wrapper");
    let arrow = parent.querySelector(".control-next");
    arrow.click();
  };

  const onWeekPrevArrowClick = () => {
    let parent = document.querySelector(".week-top-sumission-wrapper");
    let arrow = parent.querySelector(".control-prev");
    arrow.click();
  };

  const onMonthNextArrowClick = () => {
    let parent = document.querySelector(".top-submissions-in-month-wrapper");
    let arrow = parent.querySelector(".control-next");
    arrow.click();
  };

  const onMonthPrevArrowClick = () => {
    let parent = document.querySelector(".top-submissions-in-month-wrapper");
    let arrow = parent.querySelector(".control-prev");
    arrow.click();
  };

  const onModalNextArrowClick = () => {
    let parent = document.querySelector(
      ".top-week-submission-card-modal-title-wrapper"
    );
    let arrow = parent.querySelector(".control-next");
    arrow.click();
  };

  const onModalPrevArrowClick = () => {
    let parent = document.querySelector(
      ".top-week-submission-card-modal-title-wrapper"
    );
    let arrow = parent.querySelector(".control-prev");
    arrow.click();
  };

  const [contestEntries, setContestEntries] = React.useState([]);

  const [contestEntriesMonth, setContestEntriesMonth] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          "/contest-entry/get/count-all"
      )
      .then((response) => {
        setTotalContestEntry(response.data.total || 0);
      });

    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/contest-entry/get/nearest-exam-round`
      )
      .then((res) => {
        if (res.data) {
          setContestEntries(res.data.rows);
        }
      });

    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/contest-entry/get/nearest-month`
      )
      .then((res) => {
        if (res.data) {
          setContestEntriesMonth(res.data.rows);
        }
      });
  }, []);

  const contestDisplay = (listContest) => {
    if (listContest.length === 0) {
      return null;
    }
    if (listContest.length <= 3) {
      return [
        <Grid
          container
          key={listContest[0].id}
          justifyContent="center"
          spacing={3}
        >
          {listContest.map((contestEntry, index) => {
            return (
              <Grid
                key={contestEntry.id}
                item
                xs={4}
                onClick={() => handleSubmissionCardModalOpen(contestEntry)}
              >
                <SubmissionCard cardData={contestEntry} />
              </Grid>
            );
          })}
        </Grid>,
      ];
    }
    return [
      <Grid
        container
        key={listContest[0].id}
        justifyContent="center"
        spacing={3}
      >
        {listContest.slice(0, 3).map((contestEntry, index) => {
          return (
            <Grid
              key={contestEntry.id}
              item
              xs={4}
              onClick={() => handleSubmissionCardModalOpen(contestEntry)}
            >
              <SubmissionCard cardData={contestEntry} />
            </Grid>
          );
        })}
      </Grid>,
    ].concat(contestDisplay(listContest.slice(3, listContest.length)));
  };

  const contestDisplayMonth = (listContest) => {
    if (listContest.length === 0) {
      return null;
    }
    if (listContest.length <= 3) {
      return [
        <Grid
          container
          key={listContest[0].id}
          justifyContent="center"
          spacing={3}
        >
          {listContest.map((contestEntry, index) => {
            return (
              <Grid
                key={contestEntry.id}
                item
                xs={4}
                onClick={() => handleSubmissionCardModalOpen(contestEntry)}
              >
                <SubmissionCard cardData={contestEntry} />
              </Grid>
            );
          })}
        </Grid>,
      ];
    }
    return [
      <Grid
        container
        key={listContest[0].id}
        justifyContent="center"
        spacing={3}
      >
        {listContest.slice(0, 3).map((contestEntry, index) => {
          return (
            <Grid
              key={contestEntry.id}
              item
              xs={4}
              onClick={() => handleSubmissionCardModalOpen(contestEntry)}
            >
              <SubmissionCard cardData={contestEntry} />
            </Grid>
          );
        })}
      </Grid>,
    ].concat(contestDisplayMonth(listContest.slice(3, listContest.length)));
  };

  const contestEntriesImagesDisplay = (listImage) => {
    if (!Array.isArray(listImage) || !(listImage.length > 0)) {
      return null;
    } else {
      return (
        listImage.length > 0 &&
        listImage.map((img, index) => {
          return (
            <Grid
              key={index}
              item
              xs={12}
              display="flex"
              justifyContent="center"
              className="modal-image-wrapper"
            >
              <TransformWrapper
                initialScale={1}
                minScale={1}
                maxScale={isMobile ? 6 : 5}
              >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <React.Fragment>
                    <div className="tools-wrapper">
                      <IconButton onClick={() => zoomIn()}>
                        <AddCircleIcon />
                      </IconButton>
                      <IconButton onClick={() => zoomOut()}>
                        <RemoveCircleIcon />
                      </IconButton>
                      <IconButton onClick={() => resetTransform()}>
                        <RestartAltIcon />
                      </IconButton>
                    </div>
                    <TransformComponent wrapperStyle={{ overflow: "visible" }}>
                      <div
                        className="contest-entries-image-display-wrapper"
                        style={{
                          height: isTablet
                            ? isMobile
                              ? "300px"
                              : "600px"
                            : "1000px",
                          width: isTablet
                            ? isMobile
                              ? "300px"
                              : "600px"
                            : "1000px",
                        }}
                      >
                        <img
                          id="contest-entries-display-background-frame"
                          alt="contest entries background frame"
                          src="/images/contest-entries-background-frame.png"
                        />
                        <img
                          style={{
                            transform: `rotate(${img.image.rotateDegree}deg)`,
                            msTransform: `rotate(${img.image.rotateDegree}deg)`,
                            WebkitTransform: `rotate(${img.image.rotateDegree}deg)`,
                            msTransform: `rotate(${img.image.rotateDegree}deg)`,
                          }}
                          className="admin-submission-card-modal-img"
                          alt="submission test"
                          src={
                            process.env.REACT_APP_SERVER_ROOT_MEDIA_URL +
                            img.image.path
                          }
                        />
                      </div>
                    </TransformComponent>
                  </React.Fragment>
                )}
              </TransformWrapper>
            </Grid>
          );
        })
      );
    }
  };

  return (
    <Grid
      container
      spacing={0}
      justifyContent="center"
      className="top-sumission-wrapper"
    >
      <Grid
        item
        xs={12}
        className="week-top-sumission-wrapper"
        style={{
          backgroundImage:
            'url("images/homepage-week-top-submission-background-1.png")',
        }}
      >
        <Grid
          container
          spacing={0}
          justifyContent="center"
          className="week-top-sumission-inside-wrapper"
          style={{
            backgroundImage:
              'url("images/homepage-week-top-submission-background-2.png")',
          }}
        >
          <img
            data-aos="zoom-in"
            data-aos-duration="1200"
            className="homepage-week-top-submission-book"
            alt="week top submission book"
            src="images/homepage-week-top-submission-book.png"
          />
          {/* <img
            data-aos="zoom-in"
            data-aos-duration="1200"
            className="homepage-week-top-submission-pencase"
            alt="week top submission pencase"
            src="images/homepage-week-top-submission-pencase.png"
            style={isMobile ? { zIndex: "9" } : null}
          /> */}
          <img
            data-aos="zoom-in"
            data-aos-duration="1200"
            className="homepage-week-top-submission-mail"
            alt="week top submission mail"
            src="images/homepage-week-top-submission-mail.png"
          />
          <img
            data-aos="zoom-in"
            data-aos-duration="1200"
            className="homepage-week-top-submission-paper"
            alt="week top submission paper"
            src="images/homepage-week-top-submission-paper.png"
          />
          <Grid item xs={10}>
            <Grid container justifyContent="center">
              <Grid
                data-aos="fade-down"
                data-aos-duration="1500"
                item
                md={6}
                xs={12}
                className="week-top-sumission-title-wrapper"
                style={{
                  backgroundImage:
                    'url("images/SubmissionSearchPage/search-box-title-background.png")',
                }}
              >
                <Typography className="week-top-sumission-title">
                  Bài đẹp trong tuần
                </Typography>
              </Grid>
              <Grid item xs={12} className="top-submission-card-wrapper">
                {!isMobile && contestEntries.length > 3 && (
                  <img
                    data-aos="fade-down"
                    data-aos-duration="1500"
                    onClick={onWeekNextArrowClick}
                    className="top-submission-content-next-btn"
                    alt="top submissions next btn"
                    src="images/homepage-slider-icon-next.png"
                  />
                )}
                {isMobile && contestEntries.length > 1 && (
                  <img
                    data-aos="fade-down"
                    data-aos-duration="1500"
                    onClick={onWeekNextArrowClick}
                    className="top-submission-content-next-btn"
                    alt="top submissions next btn"
                    src="images/homepage-slider-icon-next.png"
                  />
                )}
                {isMobile
                  ? null
                  : contestEntries.length > 3 && (
                      <img
                        data-aos="fade-down"
                        data-aos-duration="1500"
                        onClick={onWeekPrevArrowClick}
                        className="top-submission-content-back-btn"
                        alt="top submissions back btn"
                        src="images/homepage-slider-icon-back.png"
                      />
                    )}
                {contestEntries.length > 0 ? (
                  <div data-aos="fade-up" data-aos-duration="1500">
                    <Carousel
                      infiniteLoop={true}
                      showStatus={false}
                      showIndicators={false}
                      showThumbs={false}
                      selectedItem={contestEntries.length > 3 ? -1 : 0}
                    >
                      {isMobile
                        ? contestEntries.map((contestEntry, index) => {
                            return (
                              <Grid
                                container
                                key={contestEntry.id}
                                justifyContent="center"
                                spacing={0}
                              >
                                <Grid
                                  item
                                  xs={11}
                                  onClick={() =>
                                    handleSubmissionCardModalOpen(contestEntry)
                                  }
                                >
                                  <SubmissionCard cardData={contestEntry} />
                                </Grid>
                              </Grid>
                            );
                          })
                        : contestDisplay(contestEntries)}
                    </Carousel>
                  </div>
                ) : (
                  <Typography
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    className="contest-entries-blank-text"
                  >
                    Dữ liệu đang được cập nhật !
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          spacing={0}
          className="submissions-counter"
          justifyContent="center"
          alignItems="flex-start"
        >
          <img
            className="homepage-submission-counter-street"
            alt="top submission street"
            src="images/homepage-submission-counter-street.png"
          />
          <img
            data-aos="slide-right"
            data-aos-duration="2000"
            className="homepage-submission-counter-tam-tuot"
            alt="top submission tam tuot"
            src="images/homepage-submission-counter-tam-tuot.png"
          />
          <img
            className="homepage-submission-counter-bus"
            alt="top submission bus"
            src="images/homepage-submission-counter-bus.png"
          />
          <Grid
            data-aos="fade-down"
            data-aos-duration="1500"
            item
            md={6}
            xs={10}
            display="flex"
            justifyContent="center"
            className="submissions-counting-title-wrapper"
            style={{
              backgroundImage:
                'url("images/SubmissionSearchPage/search-box-title-background.png")',
            }}
          >
            <img
              className="homepage-submission-counter-bird"
              alt="top submission bird"
              src="images/homepage-submission-counter-bird.png"
            />
            <Typography className="submissions-counting-title">
              Thống kê số lượt bài tham gia
            </Typography>
          </Grid>
          <Grid item md={12} xs={10} display="flex" justifyContent="center">
            <Paper
              data-aos="fade-down"
              data-aos-duration="1500"
              className="submissions-count-wrapper"
            >
              <Typography className="submissions-count-value">
                {totalContestEntry.toString().padStart(6, "0")}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className="top-submissions-in-month-wrapper"
        style={
          !isMobile
            ? {
                backgroundImage:
                  'url("images/homepage-top-month-submission-backgroun.png")',
              }
            : null
        }
      >
        <img
          data-aos="zoom-in"
          data-aos-duration="1200"
          className="homepage-top-month-submission-cube"
          alt="top month submission cube"
          src="images/homepage-top-month-submission-cube.png"
        />
        <Grid
          container
          justifyContent="center"
          className="top-submissions-in-month-inside-wrapper"
        >
          <Grid item xs={10}>
            <Grid container justifyContent="center" spacing={0}>
              <Grid
                data-aos="fade-down"
                data-aos-duration="1500"
                item
                md={6}
                xs={12}
                className="month-top-sumission-title-wrapper"
                style={{
                  backgroundImage:
                    'url("images/SubmissionSearchPage/search-box-title-background.png")',
                }}
              >
                <Typography className="month-top-sumission-title">
                  Gương mặt của tháng
                </Typography>
              </Grid>
              <Grid item xs={12} className="top-submission-card-wrapper">
                {!isMobile && contestEntriesMonth.length > 3 && (
                  <img
                    data-aos="fade-down"
                    data-aos-duration="1500"
                    onClick={onMonthNextArrowClick}
                    className="top-submission-content-next-btn"
                    alt="top submissions next btn"
                    src="images/homepage-slider-icon-next.png"
                  />
                )}
                {isMobile && contestEntriesMonth.length > 1 && (
                  <img
                    data-aos="fade-down"
                    data-aos-duration="1500"
                    onClick={onMonthNextArrowClick}
                    className="top-submission-content-next-btn"
                    alt="top submissions next btn"
                    src="images/homepage-slider-icon-next.png"
                  />
                )}
                {!isMobile
                  ? contestEntriesMonth.length > 3 && (
                      <img
                        data-aos="fade-down"
                        data-aos-duration="1500"
                        onClick={onMonthPrevArrowClick}
                        className="top-submission-content-back-btn"
                        alt="top submissions back btn"
                        src="images/homepage-slider-icon-back.png"
                      />
                    )
                  : null}
                {contestEntriesMonth.length > 0 ? (
                  <div data-aos="fade-up" data-aos-duration="1500">
                    <Carousel
                      infiniteLoop={true}
                      showStatus={false}
                      showIndicators={false}
                      showThumbs={false}
                      selectedItem={contestEntriesMonth.length > 3 ? -1 : 0}
                    >
                      {isMobile
                        ? contestEntriesMonth.map((contestEntry, index) => {
                            return (
                              <Grid
                                container
                                key={contestEntry.id}
                                justifyContent="center"
                                spacing={0}
                              >
                                <Grid
                                  item
                                  xs={11}
                                  onClick={() =>
                                    handleSubmissionCardModalOpen(contestEntry)
                                  }
                                >
                                  <SubmissionCard cardData={contestEntry} />
                                </Grid>
                              </Grid>
                            );
                          })
                        : contestDisplayMonth(contestEntriesMonth)}
                    </Carousel>
                  </div>
                ) : (
                  <Typography
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    className="contest-entries-blank-text"
                  >
                    Dữ liệu đang được cập nhật !
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        fullScreen={fullScreen}
        open={submissionCardModalOpen}
        onClose={handleSubmissionCardModalClose}
        className={classes.modal}
      >
        <DialogContent className={classes.modal}>
          <Grid
            container
            rowSpacing={2}
            className="submission-card-modal-box-inside-wrapper"
          >
            <Grid
              item
              xs={12}
              className="top-week-submission-card-modal-title-wrapper"
            >
              <Grid
                container
                justifyContent="center"
                spacing={isMobile ? 1 : 2}
                style={{ position: "relative" }}
              >
                <Grid item xs={12}>
                  <Typography
                    variant="quicksand18shadow"
                    className="submission-card-modal-title"
                    sx={{ fontWeight: "700" }}
                  >
                    Ảnh mô tả bài thi:
                  </Typography>
                </Grid>
                {selectedData.imgPath.length > 1 && (
                  <img
                    onClick={onModalNextArrowClick}
                    className="submission-modal-next-btn"
                    alt="submissions modal next btn"
                    src="images/homepage-slider-icon-next.png"
                  />
                )}
                {selectedData.imgPath.length > 1 && (
                  <img
                    onClick={onModalPrevArrowClick}
                    className="submission-modal-prev-btn"
                    alt="submissions modal back btn"
                    src="images/homepage-slider-icon-back.png"
                  />
                )}
                <Carousel
                  infiniteLoop={true}
                  showStatus={false}
                  showIndicators={false}
                  showThumbs={false}
                  swipeable={false}
                  width={isTablet ? (isMobile ? 300 : 600) : 1000}
                >
                  {contestEntriesImagesDisplay(selectedData.imgPath)}
                </Carousel>
              </Grid>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Họ tên thí sinh: {selectedData.candidateName}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Tên chủ đề: {selectedData.subject}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Nội dung tác phẩm: {selectedData.content}
              </Typography>
            </Grid>
            {/* <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Ý nghĩa: {selectedData.sense}
              </Typography>
            </Grid> */}
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Trường: {selectedData.candidateSchool}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Quận/ Huyện: {selectedData.district}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Tỉnh/ Thành phố: {selectedData.city}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            size="large"
            className="submission-card-modal-close-button"
            onClick={handleSubmissionCardModalClose}
            autoFocus
          >
            Đóng
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
