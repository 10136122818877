import { Grid, Typography } from "@mui/material";
import "./HotSubmissionCard.css"

export default function HotSubmissionCard(props) {
    const data = props.cardData
    const titleDisplay = (data) => {
        var title = []
        data && title.push(data.candidateName)
        data && title.push(data.candidateSchool)
        data && data.user && data.user.district && title.push(data.user.district.name)
        data && data.user && data.user.city && title.push(data.user.city.name)
        return title.join(' - ')
    }
    return (
        <Grid container justifyContent="center" rowSpacing={4}>
            <Grid item xs={12} display="flex" justifyContent="center" style={{ cursor: 'pointer' }}>
                <img className="hot-submissions-card-img" id="hot-submissions-card-img" alt="hot submissions card img" src={data ? (process.env.REACT_APP_SERVER_ROOT_MEDIA_URL + data.contestEntriesImages[0].image.path) : ''} />
            </Grid>
            <Grid item xs={12}>
                <Typography className="hot-submission-card-title">
                    {titleDisplay(data)}
                </Typography>
            </Grid>
        </Grid>
    )
}