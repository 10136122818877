import { Grid, Typography } from "@mui/material";
import "./SearchResultSubmission.css";
import useMediaQuery from '@mui/material/useMediaQuery';

const convertDateTime = (inputDate) => {
    var year = inputDate.toString().slice(0, 4)
    var month = inputDate.toString().slice(5, 7)
    var day = inputDate.toString().slice(8, 10)

    return day.concat('/').concat(month).concat('/').concat(year)
}

export default function SearchResultSubmission(props) {
    const isMobile = useMediaQuery('(max-width:768px)');
    const searchResultData = props.searchResultData
    return (
        <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={isMobile ? 11 : 10}>
                <Grid container justifyContent="center" spacing={isMobile ? 4 : 0}>
                    <Grid item md={7} xs={12} className="submission-search-result-image-wrapper">
                        <img className="submission-search-result-image" alt="submission search result" src={searchResultData ? (process.env.REACT_APP_SERVER_ROOT_MEDIA_URL + searchResultData.contestEntriesImages[0].image.path) : ''} />
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <Grid container justifyContent="center" className="submission-status-wrapper">
                            <Grid item xs={12} className="submission-success-status" style={{ backgroundColor: 'rgba(66, 189, 178, 0.4)' }}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={3} display="flex" alignItems="center" justifyContent="center">
                                        <img className="submission-success-status-icon" alt="submission success status icon" src="images/SubmissionSearchPage/submission-success-status-icon.png" />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography className="submission-status-title">
                                            Đã nộp
                                        </Typography>
                                        <Typography className="submission-status-date-text">
                                            {convertDateTime(searchResultData.createdAt)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className="submission-received-status"
                            // style={{ backgroundColor: 'rgba(66, 189, 178, 0.4)' }}
                            >
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={3} display="flex" alignItems="center" justifyContent="center">
                                        <img className="submission-received-status-icon" alt="submission received status icon" src="images/SubmissionSearchPage/submission-received-status-icon.png" />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography className="submission-status-title">
                                            Đã nhận
                                        </Typography>
                                        <Typography className="submission-status-date-text">
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className="submission-grading-status"
                            // style={{ backgroundColor: 'rgba(66, 189, 178, 0.4)' }}
                            >
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={3} display="flex" alignItems="center" justifyContent="center">
                                        <img className="submission-grading-status-icon" alt="submission grading status icon" src="images/SubmissionSearchPage/submission-grading-status-icon.png" />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography className="submission-status-title">
                                            Đang chấm
                                        </Typography>
                                        <Typography className="submission-status-date-text">
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className="submission-result-status"
                            // style={{ backgroundColor: 'rgba(66, 189, 178, 0.4)' }}
                            >
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={3} display="flex" alignItems="center" justifyContent="center">
                                        <img className="submission-result-status-icon" alt="submission result status icon" src="images/SubmissionSearchPage/submission-result-status-icon.png" />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography className="submission-status-title">
                                            Kết quả
                                        </Typography>
                                        <Typography className="submission-status-date-text">
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={10}>
                <Typography className="search-result-user-name">{searchResultData.candidateName}</Typography>
                <Typography className="search-result-user-info" style={{ marginTop: '10px' }}>Chủ đề: {searchResultData.subject}</Typography>
                <Typography className="search-result-user-info">Nội dung: {searchResultData.content}</Typography>
                <ul>
                    <li className="search-result-user-info-li-wrapper"><Typography className="search-result-user-info">Trường: {searchResultData.candidateSchool}</Typography></li>
                    <li className="search-result-user-info-li-wrapper"><Typography className="search-result-user-info">Địa chỉ: {searchResultData.user ? searchResultData.user.address : ''}</Typography></li>
                </ul>
            </Grid>
        </Grid>
    )
}