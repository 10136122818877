import React from 'react';
import HomePage from './pages/HomePage'
import ExamPage from './pages/ExamPage'
import SubmissionSearchPage from './pages/SubmissionSearchPage';
import SubmitExamPage from './pages/SubmitExamPage'
import InformationPage from './pages/InformationPage'
import ResultsPage from './pages/ResultsPage'
import LoginPage from './pages/admin/LoginPage'
import DashboardPage from './pages/admin/DashboardPage'

const routes = [
    // user 
    {
        path: '/',
        exact: true,
        main: < HomePage />
    },
    {
        path: '/information',
        exact: true,
        main: < InformationPage />
    },
    {
        path: '/exam-topic-poem-writing',
        exact: true,
        main: < ExamPage type={'poem-writing'}
        />
    },
    {
        path: '/exam-topic-feeling-writing',
        exact: true,
        main: < ExamPage type={'feeling-writing'}
        />
    },
    {
        path: '/submission-search',
        exact: true,
        main: < SubmissionSearchPage />
    },
    {
        path: '/submit-exam',
        exact: true,
        main: < SubmitExamPage />
    },
    {
        path: '/results',
        exact: true,
        main: < ResultsPage />
    },

    // admin
    {
        path: '/admin',
        exact: true,
        main: < LoginPage />
    },
    {
        path: '/admin/login',
        exact: true,
        main: < LoginPage />
    },
    {
        path: '/admin/dashboard/judges-management',
        exact: true,
        main: < DashboardPage itemNumber={1} />
    },
    {
        path: '/admin/dashboard/users-management',
        exact: true,
        main: < DashboardPage itemNumber={2} />
    },
    {
        path: '/admin/dashboard/contest-entries-ungraded-management',
        exact: true,
        main: < DashboardPage itemNumber={3.1} />
    },
    {
        path: '/admin/dashboard/contest-entries-round-1-management',
        exact: true,
        main: < DashboardPage itemNumber={3.2} />
    },
    {
        path: '/admin/dashboard/contest-entries-round-week-management',
        exact: true,
        main: < DashboardPage itemNumber={3.3} />
    },
    {
        path: '/admin/dashboard/contest-entries-round-final-management',
        exact: true,
        main: < DashboardPage itemNumber={3.4} />
    },
    {
        path: '/admin/dashboard/contest-entries-fail-management',
        exact: true,
        main: < DashboardPage itemNumber={3.5} />
    },
    {
        path: '/admin/dashboard/contest-entries-all-management',
        exact: true,
        main: < DashboardPage itemNumber={3.6} />
    },
    {
        path: '/admin/dashboard/results-round-week-management',
        exact: true,
        main: < DashboardPage itemNumber={4.1} />
    },
    {
        path: '/admin/dashboard/results-round-final-management',
        exact: true,
        main: < DashboardPage itemNumber={4.2} />
    }
]

export default routes;