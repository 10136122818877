import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import "./Footer.css"

export default function Footer() {
    return (
        <div style={{ position: 'relative' }}>
            <Paper className="footer-container" square elevation={0}>
                <Grid container justifyContent="center">
                    <Grid className="footer-content-wrapper" style={{ marginLeft: '5%', marginRight: '5%' }} container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={4}>
                            <Grid container spacing={0} justifyContent="flex-start">
                                <Grid item xs={12} style={{ marginBottom: '20px' }}>
                                    <Avatar variant="square" alt="Logo doan thanh nien" src="images/HDDTW.jpg" sx={{ width: 160, height: 110 }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className="content-title" variant="body1">
                                        Hội đồng đội trung ương
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className="content-text" variant="body2">
                                        <strong>Địa chỉ:</strong> Số 62 Bà Triệu, Hoàn Kiếm, Hà Nội
                                    </Typography>
                                    <Typography className="content-text" variant="body2">
                                        <strong>Điện thoại:</strong> 04 224 12031 - 04 224 12032
                                    </Typography>
                                    <Typography className="content-text" variant="body2">
                                        <strong>Website:</strong> www.thieunhivietnam.vn
                                    </Typography>
                                    <Typography className="content-text" variant="body2">
                                        <strong>Email:</strong> cungemlamviectot@gmail.com
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Grid container spacing={0} justifyContent="flex-start">
                                <Grid item xs={12} style={{ marginBottom: '20px' }}>
                                    <Avatar variant="square" alt="Logo idp" src="images/idp_logo.png" sx={{ width: 80, height: 82 }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className="content-title" variant="body1">
                                        CÔNG TY CỔ PHẦN SỮA QUỐC TẾ (IDP)
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className="content-text" variant="body2">
                                        Giấy CN ĐKDN số: 0500463609
                                    </Typography>
                                    <Typography className="content-text" variant="body2">
                                        Nơi cấp: Sở KH và ĐT Thành phố Hà Nội
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className="content-text" variant="body2">
                                        <strong>Địa chỉ HCM:</strong> 217 Nguyễn Văn Thủ, Phường Đakao, Quận 1
                                    </Typography>
                                    <Typography className="content-text" variant="body2">
                                        <strong>Điện thoại:</strong> 1900 633 571
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3} display="flex" alignItems="center">
                            <Grid container spacing={0} justifyContent="flex-start">
                                <Grid item xs={12}>
                                    <Typography className="content-text" variant="body2" style={{ marginBottom: '20px' }}>
                                        <strong>Liên hệ</strong>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} justifyContent="flex-start">
                                        <Grid item>
                                            <Avatar className="link-icon" variant="square" alt="icon facebook" src="images/facebook-icon.png" sx={{ width: 35, height: 35 }} />
                                        </Grid>
                                        <Grid item>
                                            <Avatar className="link-icon" variant="square" alt="icon youtube" src="images/youtube-icon.png" sx={{ width: 35, height: 35 }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className="content-link" variant="body2">
                                        cungkunlamviectot@idp.vn
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}