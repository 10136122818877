import HomePageSlider from "../components/HomePage/HomePageSlider";
import HomePageAnnouncement from "../components/HomePage/HomePageAnnouncement";
import HomePageTopSubmissions from "../components/HomePage/HomePageTopSubmissions";
import HomePageProgramSchedule from "../components/HomePage/HomePageProgramSchedule";
import HomePageHotSubmissions from "../components/HomePage/HomePageHotSubmissions";
import HomePageNews from "../components/HomePage/HomePageNews";
import Footer from "../components/Footer";
import React, { useEffect } from "react";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isMobile = useMediaQuery("(max-width:768px)");

  const newsRef = React.useRef(null);

  const executeScroll = () => {
    newsRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <div className="homepage-wrapper">
      {isMobile ? (
        <React.Fragment>
          <HomePageSlider />
          <HomePageAnnouncement newsScroll={executeScroll} />
        </React.Fragment>
      ) : (
        <div
          className="slider-and-announcement-wrapper"
          style={{
            backgroundImage: `url("images/homepage-slider-and-announcement-background-news.png")`,
          }}
        >
          <img
            className="homepage-banner-background-icon"
            alt="homepage banner background icon"
            src="/images/homepage-banner-background-icon.png"
          />
          <HomePageSlider />
          <HomePageAnnouncement newsScroll={executeScroll} />
        </div>
      )}
      <HomePageTopSubmissions />
      <HomePageProgramSchedule />
      <HomePageHotSubmissions />
      {isMobile ? (
        <Grid
          container
          justifyContent="center"
          style={{ marginTop: "1200px" }}
          sx={{ position: "relative", paddingBottom: 10 }}
        >
          {/* <HomePageNews refProp={newsRef} /> */}
          <CardMedia
            component="img"
            image="/images/footer-bg-mobile.png"
            sx={{ position: "absolute", bottom: 0 }}
          ></CardMedia>
          <Footer />
        </Grid>
      ) : (
        <div>
          {/* <HomePageNews refProp={newsRef} /> */}
          <CardMedia
            component="img"
            image="/images/footer-bg.png"
            sx={{ position: "absolute", bottom: 0 }}
          ></CardMedia>
          <Grid
            container
            justifyContent="center"
            sx={{ position: "absolute", bottom: "100px" }}
          >
            <Footer />
          </Grid>
        </div>
      )}
    </div>
  );
}
