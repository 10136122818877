import React from 'react'
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from "@material-ui/core/styles";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import LockIcon from '@mui/icons-material/Lock';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import userStore from '../../stores/user'

const useStyles = makeStyles({
    root: {
        "& .MuiOutlinedInput-input": {
            color: "rgba(0, 0, 0, 0.87)",
            fontFamily: "Quicksand",
            fontSize: '18px'
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: 'rgba(0, 0, 0, 0.87) !important',
        },
    },
})

export default function LoginPage() {
    const { login } = userStore((state) => state)
    const classes = useStyles();
    const navigate = useNavigate();
    const [loginInfo, setLoginInfo] = React.useState({
        username: '',
        password: ''
    })

    const [loginError, setLoginError] = React.useState({
        username: '',
        password: ''
    })

    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };
    const handleBackdropOpen = () => {
        setBackdropOpen(true);
    };

    const handleUsernameChange = (e) => {
        setLoginInfo(prevState => ({
            ...prevState,
            'username': e.target.value
        }))
        if (e.target.value !== '') {
            setLoginError(prevState => ({
                ...prevState,
                'username': ''
            }))
        }
    }

    const handlePasswordChange = (e) => {
        setLoginInfo(prevState => ({
            ...prevState,
            'password': e.target.value
        }))
        if (e.target.value !== '') {
            setLoginError(prevState => ({
                ...prevState,
                'password': ''
            }))
        }
    }

    const handleLogin = () => {
        handleBackdropOpen()
        if (loginInfo.username === '') {
            setLoginError(prevState => ({
                ...prevState,
                'username': 'Bạn chưa điền tên đăng nhập !'
            }))
            setBackdropOpen(false)
            return null
        }
        if (loginInfo.password === '') {
            setLoginError(prevState => ({
                ...prevState,
                'password': 'Bạn chưa điền mật khẩu !'
            }))
            setBackdropOpen(false)
            return null
        }

        axios.post(process.env.REACT_APP_SERVER_API_ROOT_URL + `/users/sign-in`, loginInfo)
            .then(respone => {
                login(respone.data)
                setBackdropOpen(false)
                if (respone.data.userInfo.role.name === 'Admin') {
                    navigate("/admin/dashboard/judges-management");
                } else if (respone.data.userInfo.role.name === 'Giám khảo') {
                    navigate("/admin/dashboard/contest-entries-all-management");
                }
            })
            .catch(error => {
                console.log(error)
                setLoginError(prevState => ({
                    ...prevState,
                    'username': 'Sai thông tin đăng nhập !'
                }))
                setBackdropOpen(false)
                return
            });
    }

    const handleInputEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleLogin()
        }
    }

    return (
        <div style={{
            backgroundColor: '#ffffff',
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
                onClick={handleBackdropClose}
            >
                <CircularProgress color="primary" />
            </Backdrop>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={11} md={4} lg={4} xl={3} display="flex" justifyContent="center" alignItems="center">
                    <Paper elevation={4} style={{ width: '100%', padding: '20px' }}>
                        <Grid container justifyContent="center" alignItems="center" spacing={4}>
                            <Grid item xs={12}>
                                <Typography variant="h4" align="center" color="primary" style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
                                    Đăng nhập
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {loginError.username !== '' && (
                                    <Alert style={{ fontSize: '16px', marginBottom: '5px' }} severity="error">{loginError.username}</Alert>
                                )}
                                <Typography variant="quicksand18" align="left">
                                    Tên đăng nhập :
                                </Typography>
                                <FormControl fullWidth size="small" className={classes.root} variant="outlined">
                                    <OutlinedInput
                                        value={loginInfo.username}
                                        onChange={handleUsernameChange}
                                        onKeyDown={handleInputEnterKeyPress}
                                        startAdornment={<InputAdornment position="start"><AccountCircleIcon /></InputAdornment>}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                {loginError.password !== '' && (
                                    <Alert severity="error" style={{ fontSize: '16px', marginBottom: '5px' }}>{loginError.password}</Alert>
                                )}
                                <Typography variant="quicksand18" align="left">
                                    Mật khẩu :
                                </Typography>
                                <FormControl fullWidth size="small" className={classes.root} variant="outlined">
                                    <OutlinedInput
                                        value={loginInfo.password}
                                        type='password'
                                        onChange={handlePasswordChange}
                                        onKeyDown={handleInputEnterKeyPress}
                                        startAdornment={<InputAdornment position="start"><LockIcon /></InputAdornment>}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <Button onClick={handleLogin} size="medium" variant="contained" color="primary">Đăng nhập</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}