import { Grid, Paper, Typography, Modal, Box, CardMedia } from "@mui/material";
import "./HomePageHotSubmissions.css";
import HotSubmissionCard from "../../HotSubmissionCard";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import React from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@material-ui/styles";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles({
  modal: {
    borderRadius: "20px !important",
  },
});

export default function HomePageHotSubmissions() {
  const classes = useStyles();
  const [contestEntries, setContestEntries] = React.useState([]);
  const [submissionCardModalOpen, updateSubmissionCardModalOpen] =
    React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:420px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedData, setSelectedData] = React.useState({
    imgPath: [],
    candidateName: "",
    subject: "",
    content: "",
    sense: "",
    candidateSchool: "",
    district: "",
    city: "",
  });

  const handleSubmissionCardModalOpen = (selectedContestEntry) => {
    updateSubmissionCardModalOpen(true);
    setSelectedData((prevState) => ({
      ...prevState,
      imgPath: selectedContestEntry.contestEntriesImages,
      candidateName: selectedContestEntry
        ? selectedContestEntry.candidateName
        : "",
      subject: selectedContestEntry ? selectedContestEntry.subject : "",
      content: selectedContestEntry ? selectedContestEntry.content : "",
      sense: selectedContestEntry ? selectedContestEntry.sense : "",
      district: selectedContestEntry
        ? selectedContestEntry.user.district.name
        : "",
      city: selectedContestEntry ? selectedContestEntry.user.city.name : "",
      candidateSchool: selectedContestEntry
        ? selectedContestEntry.candidateSchool
        : "",
    }));
    axios
      .put(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/contest-entry/count-view-number/${selectedContestEntry.id}`
      )
      .then((res) => {})
      .catch((error) => console.log(error));
  };

  const handleSubmissionCardModalClose = () => {
    updateSubmissionCardModalOpen(false);
  };

  React.useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/contest-entry/get/outstanding`
      )
      .then((res) => {
        if (res.data) {
          setContestEntries(res.data.rows);
        }
      });
  }, []);

  const onNextArrowClick = () => {
    let parent = document.querySelector(".hot-submission-wrapper");
    let arrow = parent.querySelector(".control-next");
    arrow.click();
  };

  const onPrevArrowClick = () => {
    let parent = document.querySelector(".hot-submission-wrapper");
    let arrow = parent.querySelector(".control-prev");
    arrow.click();
  };

  const onModalNextArrowClick = () => {
    let parent = document.querySelector(
      ".hot-submission-card-modal-title-wrapper"
    );
    let arrow = parent.querySelector(".control-next");
    arrow.click();
  };

  const onModalPrevArrowClick = () => {
    let parent = document.querySelector(
      ".hot-submission-card-modal-title-wrapper"
    );
    let arrow = parent.querySelector(".control-prev");
    arrow.click();
  };

  const contestDisplay = (listContest) => {
    if (listContest.length === 0) {
      return null;
    }
    if (listContest.length <= 4) {
      return [
        <Grid
          container
          key={listContest[0].id}
          justifyContent="center"
          spacing={1}
        >
          {listContest.map((contestEntry) => {
            return (
              <Grid
                key={contestEntry.id}
                item
                xs={3}
                onClick={() => handleSubmissionCardModalOpen(contestEntry)}
              >
                <HotSubmissionCard cardData={contestEntry} />
              </Grid>
            );
          })}
        </Grid>,
      ];
    }
    return [
      <Grid
        container
        key={listContest[0].id}
        justifyContent="center"
        spacing={1}
      >
        {listContest.slice(0, 4).map((contestEntry) => {
          return (
            <Grid
              key={contestEntry.id}
              item
              xs={3}
              onClick={() => handleSubmissionCardModalOpen(contestEntry)}
            >
              <HotSubmissionCard cardData={contestEntry} />
            </Grid>
          );
        })}
      </Grid>,
    ].concat(contestDisplay(listContest.slice(4, listContest.length)));
  };
  const contestDisplayMobile = (listContest) => {
    if (listContest.length === 0) {
      return null;
    }
    if (listContest.length <= 1) {
      return [
        <Grid container key={listContest[0].id} justifyContent="center">
          {listContest.map((contestEntry) => {
            return (
              <Grid
                key={contestEntry.id}
                item
                xs={11}
                onClick={() => handleSubmissionCardModalOpen(contestEntry)}
              >
                <HotSubmissionCard cardData={contestEntry} />
              </Grid>
            );
          })}
        </Grid>,
      ];
    }
    return [
      <Grid container key={listContest[0].id} justifyContent="center">
        {listContest.slice(0, 1).map((contestEntry) => {
          return (
            <Grid
              key={contestEntry.id}
              item
              xs={11}
              onClick={() => handleSubmissionCardModalOpen(contestEntry)}
            >
              <HotSubmissionCard cardData={contestEntry} />
            </Grid>
          );
        })}
      </Grid>,
    ].concat(contestDisplayMobile(listContest.slice(1, listContest.length)));
  };

  const contestEntriesImagesDisplay = (listImage) => {
    if (!Array.isArray(listImage) || !(listImage.length > 0)) {
      return null;
    } else {
      return (
        listImage.length > 0 &&
        listImage.map((img, index) => {
          return (
            <Grid
              key={index}
              item
              xs={12}
              display="flex"
              justifyContent="center"
              className="modal-image-wrapper"
            >
              <TransformWrapper
                initialScale={1}
                minScale={1}
                maxScale={isMobile ? 6 : 5}
              >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <React.Fragment>
                    <div className="tools-wrapper">
                      <IconButton onClick={() => zoomIn()}>
                        <AddCircleIcon />
                      </IconButton>
                      <IconButton onClick={() => zoomOut()}>
                        <RemoveCircleIcon />
                      </IconButton>
                      <IconButton onClick={() => resetTransform()}>
                        <RestartAltIcon />
                      </IconButton>
                    </div>
                    <TransformComponent wrapperStyle={{ overflow: "visible" }}>
                      <div
                        className="contest-entries-image-display-wrapper"
                        style={{
                          height: isTablet
                            ? isMobile
                              ? "300px"
                              : "600px"
                            : "1000px",
                          width: isTablet
                            ? isMobile
                              ? "300px"
                              : "600px"
                            : "1000px",
                        }}
                      >
                        <img
                          id="contest-entries-display-background-frame"
                          alt="contest entries background frame"
                          src="/images/contest-entries-background-frame.png"
                        />
                        <img
                          style={{
                            transform: `rotate(${img.image.rotateDegree}deg)`,
                            msTransform: `rotate(${img.image.rotateDegree}deg)`,
                            WebkitTransform: `rotate(${img.image.rotateDegree}deg)`,
                            msTransform: `rotate(${img.image.rotateDegree}deg)`,
                          }}
                          className="admin-submission-card-modal-img"
                          alt="submission test"
                          src={
                            process.env.REACT_APP_SERVER_ROOT_MEDIA_URL +
                            img.image.path
                          }
                        />
                      </div>
                    </TransformComponent>
                  </React.Fragment>
                )}
              </TransformWrapper>
            </Grid>
          );
        })
      );
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      spacing={0}
      className="hot-submissions-wrapper"
      mt={10}
    >
      <Grid item xs={12} className="hot-submission-wrapper">
        <Grid container justifyContent="center" spacing={0}>
          <Grid
            item
            xs={11}
            md={6}
            sx={{ textAlign: "center", position: "relative", mt: 3 }}
          >
            <Box
              data-aos="fade-down"
              data-aos-duration="1200"
              className="search-title-box"
              sx={{ backgroundImage: "url('/images/SubmitExam/bg-title.png')" }}
            >
              <Typography variant="crocante36" color="yellow.main">
                Bài tham gia nổi bật
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={10} className="hot-submission-content-wrapper">
            {!isMobile && contestEntries.length > 4 && (
              <img
                data-aos="fade-down"
                data-aos-duration="1200"
                onClick={onNextArrowClick}
                className="hot-submission-content-next-btn"
                alt="hot submissions next btn"
                src="images/homepage-slider-icon-next.png"
              />
            )}
            {isMobile && contestEntries.length > 1 && (
              <img
                data-aos="fade-down"
                data-aos-duration="1200"
                onClick={onNextArrowClick}
                className="hot-submission-content-next-btn"
                alt="hot submissions next btn"
                src="images/homepage-slider-icon-next.png"
              />
            )}
            {!isMobile
              ? contestEntries.length > 4 && (
                  <img
                    data-aos="fade-down"
                    data-aos-duration="1200"
                    onClick={onPrevArrowClick}
                    className="hot-submission-content-back-btn"
                    alt="hot submissions back btn"
                    src="images/homepage-slider-icon-back.png"
                  />
                )
              : null}
            {contestEntries.length > 0 ? (
              <div data-aos="fade-up" data-aos-duration="1200">
                <Carousel
                  infiniteLoop={true}
                  showStatus={false}
                  showIndicators={false}
                  showThumbs={false}
                  selectedItem={contestEntries.length > 4 ? -1 : 0}
                >
                  {isMobile
                    ? contestDisplayMobile(contestEntries)
                    : contestDisplay(contestEntries)}
                </Carousel>
              </div>
            ) : (
              <Typography
                data-aos="fade-up"
                data-aos-duration="1500"
                className="contest-entries-blank-text"
              >
                Dữ liệu đang được cập nhật !
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container className="footer-info-wrapper">
        <Grid
          data-aos={isMobile ? "zoom-in-down" : "zoom-in-right"}
          data-aos-easing="ease-in-cubic"
          data-aos-duration="1200"
          item
          xs={12}
          md={5}
        >
          <CardMedia
            component="img"
            image="/images/homepage-hot-submission-cow.png"
            sx={{ position: "relative", zIndex: 1 }}
          ></CardMedia>
        </Grid>
        <Grid item xs={12} md={7} justifyContent="flex-end">
          <Grid
            data-aos={isMobile ? "zoom-in-up" : "zoom-in-left"}
            data-aos-easing="ease-in-cubic"
            data-aos-duration="800"
            item
            xs={12}
            md={11}
            className="footer-info-container"
            style={{
              backgroundImage:
                'url("images/homepage-hot-submission-text-background.png")',
            }}
          >
            <Grid container justifyContent="flex-start" spacing={0}>
              <Grid item xs={12}>
                <Typography className="footer-info-title">
                  Tham gia các việc tốt khác
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className="footer-info-text">
                  Các bạn hãy cùng tham gia làm nhiều việc tốt khác bằng cách
                  tải ứng dụng Làm việc tốt hoặc truy cập trang web{" "}
                  <a href="https://lamviectot.edu.vn">
                    https://lamviectot.edu.vn
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "20px" }}>
                <button
                  className="footer-info-button"
                  onClick={() => window.open("https://lamviectot.edu.vn")}
                >
                  Xem
                </button>
              </Grid>
            </Grid>
            <img
              className="homepage-hot-submissions-footer-pencil"
              alt="hot submissions footer pencil"
              src="images/homepage-hot-submissions-footer-pencil.png"
            />
            <img
              className="homepage-hot-submissions-footer-line"
              alt="hot submissions footer line"
              src="images/homepage-hot-submissions-footer-line.png"
            />
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        fullScreen={fullScreen}
        open={submissionCardModalOpen}
        onClose={handleSubmissionCardModalClose}
        className={classes.modal}
      >
        <DialogContent className={classes.modal}>
          <Grid
            container
            rowSpacing={2}
            className="submission-card-modal-box-inside-wrapper"
          >
            <Grid
              item
              xs={12}
              className="hot-submission-card-modal-title-wrapper"
            >
              <Grid
                container
                justifyContent="center"
                spacing={isMobile ? 1 : 2}
                style={{ position: "relative" }}
              >
                <Grid item xs={12}>
                  <Typography
                    variant="quicksand18shadow"
                    className="submission-card-modal-title"
                    sx={{ fontWeight: "700" }}
                  >
                    Ảnh mô tả bài thi:
                  </Typography>
                </Grid>
                {selectedData.imgPath.length > 1 && (
                  <img
                    onClick={onModalNextArrowClick}
                    className="submission-modal-next-btn"
                    alt="submissions modal next btn"
                    src="images/homepage-slider-icon-next.png"
                  />
                )}
                {selectedData.imgPath.length > 1 && (
                  <img
                    onClick={onModalPrevArrowClick}
                    className="submission-modal-prev-btn"
                    alt="submissions modal back btn"
                    src="images/homepage-slider-icon-back.png"
                  />
                )}
                <Carousel
                  infiniteLoop={true}
                  showStatus={false}
                  showIndicators={false}
                  showThumbs={false}
                  swipeable={false}
                  width={isTablet ? (isMobile ? 300 : 600) : 1000}
                >
                  {contestEntriesImagesDisplay(selectedData.imgPath)}
                </Carousel>
              </Grid>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Họ tên thí sinh: {selectedData.candidateName}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Tên chủ đề: {selectedData.subject}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Nội dung tác phẩm: {selectedData.content}
              </Typography>
            </Grid>
            {/* <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Ý nghĩa: {selectedData.sense}
              </Typography>
            </Grid> */}
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Trường: {selectedData.candidateSchool}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Quận/ Huyện: {selectedData.district}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Tỉnh/ Thành phố: {selectedData.city}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            size="large"
            className="submission-card-modal-close-button"
            onClick={handleSubmissionCardModalClose}
            autoFocus
          >
            Đóng
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
