import { Grid } from "@mui/material";
import React from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { DataGrid, viVN } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import Paper from "@mui/material/Paper";
import DateTimeConvert from "../../../utils/DateTimeConvert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Carousel } from "react-responsive-carousel";
import SuccessSnackbar from "../Common/SuccessSnackbar";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SettingsIcon from "@mui/icons-material/Settings";
import UpdateIcon from "@mui/icons-material/Update";

const gender_vi = {
  male: "Nam",
  female: "Nữ",
};

const result_vi = {
  ACTIVE: "Chưa được chấm điểm",
  PASS: "Đạt",
  FAIL: "Không đạt",
};

const useStyles = makeStyles({
  root: {
    marginTop: "10px",
    "& .MuiOutlinedInput-input": {
      color: "rgba(0, 0, 0, 0.87)",
      fontFamily: "Quicksand",
      fontSize: "16px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.5) !important",
    },
  },
});

export default function ResultsManagement(props) {
  const contestEntriesType = props.type;
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:420px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const [ContestEntries, setContestEntries] = React.useState([]);
  const [examRounds, setExamRounds] = React.useState([]);
  const [Cities, setCities] = React.useState([]);
  const [Districts, setDistricts] = React.useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const handleBackdropClose = () => {
    setBackdropOpen(false);
  };
  const handleBackdropOpen = () => {
    setBackdropOpen(true);
  };

  const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState({
    isOpen: false,
    content: "",
  });

  const [formError, setFormError] = React.useState({
    field: "",
    text: "",
  });

  const [searchForm, setSearchForm] = React.useState({
    createDate: "",
    examRound: "",
    city: "",
    district: "",
    examCode: "",
    candidateName: "",
    candidateDob: "",
    userPhone: "",
    candidateSchool: "",
  });

  const handleExamRoundChange = (e) => {
    setSearchForm((prevState) => ({
      ...prevState,
      examRound: e.target.value,
    }));
    setFormError({
      field: "",
      text: "",
    });
  };

  const handleCityChange = (e) => {
    setSearchForm((prevState) => ({
      ...prevState,
      city: e.target.value,
    }));

    setFormError({
      field: "",
      text: "",
    });

    setSearchForm((prevState) => ({
      ...prevState,
      district: "",
    }));

    getDistrictsByCity(e.target.value);
  };

  const handleDistrictChange = (e) => {
    setSearchForm((prevState) => ({
      ...prevState,
      district: e.target.value,
    }));

    setFormError({
      field: "",
      text: "",
    });
  };

  const handleExamCodeChange = (e) => {
    setSearchForm((prevState) => ({
      ...prevState,
      examCode: e.target.value,
    }));

    setFormError({
      field: "",
      text: "",
    });
  };

  const handleCandidateNameChange = (e) => {
    setSearchForm((prevState) => ({
      ...prevState,
      candidateName: e.target.value,
    }));

    setFormError({
      field: "",
      text: "",
    });
  };

  const handleCandidateDobChange = (e) => {
    setSearchForm((prevState) => ({
      ...prevState,
      candidateDob: e.target.value,
    }));

    setFormError({
      field: "",
      text: "",
    });
  };

  const handleUserPhoneChange = (e) => {
    setSearchForm((prevState) => ({
      ...prevState,
      userPhone: e.target.value,
    }));

    setFormError({
      field: "",
      text: "",
    });
  };

  const handleCandidateSchoolChange = (e) => {
    setSearchForm((prevState) => ({
      ...prevState,
      candidateSchool: e.target.value,
    }));

    setFormError({
      field: "",
      text: "",
    });
  };

  const resetSearchForm = () => {
    handleBackdropOpen();
    setSearchForm({
      createDate: "",
      examRound: "",
      city: "",
      district: "",
      examCode: "",
      candidateName: "",
      candidateDob: "",
      userPhone: "",
      candidateSchool: "",
    });

    setFormError({
      field: "",
      text: "",
    });

    axios
      .get(process.env.REACT_APP_SERVER_API_ROOT_URL + `/contest-entry`)
      .then((res) => {
        if (res.data) {
          setContestEntries(res.data.rows);
          handleBackdropClose();
        }
      });
  };

  const [isSearched, setIsSearched] = React.useState(false);

  const handleSubmitSearchForm = (page) => {
    setIsSearched(true);
    if (
      !searchForm.candidateDob &&
      !searchForm.candidateName &&
      !searchForm.candidateSchool &&
      !searchForm.city &&
      !searchForm.district &&
      !searchForm.examCode &&
      !searchForm.examRound &&
      !searchForm.userPhone
    ) {
      setFormError({
        field: "missing",
        text: "Bạn chưa điền thông tin tìm kiếm, xin hãy kiểm tra lại !",
      });
      return;
    }
    handleBackdropOpen();
    setContestEntries([]);
    setContestEntriesIdArray([]);
    setPageData(1);
    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/contest-entry/admin/search-results/?type=${contestEntriesType}&examRound=${searchForm.examRound}&city=${searchForm.city}&district=${searchForm.district}&examCode=${searchForm.examCode}&candidateName=${searchForm.candidateName}&candidateDob=${searchForm.candidateDob}&userPhone=${searchForm.userPhone}&candidateSchool=${searchForm.candidateSchool}&page=${page}`
      )
      .then((respone) => {
        if (respone.data.rows) {
          setContestEntries(respone.data.rows);
          var tempArray = [];
          respone.data.rows.forEach((contestEntry) => {
            tempArray.push(contestEntry.id);
          });
          setContestEntriesIdArray(tempArray);
          setTotalRows(respone.data.count);

          handleBackdropClose();
        }
      })
      .catch((error) => console.log(error));
  };

  const getDistrictsByCity = (cityId) => {
    handleBackdropOpen();
    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/districts/find-by-city/${cityId}`
      )
      .then((respone) => {
        if (respone.data.rows) {
          setDistricts(respone.data.rows);
          handleBackdropClose();
        }
      })
      .catch((error) => console.log(error));
  };

  // begin get detail contest entry

  const [contestEntryDetailInfo, setContestEntryDetailInfo] = React.useState({
    contestEntryId: "",
    contestEntriesImages: [],
    candidateName: "",
    candidateDob: "",
    userPhone: "",
    candidateSchool: "",
    address: "",
    district: "",
    city: "",
    examType: "",
    contestJoinDate: "",
    examRound: {
      id: "",
      firstDoW: "",
      firstDoM: "",
      isDone: "",
      roundSequenceId: "",
      roundSequenceSequence: 0,
    },
    subject: "",
    content: "",
    sense: "",
    views: "",
    status: "",
    examCode: "",
    roundSequence: {
      id: "",
      sequence: "",
      isEndWeekRound: "",
      isFinalRound: "",
      name: "",
    },
  });
  const [submissionCardModalOpen, updateSubmissionCardModalOpen] =
    React.useState(false);

  const handleSubmissionCardModalClose = () => {
    updateSubmissionCardModalOpen(false);
  };

  const onModalNextArrowClick = () => {
    let parent = document.querySelector(
      ".admin-detail-submission-card-modal-title-wrapper"
    );
    let arrow = parent.querySelector(".control-next");
    arrow.click();
  };

  const onModalPrevArrowClick = () => {
    let parent = document.querySelector(
      ".admin-detail-submission-card-modal-title-wrapper"
    );
    let arrow = parent.querySelector(".control-prev");
    arrow.click();
  };

  const clearDetailContestEntry = () => {
    setContestEntryDetailInfo((prevState) => ({
      ...prevState,
      contestEntryId: "",
      contestEntriesImages: [],
      candidateName: "",
      candidateDob: "",
      userPhone: "",
      candidateSchool: "",
      address: "",
      district: "",
      city: "",
      examType: "",
      contestJoinDate: "",
      examRound: {
        id: "",
        firstDoW: "",
        firstDoM: "",
        isDone: "",
        roundSequenceId: "",
        roundSequenceSequence: 0,
      },
      subject: "",
      content: "",
      sense: "",
      views: "",
      status: "",
      examCode: "",
      roundSequence: {
        id: "",
        sequence: "",
        isEndWeekRound: "",
        isFinalRound: "",
        name: "",
      },
    }));
  };

  const getDetailContestEntry = (contestEntryId) => {
    handleBackdropOpen();
    clearDetailContestEntry();
    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/contest-entry/${contestEntryId}`
      )
      .then((respone) => {
        if (respone.data) {
          updateSubmissionCardModalOpen(true);
          setContestEntryDetailInfo((prevState) => ({
            ...prevState,
            contestEntryId: respone.data.id,
            contestEntriesImages: respone.data.contestEntriesImages,
            candidateName: respone.data.candidateName,
            candidateDob: DateTimeConvert.yyyymmddConvert(
              respone.data.candidateDob
            ),
            userPhone: respone.data.user
              ? respone.data.user.phone
              : "<Không có thông tin>",
            candidateSchool: respone.data.candidateSchool,
            address: respone.data.user
              ? respone.data.user.address
              : "<Không có thông tin>",
            district: respone.data.user
              ? respone.data.user.district.name
              : "<Không có thông tin>",
            city: respone.data.user
              ? respone.data.user.city.name
              : "<Không có thông tin>",
            examType: respone.data.examType.name,
            contestJoinDate: DateTimeConvert.yyyymmddConvert(
              respone.data.createdAt
            ),
            examRound: {
              ...prevState.examRound,
              id: respone.data.examRound.id,
              firstDoW: DateTimeConvert.roundResultDateDisplay(
                respone.data.examRound.firstDoW
              ),
              firstDoM: respone.data.examRound.firstDoM,
              isDone: respone.data.examRound.isDone,
              roundSequenceId: respone.data.examRound.roundSequenceId,
              roundSequenceSequence:
                respone.data.examRound.roundSequence.sequence,
            },
            subject: respone.data.subject,
            content: respone.data.content,
            sense: respone.data.sense,
            views: respone.data.view,
            status: respone.data.status,
            examCode: respone.data.code,
            roundSequence: {
              ...prevState.roundSequence,
              id: respone.data.roundSequence?.id,
              sequence: respone.data.roundSequence
                ? respone.data.roundSequence.sequence
                : 0,
              isEndWeekRound: respone.data.roundSequence?.isEndWeekRound,
              isFinalRound: respone.data.roundSequence?.isFinalRound,
              name: respone.data.roundSequence?.name,
            },
          }));
          handleBackdropClose();
        }
      })
      .catch((error) => console.log(error));
  };
  // end get detail contest entry

  const displayPrevContestEntry = () => {
    const index = contestEntriesIdArray.indexOf(
      contestEntryDetailInfo.contestEntryId
    );
    getDetailContestEntry(contestEntriesIdArray[index - 1]);
  };

  const displayNextContestEntry = () => {
    const index = contestEntriesIdArray.indexOf(
      contestEntryDetailInfo.contestEntryId
    );
    getDetailContestEntry(contestEntriesIdArray[index + 1]);
  };

  // begin contest entries management features expand

  const [isContestEntriesSearchExpand, setContestEntriesSearchExpand] =
    React.useState(false);

  const [isResultsAnnoucementExpand, setIsResultsAnnoucementExpand] =
    React.useState(false);

  // end contest entries management features expand

  // begin set result's annoucement schedule

  const [resultAnnoucementScheduleForm, setResultAnnoucementScheduleForm] =
    React.useState({
      examRoundId: "",
      date: "",
      time: "",
    });

  const handleResultAnnoucementSettingChangeExamRound = (e) => {
    setResultAnnoucementScheduleForm((prevState) => ({
      ...prevState,
      examRoundId: e.target.value,
    }));
    handleBackdropOpen();
    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/annoucement-time/find-by-exam-round/${e.target.value}`
      )
      .then((res) => {
        if (res.data?.id) {
          setResultAnnoucementScheduleForm((prevState) => ({
            ...prevState,
            date: res.data.dateString || "",
            time: res.data.timeString || "",
          }));
          setTimeout(() => {
            handleBackdropClose();
          }, 500);
        } else {
          handleBackdropClose();
          setResultAnnoucementScheduleForm((prevState) => ({
            ...prevState,
            date: "",
            time: "",
          }));
        }
      });
  };

  const [
    resultAnnoucementSheduleFormError,
    setResultAnnoucementSheduleFormError,
  ] = React.useState({
    field: "",
    text: "",
  });

  const handleSetResultAnnoucementScheduleSubmit = () => {
    if (!resultAnnoucementScheduleForm.examRoundId) {
      setResultAnnoucementSheduleFormError((prevState) => ({
        ...prevState,
        field: "examRoundId",
        text: "Không được bỏ trống trường Tuần tham gia",
      }));
      return null;
    }
    if (!resultAnnoucementScheduleForm.date) {
      setResultAnnoucementSheduleFormError((prevState) => ({
        ...prevState,
        field: "examRoundId",
        text: "Không được bỏ trống trường Ngày công bố",
      }));
      return null;
    }
    if (!resultAnnoucementScheduleForm.time) {
      setResultAnnoucementSheduleFormError((prevState) => ({
        ...prevState,
        field: "examRoundId",
        text: "Không được bỏ trống trường Thời gian công bố",
      }));
      return null;
    }

    var timeString =
      String(resultAnnoucementScheduleForm.date) +
      " " +
      String(resultAnnoucementScheduleForm.time) +
      ":00";
    var timeStamp = Date.parse(timeString);
    var timeNow = Date.now();
    if (timeNow >= timeStamp) {
      setResultAnnoucementSheduleFormError((prevState) => ({
        ...prevState,
        field: "timeError",
        text: "Mốc thời gian không phù hợp",
      }));
    }
    handleBackdropOpen();
    axios
      .post(
        process.env.REACT_APP_SERVER_API_ROOT_URL + `/annoucement-time`,
        resultAnnoucementScheduleForm
      )
      .then((res) => {
        if (res.data?.id) {
          setResultAnnoucementSheduleFormError((prevState) => ({
            ...prevState,
            field: "",
            text: "",
          }));

          setTimeout(() => {
            handleBackdropClose();
            setSuccessSnackbarOpen((prevState) => ({
              ...prevState,
              isOpen: true,
              content: "Cập nhật thông tin thành công !",
            }));
          }, 500);
        }
      });
  };
  // end set result's annoucement schedule

  // begin get initial data
  const [pageData, setPageData] = React.useState(1);

  const [totalRows, setTotalRows] = React.useState(0);

  const [contestEntriesIdArray, setContestEntriesIdArray] = React.useState([]);

  const getListResultsPerPage = (contestEntriesType, page) => {
    if (contestEntriesType && page) {
      handleBackdropOpen();
      setContestEntries([]);
      setContestEntriesIdArray([]);
      axios
        .get(
          process.env.REACT_APP_SERVER_API_ROOT_URL +
            `/contest-entry/admin/get-results/?type=${contestEntriesType}&page=${page}`
        )
        .then((res) => {
          if (res.data) {
            setContestEntries(res.data.rows);
            setTotalRows(res.data.count);
            var tempArray = [];
            res.data.rows.forEach((contestEntry) => {
              tempArray.push(contestEntry.id);
            });
            setContestEntriesIdArray(tempArray);
            handleBackdropClose();
          } else {
            setTimeout(() => {
              handleBackdropClose();
            }, 0);
          }
        });
    }
  };

  React.useEffect(() => {
    if (contestEntriesType) {
      setContestEntries([]);
      setContestEntriesIdArray([]);
      setPageData(1);
      handleBackdropOpen();
      axios
        .get(
          process.env.REACT_APP_SERVER_API_ROOT_URL +
            `/contest-entry/admin/get-results/?type=${contestEntriesType}&page=${pageData}`
        )
        .then((res) => {
          if (res.data) {
            setContestEntries(res.data.rows);
            setTotalRows(res.data.count);
            var tempArray = [];
            res.data.rows.forEach((contestEntry) => {
              tempArray.push(contestEntry.id);
            });
            setContestEntriesIdArray(tempArray);
            axios
              .get(process.env.REACT_APP_SERVER_API_ROOT_URL + `/exam-round`)
              .then((res) => {
                if (res.data) {
                  setExamRounds(res.data.rows);
                  axios
                    .get(process.env.REACT_APP_SERVER_API_ROOT_URL + `/cities`)
                    .then((res) => {
                      if (res.data) {
                        setCities(res.data.rows);
                        handleBackdropClose();
                      } else {
                        handleBackdropClose();
                      }
                    });
                } else {
                  handleBackdropClose();
                }
              });
          } else {
            handleBackdropClose();
          }
        });
    }
  }, [contestEntriesType]);

  // begin contest entrie images display

  const contestEntriesImagesDisplay = (listImage) => {
    if (!Array.isArray(listImage) || !(listImage.length > 0)) {
      return null;
    } else {
      return (
        listImage.length > 0 &&
        listImage.map((img, index) => {
          return (
            <Grid
              key={index}
              item
              xs={12}
              display="flex"
              justifyContent="center"
              className="modal-image-wrapper"
            >
              <TransformWrapper
                initialScale={1}
                minScale={1}
                maxScale={isMobile ? 6 : 5}
              >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <React.Fragment>
                    <div className="tools-wrapper">
                      <IconButton onClick={() => zoomIn()}>
                        <AddCircleIcon />
                      </IconButton>
                      <IconButton onClick={() => zoomOut()}>
                        <RemoveCircleIcon />
                      </IconButton>
                      <IconButton onClick={() => resetTransform()}>
                        <RestartAltIcon />
                      </IconButton>
                    </div>
                    <TransformComponent wrapperStyle={{ overflow: "visible" }}>
                      <div
                        className="contest-entries-image-display-wrapper"
                        style={{
                          height: isTablet
                            ? isMobile
                              ? "300px"
                              : "600px"
                            : "1000px",
                          width: isTablet
                            ? isMobile
                              ? "300px"
                              : "600px"
                            : "1000px",
                        }}
                      >
                        <img
                          id="contest-entries-display-background-frame"
                          alt="contest entries background frame"
                          src="/images/contest-entries-background-frame.png"
                        />
                        <img
                          style={{
                            transform: `rotate(${img.image.rotateDegree}deg)`,
                            msTransform: `rotate(${img.image.rotateDegree}deg)`,
                            WebkitTransform: `rotate(${img.image.rotateDegree}deg)`,
                            msTransform: `rotate(${img.image.rotateDegree}deg)`,
                          }}
                          className="admin-submission-card-modal-img"
                          alt="submission test"
                          src={
                            process.env.REACT_APP_SERVER_ROOT_MEDIA_URL +
                            img.image.path
                          }
                        />
                      </div>
                    </TransformComponent>
                  </React.Fragment>
                )}
              </TransformWrapper>
            </Grid>
          );
        })
      );
    }
  };

  // end contest entrie images display

  const columns = [
    {
      field: "code",
      headerName: "Mã bài thi",
      headerClassName: "super-app-theme--header",
      width: 120,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: "",
      headerName: "Thao tác",
      headerClassName: "super-app-theme--header",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <Button
          onClick={() => {
            getDetailContestEntry(params.row.id);
          }}
          variant="contained"
          color="info"
          size="small"
        >
          Xem chi tiết
        </Button>
      ),
    },
    {
      field: "candidateName",
      headerName: "Tên thí sinh",
      headerClassName: "super-app-theme--header",
      width: 250,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: "candidateDob",
      headerName: "Ngày sinh",
      headerClassName: "super-app-theme--header",
      width: 120,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {DateTimeConvert.yyyymmddConvert(params.value)}
        </Typography>
      ),
    },
    {
      field: "candidateSchool",
      headerName: "Trường học",
      headerClassName: "super-app-theme--header",
      type: "number",
      width: 200,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: "candidateGender",
      headerName: "Giới tính",
      headerClassName: "super-app-theme--header",
      type: "text",
      width: 100,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {gender_vi[params.value]}
        </Typography>
      ),
    },
    {
      field: "view",
      headerName: "Lượt xem",
      headerClassName: "super-app-theme--header",
      type: "text",
      width: 100,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: "examType",
      headerName: "Hình thức",
      headerClassName: "super-app-theme--header",
      type: "text",
      width: 150,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {params.row.examType.name}
        </Typography>
      ),
    },
    {
      field: "createdAt",
      headerName: "Ngày tham gia",
      headerClassName: "super-app-theme--header",
      type: "text",
      width: 120,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {DateTimeConvert.yyyymmddConvert(params.value)}
        </Typography>
      ),
    },
    {
      field: "examRound",
      headerName: "Tuần tham gia",
      headerClassName: "super-app-theme--header",
      type: "text",
      width: 300,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {DateTimeConvert.roundResultDateDisplay(
            params.row.examRound.firstDoW
          )}
        </Typography>
      ),
    },
    {
      field: "userPhone",
      headerName: "SĐT liên lạc",
      headerClassName: "super-app-theme--header",
      type: "text",
      width: 150,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {params.row.user?.phone}
        </Typography>
      ),
    },
    {
      field: "userAddress",
      headerName: "Địa chỉ liên lạc",
      headerClassName: "super-app-theme--header",
      type: "text",
      width: 400,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {params.row.user?.address}, {params.row.user?.district.name},{" "}
          {params.row.user?.city.name}
        </Typography>
      ),
    },
  ];

  // end get initial data
  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 999 }}
        open={backdropOpen}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <EmojiEventsIcon fontSize={isMobile ? "medium" : "large"} />
            <Typography
              variant={isMobile ? "body1" : "quicksand18"}
              style={{
                marginLeft: "10px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              Quản lý kết quả
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={4}>
            <Accordion
              expanded={isContestEntriesSearchExpand}
              onChange={() => {
                setContestEntriesSearchExpand(!isContestEntriesSearchExpand);
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ManageSearchIcon fontSize="medium" />
                  <Typography
                    variant={isMobile ? "body2" : "body1"}
                    style={{ marginLeft: "10px", fontWeight: "bold" }}
                  >
                    Tìm kiếm
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                    <Typography variant="body1">Tuần tham gia:</Typography>
                    <FormControl
                      className={classes.root}
                      fullWidth
                      size="small"
                    >
                      <Select
                        value={searchForm.examRound}
                        onChange={handleExamRoundChange}
                      >
                        <MenuItem value={"all"}>{"Tất cả các tuần"}</MenuItem>
                        {examRounds?.length > 0 &&
                          examRounds.map((examRound) => {
                            return (
                              <MenuItem key={examRound.id} value={examRound.id}>
                                {DateTimeConvert.roundResultDateDisplay(
                                  examRound.firstDoW
                                )}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                    <Typography variant="body1">Tỉnh/Thành phố:</Typography>
                    <FormControl
                      className={classes.root}
                      fullWidth
                      size="small"
                    >
                      <Select
                        value={searchForm.city}
                        onChange={handleCityChange}
                      >
                        {Cities?.length > 0 &&
                          Cities.map((city) => {
                            return (
                              <MenuItem key={city.id} value={city.id}>
                                {city.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                    <Typography variant="body1">Quận/Huyện:</Typography>
                    <FormControl
                      className={classes.root}
                      fullWidth
                      size="small"
                    >
                      <Select
                        value={searchForm.district}
                        onChange={handleDistrictChange}
                      >
                        {Districts?.length > 0 &&
                          Districts.map((district) => {
                            return (
                              <MenuItem key={district.id} value={district.id}>
                                {district.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                    <Typography variant="body1">Mã bài thi:</Typography>
                    <TextField
                      value={searchForm.examCode}
                      onChange={handleExamCodeChange}
                      className={classes.root}
                      size="small"
                      color="info"
                      type="text"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                    <Typography variant="body1">Tên thí sinh:</Typography>
                    <TextField
                      value={searchForm.candidateName}
                      onChange={handleCandidateNameChange}
                      className={classes.root}
                      size="small"
                      color="info"
                      type="text"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                    <Typography variant="body1">Ngày sinh:</Typography>
                    <TextField
                      value={searchForm.candidateDob}
                      onChange={handleCandidateDobChange}
                      className={classes.root}
                      size="small"
                      color="info"
                      type="date"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                    <Typography variant="body1">SĐT liên lạc:</Typography>
                    <TextField
                      value={searchForm.userPhone}
                      onChange={handleUserPhoneChange}
                      className={classes.root}
                      size="small"
                      color="info"
                      type="tel"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                    <Typography variant="body1">Trường học:</Typography>
                    <TextField
                      value={searchForm.candidateSchool}
                      onChange={handleCandidateSchoolChange}
                      className={classes.root}
                      size="small"
                      color="info"
                      type="text"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  {formError.field && formError.text && (
                    <Grid item xs={12}>
                      <Alert
                        severity="error"
                        style={{ fontSize: `${isMobile ? "13px" : "16px"}` }}
                      >
                        <AlertTitle>
                          <strong>Có lỗi xảy ra</strong>
                        </AlertTitle>
                        {formError.text}
                      </Alert>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  justifyContent="flex-end"
                  style={{ marginTop: "20px" }}
                >
                  {/* <Button
                    startIcon={<RefreshIcon />}
                    onClick={resetSearchForm}
                    variant="contained"
                    color="success"
                    size={isMobile ? "small" : "medium"}
                    style={{ marginRight: `${isMobile ? "15px" : "20px"}` }}
                  >
                    Làm mới
                  </Button> */}
                  <Button
                    startIcon={<SearchIcon />}
                    onClick={() => {
                      setPageData(1);
                      handleSubmitSearchForm(1);
                    }}
                    variant="contained"
                    color="info"
                    size={isMobile ? "small" : "medium"}
                  >
                    Tìm kiếm
                  </Button>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={4}>
            <Accordion
              expanded={isResultsAnnoucementExpand}
              onChange={() => {
                setIsResultsAnnoucementExpand(!isResultsAnnoucementExpand);
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <SettingsIcon fontSize="medium" />
                  <Typography
                    variant={isMobile ? "body2" : "body1"}
                    style={{ marginLeft: "10px", fontWeight: "bold" }}
                  >
                    Cài đặt thời gian công bố giải
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={4}>
                  <Grid item md={5} xs={12}>
                    <Typography variant="body1">Tuần tham gia:</Typography>
                    <FormControl
                      className={classes.root}
                      fullWidth
                      size="small"
                    >
                      <Select
                        displayEmpty
                        defaultValue=""
                        value={resultAnnoucementScheduleForm.examRoundId}
                        onChange={(e) => {
                          handleResultAnnoucementSettingChangeExamRound(e);
                        }}
                      >
                        <MenuItem disabled value="">
                          Chọn tuần tham gia
                        </MenuItem>
                        {examRounds?.length > 0 &&
                          examRounds.map((examRound) => {
                            if (!examRound.isDone || examRound.isDone === 0) {
                              return (
                                <MenuItem
                                  key={examRound.id}
                                  value={examRound.id}
                                >
                                  {DateTimeConvert.roundResultDateDisplay(
                                    examRound.firstDoW
                                  )}
                                </MenuItem>
                              );
                            }
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={7} xs={12}>
                    <Typography variant="body1">
                      Chọn thời gian công bố:
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={7}>
                        <Grid container>
                          <Grid item xs={2}>
                            <span style={{ marginTop: "10px" }}>Ngày:</span>
                          </Grid>
                          <Grid item xs={10}>
                            <TextField
                              className={classes.root}
                              value={resultAnnoucementScheduleForm.date}
                              onChange={(e) => {
                                setResultAnnoucementScheduleForm(
                                  (prevState) => ({
                                    ...prevState,
                                    date: e.target.value,
                                  })
                                );
                              }}
                              size="small"
                              color="info"
                              variant="outlined"
                              type="date"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Grid container>
                          <Grid item xs={2}>
                            <span style={{ marginTop: "10px" }}>Giờ:</span>
                          </Grid>
                          <Grid item xs={10}>
                            <TextField
                              className={classes.root}
                              value={resultAnnoucementScheduleForm.time}
                              onChange={(e) => {
                                setResultAnnoucementScheduleForm(
                                  (prevState) => ({
                                    ...prevState,
                                    time: e.target.value,
                                  })
                                );
                              }}
                              size="small"
                              color="info"
                              variant="outlined"
                              type="time"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {resultAnnoucementSheduleFormError.field &&
                    resultAnnoucementSheduleFormError.text && (
                      <Grid item xs={12}>
                        <Alert
                          severity="error"
                          style={{ fontSize: `${isMobile ? "13px" : "16px"}` }}
                        >
                          <AlertTitle>
                            <strong>Có lỗi xảy ra</strong>
                          </AlertTitle>
                          {resultAnnoucementSheduleFormError.text}
                        </Alert>
                      </Grid>
                    )}
                  <Grid item xs={12} display="flex" justifyContent="flex-end">
                    <Button
                      startIcon={<UpdateIcon />}
                      size="medium"
                      color="success"
                      variant="contained"
                      onClick={handleSetResultAnnoucementScheduleSubmit}
                    >
                      Cập nhật
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={4} style={{ padding: "0px" }}>
            <Box
              sx={{
                height: 600,
                width: 1,
                "& .super-app-theme--header": {
                  fontWeight: "bolder",
                  fontSize: "16px",
                  color: "#000000",
                },
              }}
            >
              <DataGrid
                localeText={viVN.components.MuiDataGrid.defaultProps.localeText}
                rows={ContestEntries}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                paginationMode={"server"}
                rowCount={totalRows}
                onPageChange={(page, details) => {
                  setPageData(page + 1);
                  if (isSearched) {
                    handleSubmitSearchForm(page + 1);
                  } else {
                    getListResultsPerPage(contestEntriesType, page + 1);
                  }
                }}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        fullScreen={fullScreen}
        open={submissionCardModalOpen}
        onClose={handleSubmissionCardModalClose}
      >
        <DialogContent>
          <Grid
            container
            rowSpacing={2}
            className="submission-card-modal-box-inside-wrapper"
          >
            <Grid
              item
              xs={12}
              className="admin-detail-submission-card-modal-title-wrapper"
            >
              <Grid
                container
                justifyContent="center"
                spacing={isMobile ? 1 : 2}
                style={{ position: "relative" }}
              >
                <Grid item xs={12}>
                  <Typography
                    variant="quicksand18shadow"
                    className="submission-card-modal-title"
                    sx={{ fontWeight: "700" }}
                  >
                    Ảnh mô tả bài thi:
                  </Typography>
                </Grid>
                {contestEntryDetailInfo.contestEntriesImages.length > 1 && (
                  <img
                    onClick={onModalNextArrowClick}
                    className="submission-modal-next-btn"
                    alt="submissions modal next btn"
                    src={
                      process.env.PUBLIC_URL +
                      "/images/homepage-slider-icon-next.png"
                    }
                  />
                )}
                {contestEntryDetailInfo.contestEntriesImages.length > 1 && (
                  <img
                    onClick={onModalPrevArrowClick}
                    className="submission-modal-prev-btn"
                    alt="submissions modal back btn"
                    src={
                      process.env.PUBLIC_URL +
                      "/images/homepage-slider-icon-back.png"
                    }
                  />
                )}
                <Carousel
                  infiniteLoop={true}
                  showStatus={false}
                  showIndicators={false}
                  showThumbs={false}
                  swipeable={false}
                  width={isTablet ? (isMobile ? 300 : 600) : 1000}
                >
                  {contestEntriesImagesDisplay(
                    contestEntryDetailInfo.contestEntriesImages
                  )}
                </Carousel>
              </Grid>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Mã bài thi: {contestEntryDetailInfo.examCode}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Họ tên thí sinh: {contestEntryDetailInfo.candidateName}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Ngày sinh: {contestEntryDetailInfo.candidateDob}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                SĐT liên lạc: {contestEntryDetailInfo.userPhone}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Trường: {contestEntryDetailInfo.candidateSchool}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Địa chỉ liên lạc: {contestEntryDetailInfo.address},{" "}
                {contestEntryDetailInfo.district}, {contestEntryDetailInfo.city}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Tên chủ đề: {contestEntryDetailInfo.subject}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Nội dung tác phẩm: {contestEntryDetailInfo.content}
              </Typography>
            </Grid>
            {/* <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Ý nghĩa: {contestEntryDetailInfo.sense}
              </Typography>
            </Grid> */}
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Hình thức thi: {contestEntryDetailInfo.examType}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Ngày tham gia: {contestEntryDetailInfo.contestJoinDate}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Tuần tham gia: {contestEntryDetailInfo.examRound.firstDoW}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Lượt xem: {contestEntryDetailInfo.views} lượt
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Kết quả hiện tại:{" "}
                {contestEntryDetailInfo.roundSequence.id
                  ? contestEntryDetailInfo.roundSequence.name
                      .concat(" - ")
                      .concat(result_vi[contestEntryDetailInfo.status])
                  : "Vòng 1"
                      .concat(" - ")
                      .concat(result_vi[contestEntryDetailInfo.status])}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: "15px" }}>
          <Button
            disabled={
              contestEntriesIdArray.indexOf(
                contestEntryDetailInfo.contestEntryId
              ) === 0
            }
            startIcon={<ArrowLeftIcon fontSize="large" />}
            variant="contained"
            color="primary"
            size={isMobile ? "small" : "medium"}
            onClick={displayPrevContestEntry}
          >
            Trước
          </Button>
          <Button
            disabled={
              contestEntriesIdArray.indexOf(
                contestEntryDetailInfo.contestEntryId
              ) ===
              contestEntriesIdArray.length - 1
            }
            endIcon={<ArrowRightIcon fontSize="large" />}
            variant="contained"
            color="primary"
            size={isMobile ? "small" : "medium"}
            onClick={displayNextContestEntry}
          >
            Sau
          </Button>
          <Button
            variant="contained"
            color="semiBlack"
            size="medium"
            onClick={handleSubmissionCardModalClose}
          >
            Đóng
          </Button>
        </DialogActions>
      </Dialog>

      <SuccessSnackbar
        isOpen={successSnackbarOpen.isOpen}
        content={successSnackbarOpen.content}
        onClose={() => {
          setSuccessSnackbarOpen((prevState) => ({
            ...prevState,
            isOpen: false,
            content: "",
          }));
        }}
      />
    </React.Fragment>
  );
}
