import {
  Container,
  Grid,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Button,
  CardMedia,
  Modal,
  Box,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import "./SubmitExamForm.css";
import React, { useState } from "react";
import DatePicker from "../../LayoutUI/DatePicker";
// import { styled } from '@mui/material/styles';
import axios from "axios";
import FormData from "form-data";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Rotate90DegreesCwIcon from "@mui/icons-material/Rotate90DegreesCw";
// import 'blueimp-load-image/js/index.js'
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const modalBoxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#FDE6E4",
  boxShadow: 24,
};

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-input": {
      color: "#000000",
      fontFamily: "Quicksand",
      fontSize: 16,
      fontWeight: 600,
      paddingTop: "8px",
      paddingBottom: "8px",
      zIndex: 1,
      paddingLeft: 8,
    },
  },
  input: {
    color: "#000000",
    background: "white",
    borderRadius: 7,
    border: "2px solid #BABABA",
    height: "45px",
  },
  textArea: {
    color: "#000000",
    background: "white",
    borderRadius: 7,
    border: "2px solid #BABABA",
  },
  selectInput: {
    width: "100%",
    height: "45px",
    background: "#FFFFFF",
    border: "2px solid #BABABA",
    boxSizing: "border-box",
    borderRadius: "7px",
    color: "#000000",
    fontFamily: "Quicksand",
    fontSize: 16,
    fontWeight: 600,
    paddingTop: "8px",
    paddingBottom: "8px",
    zIndex: 1,
    paddingLeft: 8,
  },
});

export default function SubmitExamForm(props) {
  const classes = useStyles();
  const examImageInput = React.useRef(null);

  const setIsSubmited = props.getIsSubmited;

  const setContestEntryCreated = props.getContestEntryCreated;

  const [imageInput, setImageInput] = React.useState([]);

  const [listCities, setListCities] = React.useState([]);

  const [listDistricts, setListDistricts] = React.useState([]);

  const [listExamTypes, setListExamTypes] = React.useState([]);

  const [backdropOpen, setBackdropOpen] = React.useState(false);

  const handleBackdropClose = () => {
    setBackdropOpen(false);
  };

  const handleBackdropOpen = () => {
    setBackdropOpen(true);
  };

  // begin get initial data
  const getDistrictsByCity = (cityId) => {
    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/districts/find-by-city/${cityId}`
      )
      .then((respone) => {
        if (respone.data.rows) {
          setListDistricts(respone.data.rows);
        }
      })
      .catch((error) => console.log(error));
  };

  React.useEffect(() => {
    // cities
    axios
      .get(process.env.REACT_APP_SERVER_API_ROOT_URL + "/cities/")
      .then((respone) => {
        if (respone.data.rows.length > 0) {
          setListCities(respone.data.rows);
        }
      })
      .catch((error) => console.log(error));

    // examType
    axios
      .post(
        process.env.REACT_APP_SERVER_API_ROOT_URL + "/exam-types/initial-value",
        {}
      )
      .then((respone) => {
        if (respone.data.res === "successful") {
          axios
            .get(process.env.REACT_APP_SERVER_API_ROOT_URL + "/exam-types/")
            .then((respone) => {
              if (respone.data.rows.length > 0) {
                setListExamTypes(respone.data.rows);
              }
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => console.log(error));

    // round-sequences
    axios
      .post(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          "/round-sequences/initial-value",
        {}
      )
      .then((respone) => {})
      .catch((error) => console.log(error));
  }, []);

  // end get initial data

  const [imgPreviewSrc, setImgPreviewSrc] = React.useState([]);

  const [submitData, setSubmitData] = React.useState({
    fullName: "",
    dob: "",
    school: "",
    gender: "male",
    parentName: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    district: "",
    examForm: "",
    examTopic: "",
    examContent: "",
    examSense: "",
    images: [],
  });

  const updateFullName = (e) => {
    setSubmitData((prevState) => ({
      ...prevState,
      fullName: e.target.value,
    }));
  };

  const updateDob = (dobData) => {
    setSubmitData((prevState) => ({
      ...prevState,
      dob: dobData.target.value,
    }));
  };

  const updateSchool = (e) => {
    setSubmitData((prevState) => ({
      ...prevState,
      school: e.target.value,
    }));
  };

  const updateGender = (e) => {
    setSubmitData((prevState) => ({
      ...prevState,
      gender: e.target.value,
    }));
  };

  const updateParentName = (e) => {
    setSubmitData((prevState) => ({
      ...prevState,
      parentName: e.target.value,
    }));
  };

  const updatePhone = (e) => {
    setSubmitData((prevState) => ({
      ...prevState,
      phone: e.target.value,
    }));
  };

  const updateEmail = (e) => {
    setSubmitData((prevState) => ({
      ...prevState,
      email: e.target.value,
    }));
  };

  const updateAddress = (e) => {
    setSubmitData((prevState) => ({
      ...prevState,
      address: e.target.value,
    }));
  };

  const updateCity = (e) => {
    setSubmitData((prevState) => ({
      ...prevState,
      city: e.target.value,
    }));
    setSubmitData((prevState) => ({
      ...prevState,
      district: "",
    }));
    getDistrictsByCity(e.target.value);
  };

  const updateDistrict = (e) => {
    setSubmitData((prevState) => ({
      ...prevState,
      district: e.target.value,
    }));
  };

  const updateExamForm = (e) => {
    setSubmitData((prevState) => ({
      ...prevState,
      examForm: e.target.value,
    }));
  };

  const updateExamTopic = (e) => {
    setSubmitData((prevState) => ({
      ...prevState,
      examTopic: e.target.value,
    }));
  };

  const updateExamContent = (e) => {
    setSubmitData((prevState) => ({
      ...prevState,
      examContent: e.target.value,
    }));
  };

  const updateExamSense = (e) => {
    setSubmitData((prevState) => ({
      ...prevState,
      examSense: e.target.value,
    }));
  };

  const [fullNameError, setFullNameError] = useState(false);
  const [schoolError, setSchoolError] = useState(false);
  const [parentNameError, setParentNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [topicError, setTopicError] = useState(false);
  const [contentError, setContentError] = useState(false);
  const [contestEntryCreate, setContestEntryCreate] = React.useState(false);

  const handleSubmit = () => {
    setFullNameError(false);
    setSchoolError(false);
    setParentNameError(false);
    setPhoneError(false);
    setEmailError(false);
    setAddressError(false);
    setTopicError(false);
    setContentError(false);

    if (submitData["fullName"] === "") {
      setFullNameError(true);
    }
    if (submitData["school"] === "") {
      setSchoolError(true);
    }
    if (submitData["parentName"] === "") {
      setParentNameError(true);
    }
    if (submitData["phone"] === "") {
      setPhoneError(true);
    }
    if (submitData["email"] === "") {
      setEmailError(true);
    }
    if (submitData["address"] === "") {
      setAddressError(true);
    }
    if (submitData["examTopic"] === "") {
      setTopicError(true);
    }
    if (submitData["examContent"] === "") {
      setContentError(true);
    }

    var submitKeys = [
      "fullName",
      "dob",
      "school",
      "gender",
      "parentName",
      "phone",
      "email",
      "address",
      "city",
      "district",
      "examForm",
      "examTopic",
      "examContent",
      // "examSense",
    ];

    for (let key of submitKeys) {
      if (!submitData[key]) {
        setErrorModalTitle(
          "Bạn đang điền thiếu thông tin, vui lòng kiểm tra lại"
        );
        return handleErrorModalOpen();
      }
    }

    if (!imageInput || imageInput.length === 0) {
      setErrorModalTitle("Bạn chưa chọn ảnh bài thi, vui lòng kiểm tra lại");
      return handleErrorModalOpen();
    }

    // image create
    handleBackdropOpen();
    imageInput.map((image) => {
      var imgData = new FormData();
      imgData.append("image", image.file);
      imgData.append("name", image.file.name);
      imgData.append("rotateDegree", image.rotateDegree);
      setTimeout(() => {
        axios
          .post(
            process.env.REACT_APP_SERVER_API_ROOT_URL + "/images/",
            imgData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((respone) => {
            if (respone.status === 200) {
              setTimeout(() => {
                setSubmitData((prevState) => ({
                  ...prevState,
                  images: [...prevState.images, { id: respone.data.id }],
                }));
              }, 1000);
            }
          })
          .catch((error) => {
            console.log(error);
            setSubmitData((prevState) => ({
              ...prevState,
              images: [],
            }));
            setImageInput([]);
            setImgPreviewSrc([]);
            setTimeout(() => {
              handleBackdropClose();
              setErrorModalTitle(
                "Có lỗi xảy ra khi nộp bài, vui lòng kiểm tra lại"
              );
              return handleErrorModalOpen();
            }, 1000);
          });
      }, 1000);
    });
  };

  React.useEffect(() => {
    if (
      imageInput.length > 0 &&
      submitData.images.length > 0 &&
      submitData.images.length === imageInput.length
    ) {
      setContestEntryCreate(true);
    }
  }, [submitData.images]);

  React.useEffect(() => {
    if (submitData.images.length > 0 && contestEntryCreate) {
      //contest entry create
      axios
        .post(
          process.env.REACT_APP_SERVER_API_ROOT_URL + "/contest-entry/",
          submitData
        )
        .then((respone) => {
          if (respone.status === 200) {
            setContestEntryCreate(false);
            setIsSubmited(true);
            setContestEntryCreated(respone.data);
            handleBackdropClose();
          }
        })
        .catch((error) => console.log(error));
    }
  }, [submitData.images, contestEntryCreate]);

  const [errorModalOpen, updateErrorModalOpen] = React.useState(false);

  const [errorModalTitle, setErrorModalTitle] = React.useState("");

  const handleErrorModalOpen = () => {
    updateErrorModalOpen(true);
  };

  const handleErrorModalClose = () => {
    updateErrorModalOpen(false);
  };

  const handleImageInputEvent = () => {
    examImageInput.current.click();
  };

  const handleImageInputChange = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    } else {
      for (let i = 0; i <= e.target.files.length - 1; i++) {
        const objectUrl = URL.createObjectURL(e.target.files[i]);
        setImageInput((prevArray) => [
          ...prevArray,
          {
            file: e.target.files[i],
            objectUrl: objectUrl,
            rotateDegree: 0,
          },
        ]);
        setImgPreviewSrc((prevArray) => [
          ...prevArray,
          {
            objectUrl: objectUrl,
            rotateDegree: 0,
          },
        ]);
      }
    }
  };

  const handleRotateImage = async (imageToRotate) => {
    if (!imageToRotate) {
      return null;
    }
    const chonsenImage = imageInput.filter((img) => {
      return img.objectUrl === imageToRotate.objectUrl;
    });
    if (chonsenImage.length === 0) {
      return null;
    }
    handleBackdropOpen();

    // replace object in list preview image
    setTimeout(() => {
      var tempArrayPreview = [...imgPreviewSrc];
      var previewIndex = tempArrayPreview.indexOf(imageToRotate);
      if (previewIndex !== -1) {
        tempArrayPreview[previewIndex]["rotateDegree"] =
          (tempArrayPreview[previewIndex].rotateDegree + 90) % 360;
      }
      setImgPreviewSrc(tempArrayPreview);
    }, 1000);

    // replace object in list image input

    setTimeout(() => {
      var tempArrayInput = [...imageInput];
      var inputIndex = -1;
      tempArrayInput.forEach((image) => {
        if (image.objectUrl === imageToRotate.objectUrl) {
          inputIndex = tempArrayInput.indexOf(image);
        }
      });

      if (inputIndex !== -1) {
        tempArrayInput[inputIndex]["rotateDegree"] =
          (tempArrayInput[inputIndex].rotateDegree + 90) % 360;
      }
      setImageInput(tempArrayInput);
      handleBackdropClose();
    }, 1000);
  };

  const handleDeleteImage = (imgToDelete) => {
    // remove image from list ImageToPreview
    var arrayPreview = [...imgPreviewSrc];
    var index = arrayPreview.indexOf(imgToDelete);
    if (index !== -1) {
      arrayPreview.splice(index, 1);
      setImgPreviewSrc(arrayPreview);
    }
    var arrayInput = [...imageInput];
    var inputIndex;
    arrayInput.forEach((img) => {
      if (img.objectUrl === imgToDelete.objectUrl) {
        inputIndex = arrayInput.indexOf(img);
      }
    });
    if (inputIndex !== -1) {
      arrayInput.splice(inputIndex, 1);
      setImageInput(arrayInput);
    }
  };

  return (
    <React.Fragment>
      <Grid
        container
        sx={{ width: "80%", mx: "auto", position: "relative", mt: 2 }}
        justifyContent="center"
      >
        <Grid container justifyContent="center">
          <Grid
            container
            justifyContent="center"
            sx={{ textAlign: "center", position: "relative", my: 2 }}
          >
            <Grid item xs={10} md={8} display="flex" justifyContent="center">
              <Box
                className="submit-exam-title-box"
                sx={{
                  backgroundImage: "url('/images/SubmitExam/bg-title.png')",
                }}
              >
                <Typography
                  fontSize={{ lg: 36, md: 36, sm: 36, xs: 22 }}
                  variant="crocante36"
                  color="yellow.main"
                >
                  Nộp bài tham gia
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 4, textAlign: 'center' }}>
            <Typography
              className="contest-entries-blank-text"
              sx={{ textAlign: 'center', width: '100%' }}
            >
              Nộp bài dự thi Vòng Chung khảo
            </Typography>
          </Grid>
          <Grid container sx={{ mt: 4 }}>
            <button
              className="confirm-submit-button"
              type="button"
              style={{ margin: 'auto' }}
            >
              <a href="https://forms.gle/4srS9B4vXruv3tvU8" style={{ 'text-decoration': 'none' }}>Nộp bài</a>
            </button>
          </Grid>
          <Grid container sx={{ mt: 4, display: 'None' }}>
            <Grid item xs={12} md={4} sx={{ px: 1, my: 2 }}>
              <Typography variant="quicksand18" color="white.main">
                Họ tên thí sinh *
              </Typography>
              <Container disableGutters={true} sx={{ mt: 1 }}>
                <TextField
                  id="fullName"
                  fullWidth
                  type="text"
                  InputProps={{
                    className: classes.input,
                  }}
                  className={classes.root}
                  value={submitData.fullName}
                  onChange={updateFullName}
                  error={fullNameError}
                  helperText={
                    fullNameError ? "Vui lòng điền họ tên thí sinh!" : ""
                  }
                />
              </Container>
            </Grid>

            <Grid item xs={12} md={3} sx={{ px: 1, my: 2 }}>
              <Typography variant="quicksand18" color="white.main">
                Ngày sinh *
              </Typography>
              {/* <DatePicker getDobData={updateDob} /> */}
              <input
                type="date"
                onChange={updateDob}
                className="search-box-guest-info-input"
              ></input>
            </Grid>
            <Grid item xs={12} md={3} sx={{ px: 1, my: 2 }}>
              <Typography variant="quicksand18" color="white.main">
                Trường *
              </Typography>
              <Container disableGutters={true} sx={{ mt: 1 }}>
                <TextField
                  id="school"
                  fullWidth
                  type="text"
                  InputProps={{
                    className: classes.input,
                  }}
                  className={classes.root}
                  value={submitData.school}
                  onChange={updateSchool}
                  error={schoolError}
                  helperText={schoolError ? "Vui lòng điền trường học!" : ""}
                />
              </Container>
            </Grid>
            <Grid item xs={12} md={2} sx={{ px: 1, my: 2 }}>
              <Typography variant="quicksand18" color="white.main">
                Giới tính * <br />
              </Typography>
              <FormControl
                component="fieldset"
                sx={{ mt: 1, color: "#FFFFFF" }}
              >
                <FormLabel component="legend"></FormLabel>
                <RadioGroup
                  row
                  name="row-radio-buttons-group"
                  value={submitData.gender}
                  onChange={updateGender}
                >
                  <FormControlLabel
                    value="male"
                    control={
                      <Radio
                        sx={{
                          color: "yellow.main",
                          "&.Mui-checked": {
                            color: "yellow.main",
                          },
                        }}
                      />
                    }
                    label="Nam"
                  />
                  <FormControlLabel
                    value="female"
                    control={
                      <Radio
                        sx={{
                          color: "yellow.main",
                          "&.Mui-checked": {
                            color: "yellow.main",
                          },
                        }}
                      />
                    }
                    label="Nữ"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4} sx={{ px: 1, my: 2 }}>
              <Typography variant="quicksand18" color="white.main">
                Họ tên phụ huynh *
              </Typography>
              <Container disableGutters={true} sx={{ mt: 1 }}>
                <TextField
                  id="parentName"
                  fullWidth
                  type="text"
                  InputProps={{
                    className: classes.input,
                  }}
                  className={classes.root}
                  value={submitData.parentName}
                  onChange={updateParentName}
                  error={parentNameError}
                  helperText={
                    parentNameError ? "Vui lòng điền họ tên phụ huynh!" : ""
                  }
                />
              </Container>
            </Grid>
            <Grid item xs={12} md={4} sx={{ px: 1, my: 2 }}>
              <Typography variant="quicksand18" color="white.main">
                Điện thoại *
              </Typography>
              <Container disableGutters={true} sx={{ mt: 1 }}>
                <TextField
                  id="phone"
                  fullWidth
                  type="tel"
                  InputProps={{
                    className: classes.input,
                  }}
                  className={classes.root}
                  value={submitData.phone}
                  onChange={updatePhone}
                  error={phoneError}
                  helperText={phoneError ? "Vui lòng điền số điện thoại!" : ""}
                />
              </Container>
            </Grid>
            <Grid item xs={12} md={4} sx={{ px: 1, my: 2 }}>
              <Typography variant="quicksand18" color="white.main">
                Email *
              </Typography>
              <Container disableGutters={true} sx={{ mt: 1 }}>
                <TextField
                  id="email"
                  fullWidth
                  type="email"
                  InputProps={{
                    className: classes.input,
                  }}
                  className={classes.root}
                  value={submitData.email}
                  onChange={updateEmail}
                  error={emailError}
                  helperText={emailError ? "Vui lòng điền email!" : ""}
                />
              </Container>
            </Grid>
            <Grid item xs={12} md={4} sx={{ px: 1, my: 2 }}>
              <Typography variant="quicksand18" color="white.main">
                Số nhà, tên đường *
              </Typography>
              <Container disableGutters={true} sx={{ mt: 1 }}>
                <TextField
                  id="address"
                  fullWidth
                  type="text"
                  InputProps={{
                    className: classes.input,
                  }}
                  className={classes.root}
                  value={submitData.address}
                  onChange={updateAddress}
                  error={addressError}
                  helperText={
                    addressError ? "Vui lòng điền số nhà, tên đường!" : ""
                  }
                />
              </Container>
            </Grid>
            <Grid item xs={12} md={4} sx={{ px: 1, my: 2 }}>
              <Typography variant="quicksand18" color="white.main">
                Tỉnh/ Thành phố *
              </Typography>
              <Container disableGutters={true} sx={{ mt: 1 }}>
                <select
                  className={classes.selectInput}
                  value={submitData.city}
                  onChange={updateCity}
                  id="city"
                >
                  <option key={0} value="" disabled={true}>
                    Chọn Tỉnh/ Thành phố
                  </option>
                  {listCities.length > 0 &&
                    listCities.map((city, index) => {
                      return (
                        <option key={index + 1} value={city.id}>
                          {city.name}
                        </option>
                      );
                    })}
                </select>
              </Container>
            </Grid>
            <Grid item xs={12} md={4} sx={{ px: 1, my: 2 }}>
              <Typography variant="quicksand18" color="white.main">
                Quận/ Huyện *
              </Typography>
              <Container disableGutters={true} sx={{ mt: 1 }}>
                <select
                  className={classes.selectInput}
                  value={submitData.district}
                  onChange={updateDistrict}
                  id="district"
                >
                  <option key={0} value="" disabled={true}>
                    Chọn Quận/ Huyện
                  </option>
                  {listDistricts.length > 0 &&
                    listDistricts.map((district, index) => {
                      return (
                        <option key={index + 1} value={district.id}>
                          {district.name}
                        </option>
                      );
                    })}
                </select>
              </Container>
            </Grid>

            <Grid item xs={12} md={12} sx={{ px: 1, my: 2 }}>
              <Typography variant="quicksand18" color="white.main">
                Hình thức tham gia *
              </Typography>
              <Container disableGutters={true} sx={{ mt: 1 }}>
                <select
                  className={classes.selectInput}
                  value={submitData.examForm}
                  onChange={updateExamForm}
                  id="examForm"
                >
                  <option key={0} value="" disabled={true}>
                    Chọn hình thức tham gia
                  </option>
                  {listExamTypes.length > 0 &&
                    listExamTypes.map((examType, index) => {
                      return (
                        <option key={index + 1} value={examType.id}>
                          {examType.name}
                        </option>
                      );
                    })}
                </select>
              </Container>
            </Grid>

            <Grid item xs={12} md={12} sx={{ px: 1, my: 2 }}>
              <Typography variant="quicksand18" color="white.main">
                Tên chủ đề *
              </Typography>
              <Container disableGutters={true} sx={{ mt: 1 }}>
                <TextField
                  id="topic"
                  fullWidth
                  type="text"
                  InputProps={{
                    className: classes.input,
                  }}
                  className={classes.root}
                  value={submitData.examTopic}
                  onChange={updateExamTopic}
                  error={topicError}
                  helperText={topicError ? "Vui lòng điền tên chủ đề!" : ""}
                />
              </Container>
            </Grid>

            <Grid item xs={12} md={12} sx={{ px: 1, my: 2 }}>
              <Typography variant="quicksand18" color="white.main">
                Nội dung tác phẩm *
              </Typography>
              <Container
                disableGutters={true}
                sx={{ mt: 1, "& .MuiOutlinedInput-root": { padding: "0px" } }}
              >
                <TextField
                  id="content"
                  fullWidth
                  type="text"
                  InputProps={{
                    className: classes.textArea,
                  }}
                  multiline
                  rows={4}
                  className={classes.root}
                  value={submitData.examContent}
                  onChange={updateExamContent}
                  error={contentError}
                  helperText={
                    contentError ? "Vui lòng điền nội dung tác phẩm!" : ""
                  }
                />
              </Container>
            </Grid>

            {/* <Grid item xs={12} md={12} sx={{ px: 1, my: 2 }}>
              <Typography variant="quicksand18" color="white.main">
                Ý nghĩa *
              </Typography>
              <Container
                disableGutters={true}
                sx={{ mt: 1, "& .MuiOutlinedInput-root": { padding: "0px" } }}
              >
                <TextField
                  id="sense"
                  fullWidth
                  type="text"
                  InputProps={{
                    className: classes.textArea,
                  }}
                  multiline
                  rows={4}
                  className={classes.root}
                  value={submitData.examSense}
                  onChange={updateExamSense}
                  error={contentError}
                  helperText={
                    contentError ? "Vui lòng điền ý nghĩa tác phẩm!" : ""
                  }
                />
              </Container>
            </Grid> */}
            <Grid item xs={12} md={12} sx={{ px: 1, my: 2 }}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={0}
                className="submit-exam-image-input-wrapper"
                onClick={
                  !(imgPreviewSrc.length > 0) ? handleImageInputEvent : null
                }
              >
                {imgPreviewSrc.length > 0 ? (
                  <React.Fragment>
                    <Grid item xs={12}>
                      <Grid
                        container
                        justifyContent="center"
                        style={{ padding: "20px" }}
                        onClick={handleImageInputEvent}
                      >
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          justifyContent="center"
                          style={{ marginTop: "auto", marginBottom: "5px" }}
                        >
                          <img
                            className="submit-exam-camera-icon"
                            alt="submit exam camera icon"
                            src="images/SubmitExam/camera-icon.png"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          justifyContent="center"
                          style={{ marginTop: "5px", marginBottom: "auto" }}
                        >
                          <Typography className="submit-exam-image-input-text">
                            Hình ảnh bài tham gia
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {imgPreviewSrc.map((img, index) => (
                      <Grid
                        item
                        key={index}
                        xl={4}
                        lg={4}
                        md={6}
                        xs={12}
                        style={{
                          padding: "5px",
                          borderRadius: "5px",
                          border: "2px solid rgba(0,0,0,0.5)",
                          margin: "10px",
                          position: "relative",
                          transform: `rotate(${img.rotateDegree}deg)`,
                          msTransform: `rotate(${img.rotateDegree}deg)`,
                          WebkitTransform: `rotate(${img.rotateDegree}deg)`,
                          msTransform: `rotate(${img.rotateDegree}deg)`,
                        }}
                        display="flex"
                        justifyContent="center"
                      >
                        <IconButton
                          onClick={() => {
                            handleDeleteImage(img);
                          }}
                          color="default"
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            zIndex: "999",
                          }}
                        >
                          <CancelIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            handleRotateImage(img);
                          }}
                          color="default"
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "50px",
                            zIndex: "999",
                          }}
                        >
                          <Rotate90DegreesCwIcon />
                        </IconButton>
                        <img
                          // style={{
                          //   transform: `rotate(${img.rotateDegree}deg)`,
                          // }}
                          src={img.objectUrl}
                          alt="user upload img"
                          className="user-image-upload-preview-wrapper"
                        />
                      </Grid>
                    ))}
                  </React.Fragment>
                ) : (
                  <div>
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent="center"
                      style={{ marginTop: "auto", marginBottom: "5px" }}
                    >
                      <img
                        className="submit-exam-camera-icon"
                        alt="submit exam camera icon"
                        src="images/SubmitExam/camera-icon.png"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent="center"
                      style={{ marginTop: "5px", marginBottom: "auto" }}
                    >
                      <Typography className="submit-exam-image-input-text">
                        Hình ảnh bài tham gia
                      </Typography>
                    </Grid>
                  </div>
                )}
              </Grid>
              <input
                ref={examImageInput}
                hidden
                multiple
                type="file"
                accept="image/*"
                onChange={handleImageInputChange}
              />
            </Grid>
            <Grid
              container
              justifyContent="center"
              sx={{ textAlign: "center", my: 2 }}
            >
              <button
                className="confirm-submit-button"
                onClick={handleSubmit}
                type="button"
              >
                Xác nhận
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal hideBackdrop open={errorModalOpen} onClose={handleErrorModalClose}>
        <Box sx={modalBoxStyle} className="error-modal-box-wrapper">
          <Grid
            container
            justifyContent="center"
            className="error-modal-box-inside-wrapper"
          >
            <img
              onClick={handleErrorModalClose}
              className="error-modal-close-icon"
              alt="submission search page error modal close icon"
              src="images/SubmissionSearchPage/error-modal-close-icon.png"
            />
            <Grid
              item
              xs={12}
              className="error-modal-icon-wrapper"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img
                className="error-modal-icon"
                alt="submission search page error modal icon"
                src="images/SubmissionSearchPage/error-modal-icon.png"
              />
            </Grid>
            <Grid
              item
              xs={12}
              className="error-modal-title-wrapper"
              display="flex"
              justifyContent="center"
            >
              <Typography className="error-modal-title">
                {errorModalTitle}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
        // onClick={handleBackdropClose}
      >
        <CircularProgress color="info" />
      </Backdrop>
    </React.Fragment>
  );
}
