import { Grid, Paper, Typography } from "@mui/material";
import "./SubmissionCard.css";
import React from 'react'

export default function SubmissionCard(props) {
    const data = props.cardData
    const titleDisplay = (data) => {
        var title = []
        data && title.push(data.candidateName)
        data && title.push(data.candidateSchool)
        data && data.user && data.user.district && title.push(data.user.district.name)
        data && data.user && data.user.city && title.push(data.user.city.name)
        return title.join(' - ')
    }

    return (
        <Paper className="submission-card-wrapper" >
            <Grid container spacing={0}>
                <Grid item xs={12} display="flex" justifyContent="center" sx={{ cursor: 'pointer' }}>
                    <img className="submission-card-img" alt="submission test" src={data ? (process.env.REACT_APP_SERVER_ROOT_MEDIA_URL + data.contestEntriesImages[0].image.path) : ''} id="submission-card-img" />
                </Grid>
                <Grid item xs={12} className="submission-card-title-wrapper">
                    <Typography className="submission-card-title">
                        {titleDisplay(data)}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}
// data.image.path
// candidateName
// subject Tiêu đề
// content
// candidateSchool
// district
// city