import { Grid, Paper, Typography } from "@mui/material";
import "./PoemExamTopicCard.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import React from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import IconButton from "@mui/material/IconButton";

export default function PoemExamTopicCard(props) {
  const examData = props.examData;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Paper className="poem-exam-topic-card-wrapper">
        <Grid container justifyContent="center" spacing={0}>
          <Grid item xs={12} display="flex" justifyContent="center">
            <img
              onClick={handleClickOpen}
              className="poem-exam-topic-image"
              alt="poem exam topic"
              src={
                process.env.REACT_APP_SERVER_ROOT_MEDIA_URL +
                examData.image.path
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            className="poem-exam-topic-title-wrapper"
          >
            <Typography className="poem-exam-topic-title">
              {examData ? examData.content : ""}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Dialog
        id="exam-topic-dialog"
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        style={{ borderRadius: "0px !important" }}
      >
        <DialogTitle className="exam-topic-poem-modal-title">
          {examData ? examData.content : ""}
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            display="flex"
            justifyContent="center"
            className="modal-image-wrapper"
          >
            <TransformWrapper
              initialScale={1}
              minScale={1}
              maxScale={fullScreen ? 6 : 5}
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <React.Fragment>
                  <div className="tools-wrapper">
                    <IconButton onClick={() => zoomIn()}>
                      <AddCircleIcon />
                    </IconButton>
                    <IconButton onClick={() => zoomOut()}>
                      <RemoveCircleIcon />
                    </IconButton>
                    <IconButton onClick={() => resetTransform()}>
                      <RestartAltIcon />
                    </IconButton>
                  </div>
                  <TransformComponent>
                    <img
                      style={{ height: "100%", maxWidth: "100%" }}
                      alt="poem exam topic"
                      src={
                        process.env.REACT_APP_SERVER_ROOT_MEDIA_URL +
                        examData.image.path
                      }
                    />
                  </TransformComponent>
                </React.Fragment>
              )}
            </TransformWrapper>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            className="exam-topic-poem-modal-close-button"
            autoFocus
            onClick={handleClose}
          >
            Đóng
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
