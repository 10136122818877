import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import './HomePageSlider.css';
import { Typography } from '@mui/material';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {
    useNavigate
} from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

export default function HomePageSlider() {
    const isMobile = useMediaQuery({ query: `(max-width: 768px)` })
    const navigate = useNavigate()
    const toSubmitPage = () => {
        navigate('/submit-exam')
    }
    const toInformationPage = () => {
        navigate('/information')
    }
    const onNextArrowClick = () => {
        let parent = document.querySelector('.slider-outside-wrapper')
        let arrow = parent.querySelector('.control-next')
        arrow.click()
    }

    const onPrevArrowClick = () => {
        let parent = document.querySelector('.slider-outside-wrapper')
        let arrow = parent.querySelector('.control-prev')
        arrow.click()
    }

    return (
        <Grid data-aos={isMobile ? "fade-in" : "fade-left"} container className="slider-outside-wrapper" style={isMobile ? ({ backgroundImage: 'url("images/homepage-slider-mobile-background.png")' }) : null}>
            {isMobile && (
                <img className="homepage-banner-background-icon" alt="homepage banner background icon" src="/images/homepage-banner-background-icon-mobile.png" />
            )}
            {
                isMobile ?
                    (
                        <div className="slide-wrapper">
                            <Grid item md={6} xs={12} className="slider-info-board-wrapper">
                                <Paper className="slider-info-board">
                                    <div className="slider-info-board-content-container">
                                        <Typography variant="h6" className="info-board-title">
                                            Sân chơi: <br />
                                            <span className="slider-info-board-contest-name">Bảo vệ môi trường</span>
                                        </Typography>
                                        <Typography variant="body1" className="info-board-text">
                                            Nằm trong khuôn khổ chương trình "Thiếu nhi Việt Nam - Học tập tốt, rèn luyện chăm", Hội đồng Đội Trung ương phối hợp với Công ty cổ phần Sữa quốc tế (IDP) - Thương hiện KUN tổ chức sân chơi "Cùng KUN bảo vệ môi trường". Với chủ đề "Cùng KUN bảo vệ môi trường" hy vọng đem lại sân chơi bổ ích cho các bạn thí sinh, đồng thời qua đó các bạn nhỏ có thể chia sẻ, lan tỏa tinh thần bảo vệ môi trường, làm việc tốt mỗi ngày đến bạn bè và mọi người xung quanh.
                                        </Typography>
                                        <Typography variant="body1" className="info-board-text" style={{ textAlign: 'center' }}>
                                            {/* <span className="slider-info-board-contest-name" style={{ fontWeight: 'bold' }}>Chương trình đã kết thúc</span> */}
                                        </Typography>
                                    </div >
                                    {/* <Grid container spacing={2} justifyContent="center" className="info-board-button-wrapper">
                                        <Grid item md={6} xs={6} className="btn-wrapper" display="flex" justifyContent="flex-end">
                                            <button className="info-board-submit-button" onClick={toSubmitPage}>Nộp bài</button>
                                        </Grid>
                                        <Grid item md={6} xs={6} className="btn-wrapper" display="flex" justifyContent="flex-start">
                                            <button className="info-board-rules-button" onClick={toInformationPage}>Thể lệ</button>
                                        </Grid>
                                    </Grid> */}
                                    <img className="info-board-character-img-pencil" alt="Logo cay but" src="images/info-board-character-img-pencil.png" />
                                    <img className="info-board-character-img-pencase" alt="Logo hop but" src="images/info-board-character-img-pencase.png" />
                                    <img className="info-board-character-img-cow" alt="Logo con bo" src="images/homepage-slider-cow-icon.png" />
                                    <img className="info-board-character-img-boy" alt="Logo chop chop" src="images/homepage-slider-boy-icon.png" />
                                    <img className="info-board-character-img-cert" alt="Logo cert" src="images/homepage-slider-cert-icon.png" />
                                </Paper >
                            </Grid >
                        </div >
                    ) :
                    (
                        <React.Fragment>
                            <Carousel
                                infiniteLoop={true}
                                showStatus={false}
                                showIndicators={false}
                                showThumbs={false}
                            >
                                <div className="slide-wrapper">
                                    <Grid item md={8} xs={12}>
                                        <Paper className="slider-info-board">
                                            <div className="slider-info-board-content-container">
                                                <Typography variant="h6" className="info-board-title">
                                                    Sân chơi: <br />
                                                    <span className="slider-info-board-contest-name">Bảo vệ môi trường</span>
                                                </Typography>
                                                <Typography variant="body1" className="info-board-text">
                                                    Nằm trong khuôn khổ chương trình "Thiếu nhi Việt Nam - Học tập tốt, rèn luyện chăm", Hội đồng Đội Trung ương phối hợp với Công ty cổ phần Sữa quốc tế (IDP) - Thương hiện KUN tổ chức sân chơi "Cùng KUN bảo vệ môi trường". Với chủ đề "Cùng KUN bảo vệ môi trường" hy vọng đem lại sân chơi bổ ích cho các bạn thí sinh, đồng thời qua đó các bạn nhỏ có thể chia sẻ, lan tỏa tinh thần bảo vệ môi trường, làm việc tốt mỗi ngày đến bạn bè và mọi người xung quanh.
                                                </Typography>
                                                <Typography variant="body1" className="info-board-text" style={{ textAlign: 'center' }}>
                                                    {/* <span className="slider-info-board-contest-name" style={{ fontWeight: 'bold' }}>Chương trình đã kết thúc</span> */}
                                                </Typography>
                                            </div >
                                            {/* <Grid container spacing={4} justifyContent="center" className="info-board-button-wrapper">
                                                <Grid item md={6} sm={12} className="btn-wrapper" display="flex" justifyContent="flex-end">
                                                    <button className="info-board-submit-button" onClick={toSubmitPage}>Nộp bài</button>
                                                </Grid>
                                                <Grid item md={6} sm={12} className="btn-wrapper" display="flex" justifyContent="flex-start">
                                                    <button className="info-board-rules-button" onClick={toInformationPage}>Thể lệ</button>
                                                </Grid>
                                            </Grid> */}
                                            <img className="info-board-character-img-pencil" alt="Logo cay but" src="images/info-board-character-img-pencil.png" />
                                            <img className="info-board-character-img-pencase" alt="Logo hop but" src="images/info-board-character-img-pencase.png" />
                                            <img className="info-board-character-img-cow" alt="Logo con bo" src="images/homepage-slider-cow-icon.png" />
                                            <img className="info-board-character-img-boy" alt="Logo chop chop" src="images/homepage-slider-boy-icon.png" />
                                            <img className="info-board-character-img-cert" alt="Logo cert" src="images/homepage-slider-cert-icon.png" />
                                        </Paper >
                                    </Grid >
                                </div >
                                <div className="slide-wrapper">
                                    <Grid item md={8} xs={12}>
                                        <Paper className="slider-info-board">
                                            <div className="slider-info-board-content-container">
                                                <Typography variant="h6" className="info-board-title">
                                                    Sân chơi: <br />
                                                    <span className="slider-info-board-contest-name">Bảo vệ môi trường"</span>
                                                </Typography>
                                                <Typography variant="body1" className="info-board-text">
                                                    Nằm trong khuôn khổ chương trình "Thiếu nhi Việt Nam - Học tập tốt, rèn luyện chăm", Hội đồng Đội Trung ương phối hợp với Công ty cổ phần Sữa quốc tế (IDP) - Thương hiện KUN tổ chức sân chơi "Cùng KUN bảo vệ môi trường". Với chủ đề "Cùng KUN bảo vệ môi trường" hy vọng đem lại sân chơi bổ ích cho các bạn thí sinh, đồng thời qua đó các bạn nhỏ có thể chia sẻ, lan tỏa tinh thần bảo vệ môi trường, làm việc tốt mỗi ngày đến bạn bè và mọi người xung quanh.
                                                </Typography>
                                                <Typography variant="body1" className="info-board-text" style={{ textAlign: 'center' }}>
                                                    {/* <span className="slider-info-board-contest-name" style={{ fontWeight: 'bold' }}>Chương trình đã kết thúc</span> */}
                                                </Typography>
                                            </div>
                                            {/* <Grid container spacing={4} justifyContent="center" className="info-board-button-wrapper">
                                                <Grid item md={6} sm={12} className="btn-wrapper" display="flex" justifyContent="flex-end">
                                                    <button className="info-board-submit-button" onClick={toSubmitPage}>Nộp bài</button>
                                                </Grid>
                                                <Grid item md={6} sm={12} className="btn-wrapper" display="flex" justifyContent="flex-start">
                                                    <button className="info-board-rules-button" onClick={toInformationPage}>Thể lệ</button>
                                                </Grid>
                                            </Grid> */}
                                            <img className="info-board-character-img-pencil" alt="Logo cay but" src="images/info-board-character-img-pencil.png" />
                                            <img className="info-board-character-img-pencase" alt="Logo hop but" src="images/info-board-character-img-pencase.png" />
                                            <img className="info-board-character-img-cow" alt="Logo con bo" src="images/homepage-slider-cow-icon.png" />
                                            <img className="info-board-character-img-boy" alt="Logo chop chop" src="images/homepage-slider-boy-icon.png" />
                                            <img className="info-board-character-img-cert" alt="Logo cert" src="images/homepage-slider-cert-icon.png" />
                                        </Paper>
                                    </Grid>
                                </div>
                            </Carousel >
                            <img onClick={onNextArrowClick} className="homepage-slider-icon-next" alt="icon button next" src="images/homepage-slider-icon-next.png" />
                            <img onClick={onPrevArrowClick} className="homepage-slider-icon-back" alt="icon button back" src="images/homepage-slider-icon-back.png" />
                        </React.Fragment>
                    )
            }
        </Grid >
    )
}