import React from "react";
import ReactPlayer from "react-player/youtube";
import { Grid, Typography } from "@mui/material";
import "./ExamPageFeelingTopic.css";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function ExamPageFeelingTopic() {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <Grid
      container
      justifyContent="center"
      className="exam-feeling-topic-wrapper"
      spacing={6}
    >
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid
            item
            xs={isMobile ? 11 : 6}
            className="feeling-topic-title-wrapper"
            style={{
              backgroundImage:
                'url("images/SubmissionSearchPage/search-box-title-background.png")',
            }}
          >
            <Typography className="feeling-topic-title">
              Viết cảm nhận về clip
            </Typography>
          </Grid>
          <Grid item xs={isMobile ? 11 : 12}>
            <Typography className="feeling-topic-sub-title">
              Xem clip sau và viết cảm nhận.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={isMobile ? 11 : 6}>
        <video
          width="100%"
          src="videos/Kun_intro_final_in-out_sub_2.mp4"
          type="video/mp4"
          controls
        ></video>
        {/* <ReactPlayer
                    url='https://www.youtube.com/watch?v=Pzc0fWX3e3Y'
                    width={'100%'}
                    controls={true}
                    style={{
                        borderRadius: '19px'
                    }}
                /> */}
      </Grid>
    </Grid>
  );
}
