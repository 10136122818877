import React, { useEffect } from "react";
import SubmissionSearchPageHeader from "../components/SubmissionSearchPage/SubmissionSearchPageHeader";
import ListSubmissionPoemWriting from "../components/SubmissionSearchPage/ListSubmissionPoemWriting";
import ListSubmissionFeelingWriting from "../components/SubmissionSearchPage/ListSubmissionFeelingWriting";
import ListSubmissionPainting from "../components/SubmissionSearchPage/ListSubmissionPainting";
import ListSubmissionGoodWork from "../components/SubmissionSearchPage/ListSubmissionGoodWork";
import SearchResultSubmission from "../components/SubmissionSearchPage/SearchResultSubmission";
import Footer from "../components/Footer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CardMedia, Grid } from "@mui/material";

export default function SubmissionSearchPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isMobile = useMediaQuery("(max-width:768px)");
  const [isSearched, setIsSearched] = React.useState(false);

  const updateIsSearched = (updateData) => {
    setIsSearched(updateData);
  };

  const [searchType, setSearchType] = React.useState("guest-profile");

  const updateSearchType = (updateData) => {
    setSearchType(updateData);
  };

  const [searchResultData, setSearchResultData] = React.useState({});

  const getSearchResultData = (data) => {
    setSearchResultData(data);
  };
  return (
    <div
      className="submission-search-page-wrapper"
      style={{ backgroundImage: `url("/images/ExamPage/body-background.png")` }}
    >
      <SubmissionSearchPageHeader
        isSearched={updateIsSearched}
        searchType={updateSearchType}
        searchResultData={getSearchResultData}
      />
      {!isSearched ? (
        <React.Fragment>
          {/* <div
            style={{
              marginTop: "80px",
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <ListSubmissionPoemWriting />
          </div>
          <div
            style={{
              marginTop: "80px",
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ListSubmissionFeelingWriting />
          </div>
          <div
            style={{
              marginTop: "80px",
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <ListSubmissionGoodWork />
          </div> */}
          <div
            style={{
              marginTop: "80px",
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <ListSubmissionPainting />
          </div>
        </React.Fragment>
      ) : (
        <div
          style={{
            position: "relative",
            marginTop: `${
              searchType === "guest-profile" && !isMobile
                ? "100px"
                : searchType === "guest-code" && !isMobile
                ? "80px"
                : "40px"
            }`,
          }}
        >
          {searchResultData.length > 0
            ? searchResultData.map((contestEntry, index) => (
                <SearchResultSubmission
                  key={index}
                  searchResultData={contestEntry}
                />
              ))
            : ""}
        </div>
      )}
      <div style={{ marginTop: "40px", position: "relative" }}>
        {isMobile ? (
          <Grid
            container
            justifyContent="center"
            sx={{ position: "relative", paddingBottom: 10, mt: 5 }}
          >
            <CardMedia
              component="img"
              image="/images/footer-bg-mobile.png"
              sx={{ mt: "220px", position: "absolute", bottom: 0 }}
            ></CardMedia>
            <Footer />
          </Grid>
        ) : (
          <div>
            <CardMedia
              component="img"
              image="/images/footer-bg.png"
              sx={{ position: "relative" }}
            ></CardMedia>
            <Grid
              container
              justifyContent="center"
              sx={{ position: "absolute", bottom: "100px" }}
            >
              <Footer />
            </Grid>
          </div>
        )}
      </div>
    </div>
  );
}
