import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import GroupIcon from "@mui/icons-material/Group";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import ArticleIcon from "@mui/icons-material/Article";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import useMediaQuery from "@mui/material/useMediaQuery";
import UsersManagement from "../../components/Admin/UsersManagement";
import ContestEntriesManagement from "../../components/Admin/ContestEntriesManagement";
import { useNavigate } from "react-router-dom";
import ResultsManagement from "../../components/Admin/ResultsManagement";
import userStore from "../../stores/user";
import { useEffect } from "react";
import axios from "axios";
import JudgesManagement from "../../components/Admin/JudgesManagement";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Logout from "@mui/icons-material/Logout";
import Avatar from "@mui/material/Avatar";
import SuccessSnackbar from "../../components/Admin/Common/SuccessSnackbar";
import DateTimeConvert from "../../utils/DateTimeConvert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@material-ui/core/styles";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LockResetIcon from "@mui/icons-material/LockReset";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const drawerWidth = 240;

const useStyles = makeStyles({
  root: {
    marginTop: "10px",
    "& .MuiOutlinedInput-input": {
      color: "rgba(0, 0, 0, 0.87)",
      fontFamily: "Quicksand",
      fontSize: "16px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.5) !important",
    },
  },
});

function ResponsiveDrawer(props) {
  const classes = useStyles();
  const { logout, accessToken, userId, username, userRoleName } = userStore(
    (state) => state
  );
  const isMobile = useMediaQuery("(max-width:768px)");
  const navigate = useNavigate();
  const { window, itemNumber } = props;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [item, setItem] = React.useState(itemNumber);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const accountExpand = Boolean(anchorEl);
  const handleAccoundExpand = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAccountExpandClose = () => {
    setAnchorEl(null);
  };

  const [contestEntriesManagementExpand, setContestEntriesManagementExpand] =
    React.useState(false);

  const handleContestEntriesManagementExpand = () => {
    setContestEntriesManagementExpand(!contestEntriesManagementExpand);
  };

  const [resultsManagementExpand, setResultsManagementExpand] =
    React.useState(false);

  const handleResultsManagementExpand = () => {
    setResultsManagementExpand(!resultsManagementExpand);
  };

  React.useEffect(() => {
    if (item && item > 3 && item < 4) {
      setContestEntriesManagementExpand(true);
    } else if (item && item > 4 && item < 5) {
      setResultsManagementExpand(true);
    }
  }, [item]);

  const itemsDisplay = (key) => {
    switch (key) {
      case 1:
        return <JudgesManagement accessToken={accessToken} />;
      case 2:
        return <UsersManagement accessToken={accessToken} />;
      case 3.1:
        return <ContestEntriesManagement type={"ungraded"} />;
      case 3.2:
        return <ContestEntriesManagement type={"round-1"} />;
      case 3.3:
        return <ContestEntriesManagement type={"round-week"} />;
      case 3.4:
        return <ContestEntriesManagement type={"round-final"} />;
      case 3.5:
        return <ContestEntriesManagement type={"fail"} />;
      case 3.6:
        return <ContestEntriesManagement type={"all"} />;
      case 4.1:
        return <ResultsManagement type={"round-week"} />;
      case 4.2:
        return <ResultsManagement type={"round-final"} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_SERVER_API_ROOT_URL + "/users/get/me", {
        headers: { Authorization: `${accessToken}` },
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
        logout();
        navigate("/admin/login");
      });

    if (accessToken) {
      axios
        .get(process.env.REACT_APP_SERVER_API_ROOT_URL + `/users/${userId}`, {
          headers: { Authorization: `${accessToken}` },
        })
        .then((res) => {
          if (res.data) {
            setUserDetailInfo((prevState) => ({
              ...prevState,
              id: res.data.id,
              username: res.data.username,
              name: res.data.name,
              gender: res.data.gender,
              phone: res.data.phone,
              email: res.data.email,
              dob: res.data.dob
                ? DateTimeConvert.yyyymmddConvert(res.data.dob)
                : "",
              address: res.data.address,
              role: {
                ...prevState.role,
                id: res.data.role.id,
                name: res.data.role.name,
              },
              district: {
                ...prevState.district,
                id: res.data.district ? res.data.district.id : "",
                name: res.data.district ? res.data.district.name : "",
              },
              city: {
                ...prevState.city,
                id: res.data.city ? res.data.city.id : "",
                name: res.data.city ? res.data.city.name : "",
              },
            }));
          }
        });
    }
  }, []);

  // begin update user info

  const [userDetailInfo, setUserDetailInfo] = React.useState({
    id: "",
    username: "",
    name: "",
    gender: "",
    phone: "",
    email: "",
    dob: "",
    address: "",
    role: {
      id: "",
      name: "",
    },
    district: {
      id: "",
      name: "",
    },
    city: {
      id: "",
      name: "",
    },
  });

  const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState({
    isOpen: false,
    content: "",
  });

  const [updateUserModalOpen, setUpdateUserModalOpen] = React.useState(false);

  const handleOpenUpdateUserModal = () => {
    setUpdateUserModalOpen(true);
    handleUpdateUser();
  };

  const handleCloseUpdateUserModal = () => {
    setUpdateUserModalOpen(false);
  };

  const [Cities, setCities] = React.useState([]);
  const [Districts, setDistricts] = React.useState([]);

  const getDistrictsByCity = (cityId) => {
    setDistricts([]);
    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/districts/find-by-city/${cityId}`
      )
      .then((respone) => {
        if (respone.data.rows) {
          setDistricts(respone.data.rows);
        }
      })
      .catch((error) => console.log(error));
  };

  const [userUpdateFormData, setUserUpdateFormData] = React.useState({
    name: "",
    gender: "",
    email: "",
    dob: "",
    address: "",
    districtId: "",
    cityId: "",
  });

  const handleUpdateFormNameChange = (e) => {
    setUserUpdateFormData((prevState) => ({
      ...prevState,
      name: e.target.value,
    }));
  };

  const handleUpdateFormGenderChange = (e) => {
    setUserUpdateFormData((prevState) => ({
      ...prevState,
      gender: e.target.value,
    }));
  };

  const handleUpdateFormEmailChange = (e) => {
    setUserUpdateFormData((prevState) => ({
      ...prevState,
      email: e.target.value,
    }));
  };

  const handleUpdateFormDobChange = (e) => {
    setUserUpdateFormData((prevState) => ({
      ...prevState,
      dob: e.target.value,
    }));
  };

  const handleUpdateFormAddressChange = (e) => {
    setUserUpdateFormData((prevState) => ({
      ...prevState,
      address: e.target.value,
    }));
  };

  const handleUpdateFormCityChange = (e) => {
    setUserUpdateFormData((prevState) => ({
      ...prevState,
      cityId: e.target.value,
    }));

    getDistrictsByCity(e.target.value);
  };

  const handleUpdateFormDistrictChange = (e) => {
    setUserUpdateFormData((prevState) => ({
      ...prevState,
      districtId: e.target.value,
    }));
  };

  const handleUpdateUser = () => {
    setCities([]);
    axios
      .get(process.env.REACT_APP_SERVER_API_ROOT_URL + `/cities`)
      .then((res) => {
        if (res.data) {
          setCities(res.data.rows);
        }
      });

    setUserUpdateFormData((prevState) => ({
      ...prevState,
      name: userDetailInfo.name,
      gender: userDetailInfo.gender,
      email: userDetailInfo.email,
      dob: DateTimeConvert.ddmmyyyyConvert(userDetailInfo.dob),
      address: userDetailInfo.address,
      cityId: userDetailInfo.city.id,
    }));
    getDistrictsByCity(userDetailInfo.city.id);
    setUserUpdateFormData((prevState) => ({
      ...prevState,
      districtId: userDetailInfo.district.id,
    }));
  };

  const handleRefreshUserData = () => {
    if (accessToken) {
      axios
        .get(process.env.REACT_APP_SERVER_API_ROOT_URL + `/users/${userId}`, {
          headers: { Authorization: `${accessToken}` },
        })
        .then((res) => {
          if (res.data) {
            setUserDetailInfo((prevState) => ({
              ...prevState,
              id: res.data.id,
              username: res.data.username,
              name: res.data.name,
              gender: res.data.gender,
              phone: res.data.phone,
              email: res.data.email,
              dob: res.data.dob
                ? DateTimeConvert.yyyymmddConvert(res.data.dob)
                : "",
              address: res.data.address,
              role: {
                ...prevState.role,
                id: res.data.role.id,
                name: res.data.role.name,
              },
              district: {
                ...prevState.district,
                id: res.data.district ? res.data.district.id : "",
                name: res.data.district ? res.data.district.name : "",
              },
              city: {
                ...prevState.city,
                id: res.data.city ? res.data.city.id : "",
                name: res.data.city ? res.data.city.name : "",
              },
            }));
          }
        });
    }
  };
  const handleUserUpdateFormSubmit = () => {
    axios
      .put(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/users/?id=${userDetailInfo.id}`,
        userUpdateFormData,
        {
          headers: { Authorization: `${accessToken}` },
        }
      )
      .then((res) => {
        if (res.data) {
          handleCloseUpdateUserModal();
          setUserUpdateFormData((prevState) => ({
            ...prevState,
            name: "",
            gender: "",
            email: "",
            dob: "",
            address: "",
            districtId: "",
            cityId: "",
          }));
          handleRefreshUserData();
          setSuccessSnackbarOpen((prevState) => ({
            ...prevState,
            isOpen: true,
            content: "Cập nhật thông tin thành công !",
          }));
        }
      });
  };
  // end update user info

  // begin user change password
  const [userChangePasswordModalOpen, setUserChangePasswordModalOpen] =
    React.useState(false);

  const handleUserChangePasswordModalOpen = () => {
    setUserChangePasswordModalOpen(true);
  };

  const handleUserChangePasswordModalClose = () => {
    setUserChangePasswordModalOpen(false);
  };

  const [resetPasswordForm, setResetPasswordForm] = React.useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleChangeOldPassword = (e) => {
    setResetPasswordForm((prevState) => ({
      ...prevState,
      oldPassword: e.target.value,
    }));
    setResetPasswordError("");
  };

  const handleChangeNewPassword = (e) => {
    setResetPasswordForm((prevState) => ({
      ...prevState,
      newPassword: e.target.value,
    }));
    setResetPasswordError("");
  };

  const handleChangeConfirmPassword = (e) => {
    setResetPasswordForm((prevState) => ({
      ...prevState,
      confirmPassword: e.target.value,
    }));
    setResetPasswordError("");
  };

  const [resetPasswordError, setResetPasswordError] = React.useState("");

  const handleUserChangePasswordSubmit = () => {
    setResetPasswordError("");
    if (
      !resetPasswordForm.oldPassword ||
      !resetPasswordForm.newPassword ||
      !resetPasswordForm.confirmPassword
    ) {
      setResetPasswordError(
        "Bạn chưa điền đẩy đủ thông tin, hãy kiểm tra lại !"
      );
      return null;
    }
    if (resetPasswordForm.newPassword !== resetPasswordForm.confirmPassword) {
      setResetPasswordError(
        "Mật khẩu mới và mật khẩu xác nhận không khớp, hay kiểm tra lại!"
      );
      return null;
    }
    axios
      .put(
        process.env.REACT_APP_SERVER_API_ROOT_URL + `/users/change-password`,
        resetPasswordForm,
        {
          headers: { Authorization: `${accessToken}` },
        }
      )
      .then((res) => {
        if (res.data) {
          handleUserChangePasswordModalClose();
          setResetPasswordForm((prevState) => ({
            ...prevState,
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          }));
          setSuccessSnackbarOpen((prevState) => ({
            ...prevState,
            isOpen: true,
            content: "Đổi mật khẩu thành công !",
          }));
        }
      })
      .catch((error) => {
        setResetPasswordError(
          "Thông tin không chính xác, hãy kiểm tra lại mật khẩu hiện tại và mật khẩu mới !"
        );
      });
  };
  // end user change password
  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {userRoleName && userRoleName === "Admin" && (
          <React.Fragment>
            <ListItem
              button
              onClick={() => {
                setItem(1);
                navigate("/admin/dashboard/judges-management");
              }}
              style={
                item === 1 ? { backgroundColor: "rgba(0,0,0,0.04)" } : null
              }
            >
              <ListItemIcon>
                <SupervisedUserCircleIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    style={item === 1 ? { fontWeight: "bolder" } : null}
                  >
                    Quản lý giám khảo
                  </Typography>
                }
              />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                setItem(2);
                navigate("/admin/dashboard/users-management");
              }}
              style={
                item === 2 ? { backgroundColor: "rgba(0,0,0,0.04)" } : null
              }
            >
              <ListItemIcon>
                <GroupIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    style={item === 2 ? { fontWeight: "bolder" } : null}
                  >
                    Quản lý người dùng
                  </Typography>
                }
              />
            </ListItem>
          </React.Fragment>
        )}
        <ListItem
          button
          onClick={() => {
            handleContestEntriesManagementExpand();
          }}
        >
          <ListItemIcon>
            <ArticleIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Quản lý bài thi</Typography>}
          />
          {contestEntriesManagementExpand ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse
          in={contestEntriesManagementExpand}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <ListItem
              button
              onClick={() => {
                setItem(3.6);
                navigate("/admin/dashboard/contest-entries-all-management");
              }}
              sx={{ pl: 5 }}
              style={
                item === 3.6 ? { backgroundColor: "rgba(0,0,0,0.04)" } : null
              }
            >
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    style={item === 3.6 ? { fontWeight: "bolder" } : null}
                  >
                    Tất cả bài thi
                  </Typography>
                }
              />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                setItem(3.1);
                navigate(
                  "/admin/dashboard/contest-entries-ungraded-management"
                );
              }}
              sx={{ pl: 5 }}
              style={
                item === 3.1 ? { backgroundColor: "rgba(0,0,0,0.04)" } : null
              }
            >
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    style={item === 3.1 ? { fontWeight: "bolder" } : null}
                  >
                    Bài chưa được chấm
                  </Typography>
                }
              />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                setItem(3.2);
                navigate("/admin/dashboard/contest-entries-round-1-management");
              }}
              sx={{ pl: 5 }}
              style={
                item === 3.2 ? { backgroundColor: "rgba(0,0,0,0.04)" } : null
              }
            >
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    style={item === 3.2 ? { fontWeight: "bolder" } : null}
                  >
                    Bài đạt vòng 1
                  </Typography>
                }
              />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                setItem(3.3);
                navigate(
                  "/admin/dashboard/contest-entries-round-week-management"
                );
              }}
              sx={{ pl: 5 }}
              style={
                item === 3.3 ? { backgroundColor: "rgba(0,0,0,0.04)" } : null
              }
            >
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    style={item === 3.3 ? { fontWeight: "bolder" } : null}
                  >
                    Bài đạt giải tuần
                  </Typography>
                }
              />
            </ListItem>
            <ListItem
              onClick={() => {
                setItem(3.4);
                navigate(
                  "/admin/dashboard/contest-entries-round-final-management"
                );
              }}
              button
              sx={{ pl: 5 }}
              style={
                item === 3.4 ? { backgroundColor: "rgba(0,0,0,0.04)" } : null
              }
            >
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    style={item === 3.4 ? { fontWeight: "bolder" } : null}
                  >
                    Bài đạt giải chung kết
                  </Typography>
                }
              />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                setItem(3.5);
                navigate("/admin/dashboard/contest-entries-fail-management");
              }}
              sx={{ pl: 5 }}
              style={
                item === 3.5 ? { backgroundColor: "rgba(0,0,0,0.04)" } : null
              }
            >
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    style={item === 3.5 ? { fontWeight: "bolder" } : null}
                  >
                    Bài không đạt
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Collapse>
        <ListItem
          button
          onClick={() => {
            handleResultsManagementExpand();
          }}
        >
          <ListItemIcon>
            <EmojiEventsIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant="body1"
                style={item === 4 ? { fontWeight: "bolder" } : null}
              >
                Quản lý kết quả
              </Typography>
            }
          />
          {resultsManagementExpand ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={resultsManagementExpand} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              onClick={() => {
                setItem(4.1);
                navigate("/admin/dashboard/results-round-week-management");
              }}
              sx={{ pl: 5 }}
              style={
                item === 4.1 ? { backgroundColor: "rgba(0,0,0,0.04)" } : null
              }
            >
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    style={item === 4.1 ? { fontWeight: "bolder" } : null}
                  >
                    Bài đạt giải tuần
                  </Typography>
                }
              />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                setItem(4.2);
                navigate("/admin/dashboard/results-round-final-management");
              }}
              sx={{ pl: 5 }}
              style={
                item === 4.2 ? { backgroundColor: "rgba(0,0,0,0.04)" } : null
              }
            >
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    style={item === 4.2 ? { fontWeight: "bolder" } : null}
                  >
                    Bài đạt giải chung kết
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <React.Fragment>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Cùng Kun bảo vệ môi trường
            </Typography>
            <Typography
              variant="body1"
              noWrap
              component="div"
              style={{ marginLeft: "auto", marginRight: "0px" }}
            >
              {username}
            </Typography>
            <IconButton
              onClick={handleAccoundExpand}
              size="large"
              style={{ marginLeft: "0px", marginRight: "0px" }}
            >
              <AccountCircleIcon
                fontSize="large"
                style={{ color: "#FFFFFF" }}
              />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={accountExpand}
              onClose={handleAccountExpandClose}
              onClick={handleAccountExpandClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleOpenUpdateUserModal}>
                <ListItemIcon>
                  <AccountBoxIcon fontSize="small" />
                </ListItemIcon>
                Cập nhật thông tin cá nhân
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleUserChangePasswordModalOpen}>
                <ListItemIcon>
                  <LockResetIcon fontSize="small" />
                </ListItemIcon>
                Đổi mật khẩu
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  logout();
                  navigate("/admin");
                }}
              >
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Đăng xuất
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Toolbar />
          {itemsDisplay(item)}
        </Box>
      </Box>
      <SuccessSnackbar
        isOpen={successSnackbarOpen.isOpen}
        content={successSnackbarOpen.content}
        onClose={() => {
          setSuccessSnackbarOpen((prevState) => ({
            ...prevState,
            isOpen: false,
            content: "",
          }));
        }}
      />
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={updateUserModalOpen}
        onClose={handleCloseUpdateUserModal}
      >
        <DialogContent>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                Họ và tên:
              </Typography>
              <TextField
                value={userUpdateFormData.name}
                onChange={handleUpdateFormNameChange}
                className={classes.root}
                size="small"
                color="primary"
                type="text"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                Giới tính:
              </Typography>
              <FormControl className={classes.root} fullWidth size="small">
                <Select
                  value={userUpdateFormData.gender}
                  onChange={handleUpdateFormGenderChange}
                >
                  <MenuItem value="male">Nam</MenuItem>
                  <MenuItem value="female">Nữ</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                Email:
              </Typography>
              <TextField
                value={userUpdateFormData.email}
                onChange={handleUpdateFormEmailChange}
                className={classes.root}
                size="small"
                color="primary"
                type="email"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                Ngày sinh:
              </Typography>
              <TextField
                value={userUpdateFormData.dob}
                onChange={handleUpdateFormDobChange}
                className={classes.root}
                size="small"
                color="primary"
                type="date"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                Địa chỉ:
              </Typography>
              <TextField
                value={userUpdateFormData.address}
                onChange={handleUpdateFormAddressChange}
                className={classes.root}
                size="small"
                color="primary"
                type="text"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                Tình/Thành phố:
              </Typography>
              <FormControl className={classes.root} fullWidth size="small">
                <Select
                  value={userUpdateFormData.cityId}
                  onChange={handleUpdateFormCityChange}
                >
                  {Cities?.length > 0 &&
                    Cities.map((city) => {
                      return (
                        <MenuItem key={city.id} value={city.id}>
                          {city.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                Quận/Huyện:
              </Typography>
              <FormControl className={classes.root} fullWidth size="small">
                <Select
                  value={userUpdateFormData.districtId}
                  onChange={handleUpdateFormDistrictChange}
                >
                  {Districts?.length > 0 &&
                    Districts.map((district) => {
                      return (
                        <MenuItem key={district.id} value={district.id}>
                          {district.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: "15px" }}>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            onClick={handleUserUpdateFormSubmit}
          >
            Xác nhận
          </Button>
          <Button
            variant="contained"
            color="semiBlack"
            size="medium"
            onClick={handleCloseUpdateUserModal}
          >
            Đóng
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={userChangePasswordModalOpen}
        onClose={handleUserChangePasswordModalClose}
      >
        <DialogContent>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={12}>
              <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                Mật khẩu hiện tại:
              </Typography>
              <TextField
                value={resetPasswordForm.oldPassword}
                onChange={handleChangeOldPassword}
                className={classes.root}
                size="small"
                color="primary"
                type="password"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                Mật khẩu Mới:
              </Typography>
              <TextField
                value={resetPasswordForm.newPassword}
                onChange={handleChangeNewPassword}
                className={classes.root}
                size="small"
                color="primary"
                type="password"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                Nhập lại mật khẩu mới:
              </Typography>
              <TextField
                value={resetPasswordForm.confirmPassword}
                onChange={handleChangeConfirmPassword}
                className={classes.root}
                size="small"
                color="primary"
                type="password"
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: "15px" }}>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={12}>
              {resetPasswordError && (
                <Alert
                  severity="error"
                  style={{ fontSize: `${isMobile ? "13px" : "16px"}` }}
                >
                  <AlertTitle
                    style={{ fontSize: `${isMobile ? "13px" : "16px"}` }}
                  >
                    <strong>{resetPasswordError}</strong>
                  </AlertTitle>
                </Alert>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={handleUserChangePasswordSubmit}
                  >
                    Xác nhận
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="semiBlack"
                    size="medium"
                    onClick={handleUserChangePasswordModalClose}
                  >
                    Đóng
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;
