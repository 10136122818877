import { Grid, Paper, Typography } from "@mui/material";
import "./HomePageProgramSchedule.css";
import ExamFormatCard from "../../ExamFormatCard";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function HomePageProgramSchedule() {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <Grid
      className="homepage-programschedule-wrapper"
      container
      justifyContent="center"
      spacing={0}
    >
      <img
        data-aos="zoom-in"
        data-aos-duration="1200"
        className="homepage-programschedule-chop-chop"
        alt="programschedule chop chop"
        src="images/homepage-program-schedules-chopchop.png"
      />
      <img
        className="homepage-exam-format-background-1"
        alt="exam format background 1"
        src="images/homepage-exam-format-background-1.png"
      />
      <img
        className="homepage-exam-format-background-2"
        alt="exam format background 2"
        src="images/homepage-exam-format-background-2.png"
      />
      {!isMobile ? (
        <img
          data-aos="zoom-up"
          data-aos-duration="1200"
          className="homepage-programschedule-cloud"
          alt="programschedule cloud"
          src="images/homepage-program-schedules-cloud.png"
        />
      ) : null}
      {isMobile ? (
        <img
          data-aos="zoom-up"
          data-aos-duration="1200"
          className="homepage-programschedule-cloud"
          alt="programschedule cloud"
          src="images/homepage-program-schedules-cloud-mobile.png"
        />
      ) : null}
      <img
        data-aos="zoom-in"
        data-aos-duration="1200"
        className="homepage-programschedule-mama"
        alt="programschedule mama"
        src="images/homepage-program-schedules-mama.png"
      />
      <Grid item xs={12} className="programschedule-content-wrapper">
        <Grid container justifyContent="center" spacing={0}>
          <Grid
            data-aos="fade-down"
            data-aos-duration="1500"
            item
            md={6}
            xs={10}
            className="programschedule-title-wrapper"
            style={{
              backgroundImage:
                'url("images/SubmissionSearchPage/search-box-title-background.png")',
            }}
          >
            <Typography className="programschedule-title">
              Kế hoạch chương trình
            </Typography>
          </Grid>
          <Grid
            data-aos="fade-up"
            data-aos-duration="1500"
            item
            xs={10}
            className="programschedule-box-content-wrapper"
          >
            <Grid container spacing={0} className="programschedule-box-wrapper">
              <Grid item md={2} xs={12} className="programschedules-box-1">
                <div>
                  <Typography className="programschedules-box-time">
                    15/2/2023
                  </Typography>
                  <Typography className="programschedules-box-text">
                    Công bố phát động sân chơi
                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                xs={isMobile ? 12 : 0}
                className="programschedules-connecter-wrapper"
              >
                <span className="programschedules-connecter"></span>
              </Grid>
              <Grid item md={2} xs={12} className="programschedules-box-2">
                <div>
                  <Typography className="programschedules-box-time">
                    20/2/2023 - 30/4/2023
                  </Typography>
                  <Typography className="programschedules-box-text">
                    Bắt đầu nhận bài vòng sơ loại
                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                xs={isMobile ? 12 : 0}
                className="programschedules-connecter-wrapper"
              >
                <span className="programschedules-connecter"></span>
              </Grid>
              <Grid item md={2} xs={12} className="programschedules-box-1">
                <div>
                  <Typography className="programschedules-box-time">
                    15/5/2023 - 31/5/2023
                  </Typography>
                  <Typography className="programschedules-box-text">
                    Bắt đầu nhận bài vòng chung khảo
                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                xs={isMobile ? 12 : 0}
                className="programschedules-connecter-wrapper"
              >
                <span className="programschedules-connecter"></span>
              </Grid>
              <Grid item md={2} xs={12} className="programschedules-box-2">
                <div>
                  <Typography className="programschedules-box-time">
                    30/6/2023
                  </Typography>
                  <Typography className="programschedules-box-text">
                    Tổng kết và trao giải sân chơi
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="exam-format-wrapper">
        <Grid container justifyContent="center" spacing={!isMobile ? 6 : 0}>
          <Grid
            data-aos="fade-down"
            data-aos-duration="2000"
            item
            md={6}
            xs={10}
            className="exam-format-title-wrapper"
            style={{
              backgroundImage:
                'url("images/SubmissionSearchPage/search-box-title-background.png")',
            }}
          >
            <Typography className="exam-format-title">
              Hình thức tham gia
            </Typography>
          </Grid>
          <Grid
            data-aos="fade-up"
            data-aos-duration="2000"
            item
            xs={10}
            className="exam-format-content-wrapper"
          >
            <Grid container justifyContent="center" spacing={!isMobile ? 4 : 0}>
              {/* <Grid item md={4} xs={12} className="exam-format-card-wrapper">
                <ExamFormatCard
                  title={"Viết chữ đẹp"}
                  text={
                    "Hãy chọn một bài thơ hoặc bài hát mà em thích nhất trong các bài thơ và chép lại bài thơ đó (có danh mục các bài thơ kèm theo)"
                  }
                  imgUrl={"/images/ExamPage/viet-chu-dep-topic-banner.png"}
                />
              </Grid> */}
              {/* <Grid item md={4} xs={12} className="exam-format-card-wrapper">
                <ExamFormatCard
                  title={"Viết cảm nhận về clip"}
                  text={
                    "Viết cảm nhận của mình thông qua những dòng tâm sự, cảm xúc, suy nghĩ sau khi xem video clip “Cùng KUN làm việc tốt”"
                  }
                  imgUrl={"/images/ExamPage/viet-cam-nhan-topic-banner.jpg"}
                />
              </Grid> */}
              {/* <Grid item md={4} xs={12} className="exam-format-card-wrapper">
                <ExamFormatCard
                  title={"Viết cảm nhận"}
                  text={
                    "Hãy viết cảm nhận của mình thông qua những dòng tâm sự, cảm xúc, suy nghĩ về kỷ niệm đối với thầy, cô giáo và mái trường mến yêu, kèm theo những lời chúc ý nghĩa"
                  }
                  imgUrl={"/images/ExamPage/guong-nguoi-tot-topic-banner.png"}
                />
              </Grid> */}
              <Grid item md={4} xs={12} className="exam-format-card-wrapper">
                <ExamFormatCard
                  title={"Cùng Kun bảo vệ môi trường"}
                  text={
                    "Làm các mô hình, sản phẩm, vật dụng, đồ dùng gia đình, đồ chơi trẻ em, đồ phục vụ trang trí, phục vụ sản xuất, vật dụng trồng cây… từ những vật liệu tái chế đã qua sử dụng, thể hiện được tính sáng tạo, gắn với mục đích bảo vệ môi trường, có giá trị sử dụng hoặc phục vụ đời sống hàng ngày."
                  }
                  imgUrl={"/images/ExamPage/guong-nguoi-tot-topic-banner.png"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
