import React, { useEffect } from "react";
import ExamPageHeader from "../components/ExamPage/ExamPageHeader";
import ExamPagePoemTopic from "../components/ExamPage/ExamPagePoemTopic";
import ExamPageFeelingTopic from "../components/ExamPage/ExamPageFeelingTopic";
import Footer from "../components/Footer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CardMedia, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function ExamPage(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isMobile = useMediaQuery("(max-width:768px)");
  const navigate = useNavigate();

  const [topic, updateTopic] = React.useState("");

  React.useEffect(() => {
    if (props.type !== null) {
      updateTopic(props.type);
    }
  }, [props.type]);

  return (
    <div
      className="exam-page-wrapper"
      style={{
        backgroundImage: `url("/images/ExamPage/body-background.png")`,
        position: "relative",
      }}
    >
      <ExamPageHeader />
      <div style={{ position: "relative", top: "-350px", zIndex: "1" }}>
        {topic === "poem-writing" && <ExamPagePoemTopic />}
        {topic === "feeling-writing" && <ExamPageFeelingTopic />}
        <div className="exam-page-to-exam-submit-button-wrapper">
          <button
            onClick={() => {
              navigate("/submit-exam");
            }}
            className="to-exam-submit-button"
          >
            Nộp bài
          </button>
        </div>
      </div>
      {isMobile ? (
        <Grid
          container
          justifyContent="center"
          sx={{ position: "relative", paddingBottom: 10, mt: 5 }}
        >
          <CardMedia
            component="img"
            image="/images/footer-bg-mobile.png"
            sx={{ mt: "220px", position: "absolute", bottom: 0 }}
          ></CardMedia>
          <Footer />
        </Grid>
      ) : (
        <div>
          <CardMedia
            component="img"
            image="/images/footer-bg.png"
            sx={{ position: "relative" }}
          ></CardMedia>
          <Grid
            container
            justifyContent="center"
            sx={{ position: "absolute", bottom: "100px" }}
          >
            <Footer />
          </Grid>
        </div>
      )}
    </div>
  );
}
