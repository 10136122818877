import { Grid } from "@mui/material";
import React from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import GroupIcon from "@mui/icons-material/Group";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DataGrid, viVN } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import DateTimeConvert from "../../../utils/DateTimeConvert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Carousel } from "react-responsive-carousel";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const gender_vi = {
  male: "Nam",
  female: "Nữ",
};

const result_vi = {
  ACTIVE: "Chưa được chấm điểm",
  PASS: "Đạt",
  FAIL: "Không đạt",
};

export default function UsersManagement(props) {
  const accessToken = props.accessToken;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery("(max-width:420px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const [userList, setUserList] = React.useState([]);

  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const handleBackdropClose = () => {
    setBackdropOpen(false);
  };
  const handleBackdropOpen = () => {
    setBackdropOpen(true);
  };

  // begin get all submitted contest entries

  const [listContestEntries, setListContestEntries] = React.useState([]);

  const listContestEntriesColumns = [
    {
      field: "code",
      headerName: "Mã bài thi",
      headerClassName: "super-app-theme--header",
      width: 120,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: "",
      headerName: "Thao tác",
      headerClassName: "super-app-theme--header",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <Button
          fullWidth
          onClick={() => {
            getDetailContestEntry(params.row.id);
          }}
          variant="contained"
          color="info"
          size="small"
        >
          Xem chi tiết
        </Button>
      ),
    },
    {
      field: "candidateName",
      headerName: "Tên thí sinh",
      headerClassName: "super-app-theme--header",
      width: 250,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: "examType",
      headerName: "Hình thức",
      headerClassName: "super-app-theme--header",
      type: "text",
      width: 150,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {params.row.examType.name}
        </Typography>
      ),
    },
    {
      field: "createdAt",
      headerName: "Ngày tham gia",
      headerClassName: "super-app-theme--header",
      type: "text",
      width: 120,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {DateTimeConvert.yyyymmddConvert(params.value)}
        </Typography>
      ),
    },
    {
      field: "examRound",
      headerName: "Tuần tham gia",
      headerClassName: "super-app-theme--header",
      type: "text",
      width: 300,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {DateTimeConvert.roundResultDateDisplay(
            params.row.examRound.firstDoW
          )}
        </Typography>
      ),
    },
  ];

  const [contestEntryDetailInfo, setContestEntryDetailInfo] = React.useState({
    contestEntryId: "",
    contestEntriesImages: [],
    candidateName: "",
    candidateDob: "",
    userPhone: "",
    candidateSchool: "",
    address: "",
    district: "",
    city: "",
    examType: "",
    contestJoinDate: "",
    examRound: {
      id: "",
      firstDoW: "",
      firstDoM: "",
      isDone: "",
      roundSequenceId: "",
      roundSequenceSequence: 0,
    },
    subject: "",
    content: "",
    sense: "",
    views: "",
    status: "",
    examCode: "",
    roundSequence: {
      id: "",
      sequence: "",
      isEndWeekRound: "",
      isFinalRound: "",
      name: "",
    },
  });

  const [submissionCardModalOpen, updateSubmissionCardModalOpen] =
    React.useState(false);

  const handleSubmissionCardModalClose = () => {
    updateSubmissionCardModalOpen(false);
  };

  const onModalNextArrowClick = () => {
    let parent = document.querySelector(
      ".admin-detail-submission-card-modal-title-wrapper"
    );
    let arrow = parent.querySelector(".control-next");
    arrow.click();
  };

  const onModalPrevArrowClick = () => {
    let parent = document.querySelector(
      ".admin-detail-submission-card-modal-title-wrapper"
    );
    let arrow = parent.querySelector(".control-prev");
    arrow.click();
  };

  const getDetailContestEntry = (contestEntryId) => {
    handleBackdropOpen();
    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/contest-entry/${contestEntryId}`
      )
      .then((respone) => {
        if (respone.data) {
          updateSubmissionCardModalOpen(true);
          setContestEntryDetailInfo((prevState) => ({
            ...prevState,
            contestEntryId: respone.data.id,
            contestEntriesImages: respone.data.contestEntriesImages,
            candidateName: respone.data.candidateName,
            candidateDob: DateTimeConvert.yyyymmddConvert(
              respone.data.candidateDob
            ),
            userPhone: respone.data.user.phone,
            candidateSchool: respone.data.candidateSchool,
            address: respone.data.user.address,
            district: respone.data.user.district.name,
            city: respone.data.user.city.name,
            examType: respone.data.examType.name,
            contestJoinDate: DateTimeConvert.yyyymmddConvert(
              respone.data.createdAt
            ),
            examRound: {
              ...prevState.examRound,
              id: respone.data.examRound.id,
              firstDoW: DateTimeConvert.roundResultDateDisplay(
                respone.data.examRound.firstDoW
              ),
              firstDoM: respone.data.examRound.firstDoM,
              isDone: respone.data.examRound.isDone,
              roundSequenceId: respone.data.examRound.roundSequenceId,
              roundSequenceSequence:
                respone.data.examRound.roundSequence.sequence,
            },
            subject: respone.data.subject,
            content: respone.data.content,
            sense: respone.data.sense,
            views: respone.data.view,
            status: respone.data.status,
            examCode: respone.data.code,
            roundSequence: {
              ...prevState.roundSequence,
              id: respone.data.roundSequence?.id,
              sequence: respone.data.roundSequence
                ? respone.data.roundSequence.sequence
                : 0,
              isEndWeekRound: respone.data.roundSequence?.isEndWeekRound,
              isFinalRound: respone.data.roundSequence?.isFinalRound,
              name: respone.data.roundSequence?.name,
            },
          }));
          handleBackdropClose();
        }
      })
      .catch((error) => console.log(error));
  };

  React.useEffect(() => {
    if (contestEntryDetailInfo.contestEntryId) {
      getRoundSequenceInfoForGrading();
    }
  }, [contestEntryDetailInfo.contestEntryId]);

  const [currentRoundSequence, setCurrentRoundSequence] = React.useState({
    id: "",
    name: "",
    sequence: "",
  });

  const getRoundSequenceInfoForGrading = () => {
    axios
      .get(process.env.REACT_APP_SERVER_API_ROOT_URL + `/round-sequences`)
      .then((respone) => {
        if (respone.data.rows.length > 0) {
          var tempArr = [];
          respone.data.rows.forEach((roundSequence, index) => {
            if (
              roundSequence.sequence >
                contestEntryDetailInfo.roundSequence.sequence &&
              roundSequence.sequence <=
                contestEntryDetailInfo.examRound.roundSequenceSequence
            ) {
              tempArr.push(roundSequence);
            }
          });
          if (tempArr.length > 1) {
            var currentRoundSequence = tempArr.reduce((current, next) => {
              return current.sequence <= next.sequence ? current : next;
            });
            setCurrentRoundSequence((prevState) => ({
              ...prevState,
              id: currentRoundSequence.id,
              name: currentRoundSequence.name,
              sequence: currentRoundSequence.sequence,
            }));
          } else if (tempArr.length === 1) {
            setCurrentRoundSequence((prevState) => ({
              ...prevState,
              id: tempArr[0].id,
              name: tempArr[0].name,
              sequence: tempArr[0].sequence,
            }));
          } else {
            setCurrentRoundSequence((prevState) => ({
              ...prevState,
              id: "",
              name: "",
              sequence: "",
            }));
            return null;
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const [listContestEntriesModalOpen, setListContestEntriesModalOpen] =
    React.useState(false);

  const handleListContestEntriesModalOpen = () => {
    setListContestEntriesModalOpen(true);
  };

  const handleListContestEntriesModalClose = () => {
    setListContestEntriesModalOpen(false);
  };

  const getListSubmittedContestEntries = (userId) => {
    handleListContestEntriesModalOpen();
    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/contest-entry/get-by-user/${userId}`
      )
      .then((res) => {
        if (res.data) {
          setListContestEntries(res.data.rows);
        }
      });
  };

  // end get all submitted contest entries

  // begin contest entrie images display

  const contestEntriesImagesDisplay = (listImage) => {
    if (!Array.isArray(listImage) || !(listImage.length > 0)) {
      return null;
    } else {
      return (
        listImage.length > 0 &&
        listImage.map((img, index) => {
          return (
            <Grid
              key={index}
              item
              xs={12}
              display="flex"
              justifyContent="center"
              className="modal-image-wrapper"
            >
              <TransformWrapper
                initialScale={1}
                minScale={1}
                maxScale={isMobile ? 6 : 5}
              >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <React.Fragment>
                    <div className="tools-wrapper">
                      <IconButton onClick={() => zoomIn()}>
                        <AddCircleIcon />
                      </IconButton>
                      <IconButton onClick={() => zoomOut()}>
                        <RemoveCircleIcon />
                      </IconButton>
                      <IconButton onClick={() => resetTransform()}>
                        <RestartAltIcon />
                      </IconButton>
                    </div>
                    <TransformComponent wrapperStyle={{ overflow: "visible" }}>
                      <div
                        className="contest-entries-image-display-wrapper"
                        style={{
                          height: isTablet
                            ? isMobile
                              ? "300px"
                              : "600px"
                            : "1000px",
                          width: isTablet
                            ? isMobile
                              ? "300px"
                              : "600px"
                            : "1000px",
                        }}
                      >
                        <img
                          id="contest-entries-display-background-frame"
                          alt="contest entries background frame"
                          src="/images/contest-entries-background-frame.png"
                        />
                        <img
                          style={{
                            transform: `rotate(${img.image.rotateDegree}deg)`,
                            msTransform: `rotate(${img.image.rotateDegree}deg)`,
                            WebkitTransform: `rotate(${img.image.rotateDegree}deg)`,
                            msTransform: `rotate(${img.image.rotateDegree}deg)`,
                          }}
                          className="admin-submission-card-modal-img"
                          alt="submission test"
                          src={
                            process.env.REACT_APP_SERVER_ROOT_MEDIA_URL +
                            img.image.path
                          }
                        />
                      </div>
                    </TransformComponent>
                  </React.Fragment>
                )}
              </TransformWrapper>
            </Grid>
          );
        })
      );
    }
  };

  // end contest entrie images display

  // begin init data

  const [pageData, setPageData] = React.useState(1);

  const [totalRows, setTotalRows] = React.useState(0);

  const getListUserPerPage = (page) => {
    if (page) {
      handleBackdropOpen();
      axios
        .get(
          process.env.REACT_APP_SERVER_API_ROOT_URL +
            `/users/users-only/?page=${page}`,
          {
            headers: { Authorization: `${accessToken}` },
          }
        )
        .then((res) => {
          if (res.data) {
            setUserList(res.data.rows);
            setTotalRows(res.data.count);
            handleBackdropClose();
          } else {
            handleBackdropClose();
          }
        });
    }
  };

  React.useEffect(() => {
    if (accessToken) {
      handleBackdropOpen();
      axios
        .get(
          process.env.REACT_APP_SERVER_API_ROOT_URL +
            `/users/users-only/?page=${pageData ? pageData : 1}`,
          {
            headers: { Authorization: `${accessToken}` },
          }
        )
        .then((res) => {
          if (res.data) {
            setUserList(res.data.rows);
            setTotalRows(res.data.count);
            handleBackdropClose();
          } else {
            handleBackdropClose();
          }
        });
    }
  }, []);

  const columns = [
    {
      field: "username",
      headerName: "Tên đăng nhập",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: "",
      headerName: "Thao tác",
      headerClassName: "super-app-theme--header",
      width: 200,
      sortable: false,
      renderCell: (params) => (
        <Button
          fullWidth
          onClick={() => {
            getListSubmittedContestEntries(params.row.id);
          }}
          variant="contained"
          color="info"
          size="small"
        >
          Xem các bài thi đã nộp
        </Button>
      ),
    },
    {
      field: "name",
      headerName: "Họ và tên",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {params.value}
        </Typography>
      ),
    },
    // {
    //     field: 'gender', headerName: 'Giới tính', headerClassName: 'super-app-theme--header', width: 100,
    //     renderCell: (params) => (
    //         <Typography variant={isMobile ? "body2" : "body1"}>{gender_vi[params.value]}</Typography>
    //     )
    // },
    {
      field: "phone",
      headerName: "Số điện thoại",
      headerClassName: "super-app-theme--header",
      type: "number",
      width: 150,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "super-app-theme--header",
      type: "text",
      width: 250,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {params.value}
        </Typography>
      ),
    },
    // {
    //     field: 'dob', headerName: 'Ngày sinh', headerClassName: 'super-app-theme--header', type: 'text', width: 150,
    //     renderCell: (params) => (
    //         <Typography variant={isMobile ? "body2" : "body1"}>{DateTimeConvert.yyyymmddConvert(params.value)}</Typography>
    //     )
    // },
    {
      field: "address",
      headerName: "Địa chỉ",
      headerClassName: "super-app-theme--header",
      type: "text",
      width: 500,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {params.row.address}
          {params.row.district ? ", " + params.row.district.name : ""}
          {params.row.city ? ", " + params.row.city.name : ""}
        </Typography>
      ),
    },
  ];

  // end init data

  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
        onClick={handleBackdropClose}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <GroupIcon fontSize={isMobile ? "medium" : "large"} />
            <Typography
              variant={isMobile ? "body1" : "quicksand18"}
              style={{
                marginLeft: "10px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              Quản lý người dùng
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={4} style={{ padding: "0px" }}>
            <Box
              sx={{
                height: 600,
                width: 1,
                "& .super-app-theme--header": {
                  fontWeight: "bolder",
                  fontSize: "16px",
                  color: "#000000",
                },
              }}
            >
              <DataGrid
                localeText={viVN.components.MuiDataGrid.defaultProps.localeText}
                rows={userList}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                paginationMode={"server"}
                rowCount={totalRows}
                onPageChange={(page, details) => {
                  setPageData(page + 1);
                  getListUserPerPage(page + 1);
                }}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={listContestEntriesModalOpen}
        onClose={handleListContestEntriesModalClose}
      >
        <DialogContent>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Typography
                variant={isMobile ? "quicksand18" : "quicksand20"}
                align="center"
                style={{ textTransform: "uppercase", fontWeight: "bold" }}
              >
                Danh sách bài thi đã nộp
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Paper elevation={4} style={{ padding: "0px" }}>
                <Box
                  sx={{
                    height: 500,
                    width: 1,
                    "& .super-app-theme--header": {
                      fontWeight: "bolder",
                      fontSize: "16px",
                      color: "#000000",
                    },
                  }}
                >
                  <DataGrid
                    localeText={
                      viVN.components.MuiDataGrid.defaultProps.localeText
                    }
                    rows={listContestEntries}
                    columns={listContestEntriesColumns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: "15px" }}>
          <Button
            variant="contained"
            color="semiBlack"
            size={isMobile ? "small" : "medium"}
            onClick={handleListContestEntriesModalClose}
          >
            Đóng
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        fullScreen={fullScreen}
        open={submissionCardModalOpen}
        onClose={handleSubmissionCardModalClose}
      >
        <DialogContent>
          <Grid
            container
            rowSpacing={2}
            className="submission-card-modal-box-inside-wrapper"
          >
            <Grid
              item
              xs={12}
              className="admin-detail-submission-card-modal-title-wrapper"
            >
              <Grid
                container
                justifyContent="center"
                spacing={isMobile ? 1 : 2}
                style={{ position: "relative" }}
              >
                <Grid item xs={12}>
                  <Typography
                    variant="quicksand18shadow"
                    className="submission-card-modal-title"
                    sx={{ fontWeight: "700" }}
                  >
                    Ảnh mô tả bài thi:
                  </Typography>
                </Grid>
                {contestEntryDetailInfo.contestEntriesImages.length > 1 && (
                  <img
                    onClick={onModalNextArrowClick}
                    className="submission-modal-next-btn"
                    alt="submissions modal next btn"
                    src={
                      process.env.PUBLIC_URL +
                      "/images/homepage-slider-icon-next.png"
                    }
                  />
                )}
                {contestEntryDetailInfo.contestEntriesImages.length > 1 && (
                  <img
                    onClick={onModalPrevArrowClick}
                    className="submission-modal-prev-btn"
                    alt="submissions modal back btn"
                    src={
                      process.env.PUBLIC_URL +
                      "/images/homepage-slider-icon-back.png"
                    }
                  />
                )}
                <Carousel
                  infiniteLoop={true}
                  showStatus={false}
                  showIndicators={false}
                  showThumbs={false}
                  swipeable={false}
                  width={isTablet ? (isMobile ? 300 : 600) : 1000}
                >
                  {contestEntriesImagesDisplay(
                    contestEntryDetailInfo.contestEntriesImages
                  )}
                </Carousel>
              </Grid>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Mã bài thi: {contestEntryDetailInfo.examCode}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Họ tên thí sinh: {contestEntryDetailInfo.candidateName}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Ngày sinh: {contestEntryDetailInfo.candidateDob}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                SĐT liên lạc: {contestEntryDetailInfo.userPhone}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Trường: {contestEntryDetailInfo.candidateSchool}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Địa chỉ liên lạc: {contestEntryDetailInfo.address},{" "}
                {contestEntryDetailInfo.district}, {contestEntryDetailInfo.city}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Tên chủ đề: {contestEntryDetailInfo.subject}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Nội dung tác phẩm: {contestEntryDetailInfo.content}
              </Typography>
            </Grid>
            {/* <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Ý nghĩa: {contestEntryDetailInfo.sense}
              </Typography>
            </Grid> */}
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Hình thức thi: {contestEntryDetailInfo.examType}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Ngày tham gia: {contestEntryDetailInfo.contestJoinDate}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Tuần tham gia: {contestEntryDetailInfo.examRound.firstDoW}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Lượt xem: {contestEntryDetailInfo.views} lượt
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Kết quả hiện tại:{" "}
                {contestEntryDetailInfo.roundSequence.id
                  ? contestEntryDetailInfo.roundSequence.name
                      .concat(" - ")
                      .concat(result_vi[contestEntryDetailInfo.status])
                  : "Vòng 1"
                      .concat(" - ")
                      .concat(result_vi[contestEntryDetailInfo.status])}
              </Typography>
            </Grid>
            {contestEntryDetailInfo.status !== "FAIL" && (
              <Grid
                item
                xs={12}
                className="submission-card-modal-title-wrapper"
              >
                <Typography
                  variant="quicksand18shadow"
                  className="submission-card-modal-title"
                  sx={{ fontWeight: "700" }}
                >
                  Vòng thi hiện tại:{" "}
                  {currentRoundSequence.id
                    ? currentRoundSequence.name
                    : "Chưa mở vòng thi tiếp theo !"}
                </Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: "15px" }}>
          <Button
            variant="contained"
            color="semiBlack"
            size={isMobile ? "small" : "medium"}
            onClick={handleSubmissionCardModalClose}
          >
            Đóng
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
