import { Grid, Paper, Typography } from "@mui/material";
import "./ExamFormatCard.css";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function ExamFormatCard(props) {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:420px)");

  const getUrlPathFromExamTopic = () => {
    var url = "";
    var topic = props.title;
    switch (topic) {
      case "Viết chữ đẹp":
        url = "/exam-topic-poem-writing";
        break;
      case "Viết cảm nhận về clip":
        url = "/exam-topic-feeling-writing";
        break;
      case "Viết về việc tốt quanh Em":
        url = "/submit-exam";
        break;
      default:
        url = "/submit-exam";
        break;
    }
    navigate(url);
  };

  return (
    <Paper className="examformat-card-wrapper">
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          style={{ position: "relative", paddingBottom: "56.25%" }}
        >
          <img
            className="examformat-card-img"
            alt="submission test"
            src={props.imgUrl}
          />
        </Grid>
        <Grid item xs={12} className="examformat-card-content-wrapper">
          <Typography className="examformat-card-title" height={50}>
            {props.title}
          </Typography>
          <Typography
            className="examformat-card-text"
            height={130}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: isMobile ? 5 : 10,
              lineClamp: isMobile ? 5 : 10,
              WebkitBoxOrient: "vertical",
            }}
          >
            {props.text}
          </Typography>
          <button
            className="exam-join-button"
            onClick={getUrlPathFromExamTopic}
          >
            Tham gia ngay
          </button>
        </Grid>
      </Grid>
    </Paper>
  );
}
