import React, { useEffect } from "react";
import {
  CardMedia,
  Container,
  Box,
  Grid,
  Typography,
  Avatar,
} from "@mui/material";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import "../components/Information/Information.css";
import Footer from "../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function SubmitExam() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const textStyle = {
    fontWeight: "600",
    px: 0,
    py: 3,
    textAlign: "justify",
    justifyContent: "center",
  };
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <React.Fragment>
      <Container className="wrapper" maxWidth="xl" disableGutters={true}>
        <CardMedia
          component="img"
          image="/images/Information/bg-top.png"
          sx={{ position: "relative", zIndex: 1 }}
        ></CardMedia>
        {/* <img
          className="bg-top-text"
          alt="background top text"
          src="/images/Information/bg-top-text.png"
        /> */}
        <CardMedia
          component="img"
          image="/images/SubmitExam/bg-grid.png"
          sx={{ position: "absolute" }}
        ></CardMedia>
        <br></br>
        <CardMedia
          component="img"
          image="/images/SubmitExam/bg-gradient.png"
          className="gradient"
        ></CardMedia>
        <Grid container justifyContent="center">
          <Grid
            item
            md={6}
            sx={{ textAlign: "center", position: "relative", mx: 3, mb: 6 }}
          >
            <Grid container justifyContent="center" alignItems="center">
              <Box
                data-aos="fade-in"
                data-aos-duration="1500"
                className="info-title-box"
                sx={{
                  backgroundImage: "url('/images/SubmitExam/bg-title.png')",
                }}
              >
                <Typography variant="crocante36" color="yellow.main">
                  thông tin chi tiết
                </Typography>
              </Box>
              <Typography
                data-aos="fade-up"
                data-aos-duration="1500"
                variant="quicksand18"
                color="white.main"
                sx={textStyle}
              >
                Cuộc thi dành cho thiếu nhi Việt Nam, có độ tuổi từ 6 đến 10 tuổi 
                (từ lớp 01 đến lớp 05 tính theo năm học 2022 - 2023) đang sinh hoạt, học tập tại các trường Tiểu học hoặc bậc Tiểu học trong các trường liên cấp, 
                các Cung, Nhà Thiếu nhi, Trung tâm hoạt động Thanh Thiếu nhi trong cả nước.
              </Typography>
              <Grid
                container
                justifyContent="flex-end"
                sx={{ position: "relative", top: "50px", zIndex: "5" }}
              >
                <Grid
                  data-aos="fade-in"
                  data-aos-duration="1200"
                  item
                  md={0}
                  xs={12}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <img
                    className="info-image-letter-xs"
                    alt="information page letter icon"
                    src="/images/Information/img-letter.png"
                  />
                  {/* <CardMedia
                                        component="img"
                                        image="/images/Information/img-letter.png"
                                        
                                    ></CardMedia> */}
                </Grid>
              </Grid>
              <Box
                data-aos="fade-in"
                data-aos-duration="1500"
                className="info-title-box"
                sx={{
                  backgroundImage: "url('/images/SubmitExam/bg-title.png')",
                  mt: 10,
                }}
              >
                <Typography
                  fontSize={{ lg: 36, md: 36, sm: 36, xs: 22 }}
                  variant="crocante36"
                  color="yellow.main"
                >
                  QUY ĐỊNH VÀ CÁCH THỨC THAM GIA
                </Typography>
              </Box>
              <List data-aos="fade-up" data-aos-duration="1500">
                {/* Cách thức tham gia */}
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={
                      <Typography
                        variant="quicksand20"
                        color="white.main"
                        sx={{ fontWeight: "900" }}
                      >
                        I. Nội dung
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemIcon
                    style={{
                      minWidth: "30px",
                      fontSize: "11px",
                      marginTop: "11px",
                    }}
                  >
                    <FontAwesomeIcon icon={faCircle} color="#FBD855" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="quicksand18"
                        color="white.main"
                        sx={{ fontWeight: "700" }}
                      >
                        Thiếu nhi tham gia sân chơi bằng cách làm các mô hình, sản phẩm, vật dụng, đồ dùng gia đình, đồ chơi trẻ em, đồ phục vụ trang trí, phục vụ sản xuất, vật dụng trồng cây… từ những vật liệu tái chế đã qua sử dụng, thể hiện được tính sáng tạo, gắn với mục đích bảo vệ môi trường, có giá trị sử dụng hoặc phục vụ đời sống hàng ngày.
                      </Typography>
                    }
                  />
                </ListItem>

                {/* Hình thức thể hiện */}
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={
                      <Typography
                        variant="quicksand20"
                        color="white.main"
                        sx={{ fontWeight: "900" }}
                      >
                        II. Hình thức:
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemIcon
                    style={{
                      minWidth: "30px",
                      fontSize: "11px",
                      marginTop: "11px",
                    }}
                  >
                    <FontAwesomeIcon icon={faCircle} color="#FBD855" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="quicksand18"
                        color="white.main"
                        sx={{ fontWeight: "700" }}
                      >
                        1. Vòng sơ loại
                      </Typography>
                    }
                    secondary={
                      <Typography
                        variant="quicksand18"
                        color="white.main"
                        sx={{ fontWeight: "700", px: 0, py: 3 }}
                      >
                        - Thiếu nhi tham gia sân chơi với tư cách cá nhân, thực hiện các sản phẩm tái chế theo ý tưởng của mình. Chụp ảnh sản phẩm sau khi hoàn thành.<br/>
                        - Tiến hành truy cập website sân chơi: <a href="https://baovemoitruong.lamviectot.edu.vn">baovemoitruong.lamviectot.edu.vn</a> chọn mục <strong>“Gửi bài thi”</strong> và làm theo các bước được hướng dẫn tại website.
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemIcon
                    style={{
                      minWidth: "30px",
                      fontSize: "11px",
                      marginTop: "11px",
                    }}
                  >
                    <FontAwesomeIcon icon={faCircle} color="#FBD855" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="quicksand18"
                        color="white.main"
                        sx={{ fontWeight: "700" }}
                      >
                        2. Vòng chung khảo
                      </Typography>
                    }
                    secondary={
                      <Typography
                        variant="quicksand18"
                        color="white.main"
                        sx={{ fontWeight: "700", px: 0, py: 3 }}
                      >
                        - Thiếu nhi được lựa chọn vào vòng chung khảo tham gia các nội dung sau:<br/><br/>
                        + Bản mô tả xây dựng sản phẩm tối thiểu 500 từ.<br/>
                        + Video clip thời lượng không quá 10 phút trình bày về ý tưởng của sản phẩm. Bố cục của video clip gồm phần mở đầu <i>(Tên sản phẩm, họ và tên thiếu nhi, tên trường, huyện, tỉnh)</i> và phần trình bày về ý tưởng sản phẩm, công dụng.<br/><br/>
                        - Tiến hành truy cập website sân chơi: <a href="https://baovemoitruong.lamviectot.edu.vn">baovemoitruong.lamviectot.edu.vn</a> chọn mục <strong>“Gửi bài thi”</strong> và làm theo các bước được hướng dẫn tại website.<br/><br/>
                        - Ban Giám khảo sẽ chấm điểm dựa trên tính sáng tạo, tính ứng dụng của mô hình, sản phẩm chọn ra các giải cao.
                      </Typography>
                    }
                  />
                </ListItem>
                
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={
                      <Typography
                        variant="quicksand20"
                        color="white.main"
                        sx={{ fontWeight: "900" }}
                      >
                        III. Một số quy định
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemIcon
                    style={{
                      minWidth: "30px",
                      fontSize: "11px",
                      marginTop: "11px",
                    }}
                  >
                    <FontAwesomeIcon icon={faCircle} color="#FBD855" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="quicksand18"
                        color="white.main"
                        sx={{ fontWeight: "700" }}
                      >
                        Hình ảnh gửi tham gia tại vòng sơ loại đảm bảo rõ nét, thể hiện đầy đủ tác phẩm, ảnh có định dạng JPEG, dung lượng từ 1Mb đến 5Mb, chưa qua chỉnh sửa.
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemIcon
                    style={{
                      minWidth: "30px",
                      fontSize: "11px",
                      marginTop: "11px",
                    }}
                  >
                    <FontAwesomeIcon icon={faCircle} color="#FBD855" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="quicksand18"
                        color="white.main"
                        sx={{ fontWeight: "700" }}
                      >
                        Đối với các video clip tham gia tham gia vòng chung khảo đảm bảo theo định dạng file video cơ bản, thông dụng <i>(định dạng đuôi MP4)</i>. Khuyến khích sử dụng các thiết bị camera chuyên dụng để ghi hình nhằm đảm bảo chất lượng hình ảnh tốt. Sử dụng các phần mềm, công nghệ để thiết kế, dàn dựng sản phẩm tham gia của mình bảo đảm chất lượng âm thanh, hình ảnh của video clip.
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemIcon
                    style={{
                      minWidth: "30px",
                      fontSize: "11px",
                      marginTop: "11px",
                    }}
                  >
                    <FontAwesomeIcon icon={faCircle} color="#FBD855" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="quicksand18"
                        color="white.main"
                        sx={{ fontWeight: "700" }}
                      >
                        Sản phẩm gửi tham gia chương trình phải đảm bảo chưa từng tham gia, đạt giải ở bất kỳ sân chơi, cuộc thi nào; chưa được công bố hoặc đăng tải trên các trang mạng xã hội hoặc trên bất kỳ phương tiện thông tin đại chúng nào.
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemIcon
                    style={{
                      minWidth: "30px",
                      fontSize: "11px",
                      marginTop: "11px",
                    }}
                  >
                    <FontAwesomeIcon icon={faCircle} color="#FBD855" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="quicksand18"
                        color="white.main"
                        sx={{ fontWeight: "700" }}
                      >
                        Cá nhân tham gia phải chịu trách nhiệm hoàn toàn về bản quyền của sản phẩm tham gia và phải chịu trách nhiệm xử lý nếu có tranh chấp về bản quyền.
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemIcon
                    style={{
                      minWidth: "30px",
                      fontSize: "11px",
                      marginTop: "11px",
                    }}
                  >
                    <FontAwesomeIcon icon={faCircle} color="#FBD855" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="quicksand18"
                        color="white.main"
                        sx={{ fontWeight: "700" }}
                      >
                        Ban Tổ chức không chịu trách nhiệm đối với những sản phẩm tham gia gửi không đúng theo hướng dẫn của chương trình.
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemIcon
                    style={{
                      minWidth: "30px",
                      fontSize: "11px",
                      marginTop: "11px",
                    }}
                  >
                    <FontAwesomeIcon icon={faCircle} color="#FBD855" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="quicksand18"
                        color="white.main"
                        sx={{ fontWeight: "700" }}
                      >
                        Ban Giám khảo là người quyết định cuối cùng đối với những sản phẩm đạt giải.
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemIcon
                    style={{
                      minWidth: "30px",
                      fontSize: "11px",
                      marginTop: "11px",
                    }}
                  >
                    <FontAwesomeIcon icon={faCircle} color="#FBD855" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="quicksand18"
                        color="white.main"
                        sx={{ fontWeight: "700" }}
                      >
                        Ban Tổ chức có quyền sử dụng hình ảnh trong các bài tham gia để phục vụ công tác tuyên truyền trong các hoạt động của chương trình.
                      </Typography>
                    }
                  />
                </ListItem>

                {/* Tiêu chí chấm điểm */}
                {/* <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={
                      <Typography
                        variant="quicksand20"
                        color="white.main"
                        sx={{ fontWeight: "900" }}
                      >
                        IV. Tiêu chí chấm điểm:
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemIcon
                    style={{
                      minWidth: "30px",
                      fontSize: "11px",
                      marginTop: "11px",
                    }}
                  >
                    <FontAwesomeIcon icon={faCircle} color="#FBD855" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="quicksand18"
                        color="white.main"
                        sx={{ fontWeight: "700" }}
                      >
                        1. Viết chữ đẹp
                      </Typography>
                    }
                    secondary={
                      <Typography
                        variant="quicksand18"
                        color="white.main"
                        sx={{ fontWeight: "700", px: 0, py: 3 }}
                      >
                        Viết đầy đủ nội dung bài thơ, đúng chính tả, hình thức trình bày đẹp, sáng tạo; đảm bảo kỹ thuật viết chữ: Viết liền mạch, nét viết đều đẹp, chữ viết cân đối (chữ hoa, chữ thường); khoảng cách giữa các chữ hợp lý, ghi dấu thanh đúng vị trí.
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemIcon
                    style={{
                      minWidth: "30px",
                      fontSize: "11px",
                      marginTop: "11px",
                    }}
                  >
                    <FontAwesomeIcon icon={faCircle} color="#FBD855" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="quicksand18"
                        color="white.main"
                        sx={{ fontWeight: "700" }}
                      >
                        2. Viết cảm nhận
                      </Typography>
                    }
                    secondary={
                      <Typography
                        variant="quicksand18"
                        color="white.main"
                        sx={{ fontWeight: "700", px: 0, py: 3 }}
                      >
                        Nội dung phù hợp với thông điệp, ý nghĩa, thiết thực, có cảm xúc. Hình thức trình bày đẹp, đúng quy định, bố cục hợp lý, mạch lạc; cách sử dụng ngôn từ linh hoạt, đa dạng, phù hợp; bài viết có tính sáng tạo, độc đáo, phong cách riêng.
                      </Typography>
                    }
                  />
                </ListItem> */}
              </List>
              <Grid
                data-aos="fade-in"
                data-aos-duration="1200"
                className="info-content-wrapper"
                container
                spacing={0}
                sx={{ mt: 10, px: 2, py: 3 }}
              >
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{ px: 1 }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CardMedia
                    component="img"
                    image="/images/Information/lg-HDDTW.png"
                    style={
                      isMobile
                        ? { height: "71px", width: "65px" }
                        : { height: "116px", width: "107px" }
                    }
                  ></CardMedia>
                </Grid>
                <Grid item xs={12} md={9} sx={{ px: 1 }}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sx={
                        isMobile
                          ? { my: 1, textAlign: "center" }
                          : { my: 1, textAlign: "left" }
                      }
                    >
                      <Typography
                        variant="pony36"
                        style={
                          isMobile ? { fontSize: "20px" } : { fontSize: "36px" }
                        }
                      >
                        Liên hệ
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: "left" }}>
                      <Typography variant="quicksand18">
                        Thông tin Ban Tổ chức
                        <br />
                        Hội Đồng Đội Trung Ương
                        <br />
                        Địa chỉ: Số 62 Bà Triệu, Hoàn Kiếm, Hà Nội
                        <br />
                        Điện thoại: 0242 241 2031
                        <br />
                        Website: www.thieunhivietnam.vn
                        <br />
                        Email: cungemlamviectot@gmail.com
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            md={2}
            sx={{ position: "absolute", mt: "10%", mr: "-75%" }}
          >
            <img
              className="info-image-letter"
              alt="information page letter icon"
              src="/images/Information/img-letter.png"
            />
            {/* <CardMedia
                            component="img"
                            image="/images/Information/img-letter.png"
                            className='info-image-letter'
                        ></CardMedia> */}
          </Grid>
        </Grid>
        {isMobile ? (
          <Grid
            container
            justifyContent="center"
            sx={{ position: "relative", paddingBottom: 10, mt: 5 }}
          >
            <CardMedia
              component="img"
              image="/images/footer-bg-mobile.png"
              sx={{ mt: "220px", position: "absolute", bottom: 0 }}
            ></CardMedia>
            <Footer />
          </Grid>
        ) : (
          <div>
            <CardMedia
              component="img"
              image="/images/footer-bg.png"
              sx={{ position: "relative" }}
            ></CardMedia>
            <Grid
              container
              justifyContent="center"
              sx={{ position: "absolute", bottom: "100px" }}
            >
              <Footer />
            </Grid>
          </div>
        )}
      </Container>
    </React.Fragment>
  );
}
