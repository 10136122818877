import React from "react";
import { Paper, Grid, Typography, Button } from "@mui/material";

export default function ContestEntriesNumberCard(props) {
  const { text, number, color } = props;
  return (
    <Paper elevation={4} style={{ padding: "0px 15px 0px 15px" }}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Button variant="text" color={color}>
            <Typography
              variant="body2"
              style={{ textTransform: "uppercase", fontWeight: "bold" }}
            >
              {text}:
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography align="right" variant="h6" style={{ fontWeight: "bold" }}>
            {number}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
