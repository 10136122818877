import { createTheme } from '@mui/material'
import '@fontsource/quicksand'
import { fontWeight } from '@mui/system'

const theme = createTheme({
    // Palette
    palette: {
        // primary: {
        //     main: '#FBD855',
        // },
        mainBlue: {
            main: '#1976d2',
        },
        white: {
            main: '#FFFFFF',
            contrastText: '#000000',
        },
        semiBlack: {
            main: 'rgba(0,0,0,0.7)',
            contrastText: '#FFFFFF',
        },
        black: {
            main: '#000000',
            contrastText: '#FFFFFF',
        },
        yellow: {
            main: '#FBD855',
        },
        grey: {
            main: '#BABABA',
        },
        redBg: {
            main: '#D7282A',
        },
        lightGreen: {
            main: '#42BDB2',
            contrastText: '#FFFFFF'
        },
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
    },

    // Typography
    typography: {
        fontFamily: 'Quicksand, sans-serif',
        // button: {
        //     textTransform: "none",
        //     height: 44,
        //     border: '2px solid white',
        //     fontFamily: "iciel Pony",
        //     fontSize: 20,
        //     fontWeight: 400,
        // },
        pony20: {
            fontFamily: "iciel Pony",
            fontSize: 20,
            fontWeight: 400,
        },
        pony36: {
            fontFamily: "iciel Pony",
            fontSize: {
                lg: 36,
                md: 36,
                sm: 36,
                xs: 22
            },
            fontWeight: 400,
        },
        quicksand13: {
            fontFamily: "Quicksand",
            fontSize: 13,
            fontWeight: 500,
            lineHeight: 1.25
        },
        quicksand18: {
            fontFamily: "Quicksand",
            fontSize: 18,
            '@media (max-width:768px)': {
                fontSize: '13px',
            },
            fontWeight: 500,
            lineHeight: 1.6,
        },

        quicksand18shadow: {
            fontFamily: "Quicksand",
            fontSize: 18,
            '@media (max-width:768px)': {
                fontSize: '13px',
            },
            fontWeight: 900,
            lineHeight: 1.25,
            textShadow: "0px 1px # d9d9d9 ",
        },
        quicksand20: {
            fontFamily: "Quicksand",
            fontSize: 20,
            fontWeight: 600,
            lineHeight: 1.25
        },
        quicksand24: {
            fontFamily: "Quicksand",
            fontSize: 24,
            fontWeight: 700,
            lineHeight: 1.25
        },
        crocante36: {
            fontFamily: "iciel Crocante",
            fontSize: '36px',
            '@media (max-width:768px)': {
                fontSize: '22px',
            },
            fontWeight: 'normal',
            lineHeight: '32px',
            letterSpacing: '0.4px',
        }
    },

    // Spacing
    spacing: 8,

    // shape: {
    //     borderRadius: 100,
    // },

    // Component
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&$error': {
                        color: 'white'
                    }
                },
                notchedOutline: {
                    borderColor: "#fff !important"
                },
                input: {
                    color: "#fff"
                }
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    "&.Mui-error": {
                        "&.MuiFormHelperText-root.Mui-error": {
                            color: "#fff",
                            fontFamily: "Inter",
                        }
                    }
                },
            }
        }
    },
})

export default theme