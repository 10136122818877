import { Grid, Typography } from "@mui/material";
import "./ExamPagePoemTopic.css";
import PoemExamTopicCard from "../PoemExamTopicCard";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function ExamPagePoemTopic() {
  const [listExamTopic, setListExamTopic] = React.useState([]);
  const isMobile = useMediaQuery("(max-width:768px)");

  React.useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          "/exam-types/?name=Viết chữ đẹp"
      )
      .then((respone) => {
        if (respone.data.rows.length > 0) {
          var examTypeId = respone.data.rows[0].id;
          axios
            .get(
              process.env.REACT_APP_SERVER_API_ROOT_URL +
                `/exams/get-by-type/${examTypeId}`
            )
            .then((respone) => {
              if (respone.data.rows.length > 0) {
                var listPoemTopics = [];
                var listSongTopics = [];
                respone.data.rows.map((topic, index) => {
                  if (topic.content.includes("thơ")) {
                    listPoemTopics.push(topic);
                  } else if (topic.content.includes("hát")) {
                    listSongTopics.push(topic);
                  }
                });
                setListExamTopic(listPoemTopics.concat(listSongTopics));
              }
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => console.log(error));
  }, []);
  return (
    <Grid
      container
      justifyContent="center"
      className="exam-poem-topic-wrapper"
      spacing={6}
    >
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid
            item
            xs={isMobile ? 11 : 6}
            className="poem-topic-title-wrapper"
            style={{
              backgroundImage:
                'url("images/SubmissionSearchPage/search-box-title-background.png")',
            }}
          >
            <Typography className="poem-topic-title">Viết chữ đẹp</Typography>
          </Grid>
          <Grid item xs={isMobile ? 11 : 12}>
            <Typography className="poem-topic-sub-title">
              Chọn một trong các bài thơ/bài hát sau đây và chép lại
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <Grid container justifyContent="center" spacing={6}>
          {listExamTopic.length > 0 &&
            listExamTopic.map((examTopic, index) => {
              return (
                <Grid key={index} item xs={isMobile ? 12 : 4}>
                  <PoemExamTopicCard examData={examTopic} />
                </Grid>
              );
            })}
        </Grid>
      </Grid>
      <Grid item xs={12} display="none" justifyContent="center">
        <Pagination
          hideNextButton={true}
          hidePrevButton={true}
          boundaryCount={1}
          className="exam-poem-topic-pagination"
          count={10}
          size="large"
        />
      </Grid>
    </Grid>
  );
}
