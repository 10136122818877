import React, { useEffect } from "react";
import {
  CardMedia,
  Container,
  Box,
  Grid,
  Typography,
  Avatar,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import "../components/Results/Results.css";
import Footer from "../components/Footer";
import WinnerCard from "../components/WinnerCard";
import axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import IconButton from "@mui/material/IconButton";
import DateTimeConvert from "../utils/DateTimeConvert";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-input": {
      color: "#BABABA",
      fontFamily: "Quicksand",
      fontSize: 16,
      fontWeight: 600,
      paddingTop: "8px",
      paddingBottom: "8px",
      zIndex: 1,
      paddingLeft: 8,
    },
  },
  input: {
    color: "#BABABA",
    background: "white",
    borderRadius: 7,
    border: "2px solid #BABABA",
    height: "45px",
  },
  textArea: {
    color: "#BABABA",
    background: "white",
    borderRadius: 7,
    border: "2px solid #BABABA",
  },
  selectInput: {
    width: "100%",
    height: "45px",
    background: "#FFFFFF",
    border: "2px solid #BABABA",
    boxSizing: "border-box",
    borderRadius: "7px",
    color: "#BABABA",
    fontFamily: "Quicksand",
    fontSize: 16,
    fontWeight: 600,
    paddingTop: "8px",
    paddingBottom: "8px",
    zIndex: 1,
    paddingLeft: 8,
    paddingRight: "8px",
  },
});

export default function ResultsPage() {
  const theme = useTheme();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const classes = useStyles();
  const [category, setCategory] = React.useState("");
  const selectCategory = (e) => {
    setCategory(e.target.value);
    if (e.target.value) {
      axios
        .get(
          process.env.REACT_APP_SERVER_API_ROOT_URL +
            `/contest-entry/get-by-exam-round/${e.target.value}`
        )
        .then((res) => {
          if (res.data) {
            setListContestEntries(res.data.rows);
          } else {
            setListContestEntries([]);
          }
        })
        .catch((error) => console.log(error));
    }
  };
  const isMobile = useMediaQuery("(max-width:768px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const mw1440px = useMediaQuery("(max-width:1440px)");
  const [listExamRound, setListExamRound] = React.useState([]);
  const [listContestEntries, setListContestEntries] = React.useState([]);
  const [submissionCardModalOpen, updateSubmissionCardModalOpen] =
    React.useState(false);
  const [selectedData, setSelectedData] = React.useState({
    imgPath: [],
    candidateName: "",
    subject: "",
    content: "",
    sense: "",
    candidateSchool: "",
    district: "",
    city: "",
  });
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleSubmissionCardModalClose = () => {
    updateSubmissionCardModalOpen(false);
  };
  React.useEffect(() => {
    axios
      .get(process.env.REACT_APP_SERVER_API_ROOT_URL + "/exam-round")
      .then((res) => {
        if (res.data) {
          setListExamRound(res.data.rows);
          setCategory(res.data.rows[0].id);
          axios
            .get(
              process.env.REACT_APP_SERVER_API_ROOT_URL +
                `/contest-entry/get-by-exam-round/${res.data.rows[0].id}`
            )
            .then((res) => {
              if (res.data) {
                setListContestEntries(res.data.rows);
              }
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => console.log(error));
  }, []);

  const onNextArrowClick = () => {
    let parent = document.querySelector(
      ".result-page-winner-submission-wrapper"
    );
    let arrow = parent.querySelector(".control-next");
    arrow.click();
  };

  const onPrevArrowClick = () => {
    let parent = document.querySelector(
      ".result-page-winner-submission-wrapper"
    );
    let arrow = parent.querySelector(".control-prev");
    arrow.click();
  };

  const onModalNextArrowClick = () => {
    let parent = document.querySelector(
      ".hot-submission-card-modal-title-wrapper"
    );
    let arrow = parent.querySelector(".control-next");
    arrow.click();
  };

  const onModalPrevArrowClick = () => {
    let parent = document.querySelector(
      ".hot-submission-card-modal-title-wrapper"
    );
    let arrow = parent.querySelector(".control-prev");
    arrow.click();
  };

  const contestDisplay = (listContest) => {
    if (listContest.length === 0) {
      return null;
    }
    if (listContest.length <= 3) {
      return [
        <Grid
          container
          key={listContest[0].id}
          justifyContent="space-around"
          alignItems="center"
          sx={{ px: 1, my: 4 }}
        >
          {listContest.map((contestEntry, index) => {
            return (
              <Grid
                key={contestEntry.id}
                item
                md={3}
                sx={{ position: "relative", zIndex: 3 }}
                onClick={() => handleSubmissionCardModalOpen(contestEntry)}
              >
                <WinnerCard cardData={contestEntry} />
              </Grid>
            );
          })}
        </Grid>,
      ];
    }
    return [
      <Grid
        container
        key={listContest[0].id}
        justifyContent="space-around"
        alignItems="center"
        sx={{ px: 1, my: 4 }}
      >
        {listContest.slice(0, 3).map((contestEntry, index) => {
          return (
            <Grid
              key={contestEntry.id}
              item
              md={3}
              sx={{ position: "relative", zIndex: 3 }}
              onClick={() => handleSubmissionCardModalOpen(contestEntry)}
            >
              <WinnerCard cardData={contestEntry} />
            </Grid>
          );
        })}
      </Grid>,
    ].concat(contestDisplay(listContest.slice(3, listContest.length)));
  };

  const contestDisplayMobile = (listContest) => {
    if (listContest.length === 0) {
      return null;
    }
    if (listContest.length <= 3) {
      return [
        <Grid
          container
          key={listContest[0].id}
          justifyContent="space-around"
          alignItems="center"
          sx={{ px: 1 }}
        >
          {listContest.map((contestEntry, index) => {
            return (
              <Grid
                key={contestEntry.id}
                item
                xs={8}
                sx={{ position: "relative", zIndex: 3 }}
                onClick={() => handleSubmissionCardModalOpen(contestEntry)}
              >
                <WinnerCard cardData={contestEntry} />
              </Grid>
            );
          })}
        </Grid>,
      ];
    }
    return [
      <Grid
        container
        key={listContest[0].id}
        justifyContent="space-around"
        alignItems="center"
        sx={{ px: 1 }}
      >
        {listContest.slice(0, 3).map((contestEntry, index) => {
          return (
            <Grid
              key={contestEntry.id}
              item
              xs={8}
              sx={{ position: "relative", zIndex: 3 }}
              onClick={() => handleSubmissionCardModalOpen(contestEntry)}
            >
              <WinnerCard cardData={contestEntry} />
            </Grid>
          );
        })}
      </Grid>,
    ].concat(contestDisplayMobile(listContest.slice(3, listContest.length)));
  };

  const contestEntriesImagesDisplay = (listImage) => {
    if (!Array.isArray(listImage) || !(listImage.length > 0)) {
      return null;
    } else {
      return (
        listImage.length > 0 &&
        listImage.map((img, index) => {
          return (
            <Grid
              key={index}
              item
              xs={12}
              display="flex"
              justifyContent="center"
              className="modal-image-wrapper"
            >
              <TransformWrapper
                initialScale={1}
                minScale={1}
                maxScale={isMobile ? 6 : 5}
              >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <React.Fragment>
                    <div className="tools-wrapper">
                      <IconButton onClick={() => zoomIn()}>
                        <AddCircleIcon />
                      </IconButton>
                      <IconButton onClick={() => zoomOut()}>
                        <RemoveCircleIcon />
                      </IconButton>
                      <IconButton onClick={() => resetTransform()}>
                        <RestartAltIcon />
                      </IconButton>
                    </div>
                    <TransformComponent wrapperStyle={{ overflow: "visible" }}>
                      <div
                        className="contest-entries-image-display-wrapper"
                        style={{
                          height: isTablet
                            ? isMobile
                              ? "300px"
                              : "600px"
                            : "1000px",
                          width: isTablet
                            ? isMobile
                              ? "300px"
                              : "600px"
                            : "1000px",
                        }}
                      >
                        <img
                          id="contest-entries-display-background-frame"
                          alt="contest entries background frame"
                          src="/images/contest-entries-background-frame.png"
                        />
                        <img
                          style={{
                            transform: `rotate(${img.image.rotateDegree}deg)`,
                            msTransform: `rotate(${img.image.rotateDegree}deg)`,
                            WebkitTransform: `rotate(${img.image.rotateDegree}deg)`,
                            msTransform: `rotate(${img.image.rotateDegree}deg)`,
                          }}
                          className="admin-submission-card-modal-img"
                          alt="submission test"
                          src={
                            process.env.REACT_APP_SERVER_ROOT_MEDIA_URL +
                            img.image.path
                          }
                        />
                      </div>
                    </TransformComponent>
                  </React.Fragment>
                )}
              </TransformWrapper>
            </Grid>
          );
        })
      );
    }
  };

  const handleSubmissionCardModalOpen = (selectedContestEntry) => {
    updateSubmissionCardModalOpen(true);
    setSelectedData((prevState) => ({
      ...prevState,
      imgPath: selectedContestEntry.contestEntriesImages,
      candidateName: selectedContestEntry
        ? selectedContestEntry.candidateName
        : "",
      subject: selectedContestEntry ? selectedContestEntry.subject : "",
      content: selectedContestEntry ? selectedContestEntry.content : "",
      sense: selectedContestEntry ? selectedContestEntry.sense : "",
      district: selectedContestEntry
        ? selectedContestEntry.user.district.name
        : "",
      city: selectedContestEntry ? selectedContestEntry.user.city.name : "",
      candidateSchool: selectedContestEntry
        ? selectedContestEntry.candidateSchool
        : "",
    }));
    axios
      .put(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/contest-entry/count-view-number/${selectedContestEntry.id}`
      )
      .then((res) => {})
      .catch((error) => console.log(error));
  };

  const prizeDescStyle = {
    position: "absolute",
    textShadow: "0px 1px #000000",
    fontWeight: "900",
    textAlign: "justify",
    width: "90%",
    lineHeight: 1.2,
    top: "65px",
  };
  const prizeTitleStyle = {
    position: "absolute",
    textShadow: "0px 1px #000000",
    fontWeight: "900",
    top: "40px",
  };

  return (
    <React.Fragment>
      <Container className="wrapper" maxWidth="xl" disableGutters={true}>
        {isMobile ? (
          <CardMedia
            component="img"
            image="/images/Results/bg-top-mobile.png"
            sx={{ position: "relative" }}
          ></CardMedia>
        ) : (
          <CardMedia
            component="img"
            image="/images/Results/bg-top.png"
            sx={{
              position: "relative",
              zIndex: 1,
              width: "80%",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          ></CardMedia>
        )}
        {isMobile ? (
          <CardMedia
            component="img"
            image="/images/Results/bg-grid-mobile.png"
            sx={{ position: "absolute", top: 0 }}
          ></CardMedia>
        ) : (
          <CardMedia
            component="img"
            image="/images/Results/bg-grid.png"
            sx={{ position: "absolute", top: 0 }}
          ></CardMedia>
        )}
        {isMobile ? null : (
          <CardMedia
            component="img"
            image="/images/Results/bg-center.png"
            sx={{ position: "absolute", zIndex: 2, top: "1%" }}
          ></CardMedia>
        )}

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          sx={{ position: "relative", zIndex: 3 }}
        >
          <Grid
            item
            xs={12}
            md={6}
            data-aos="fade-down"
            data-aos-duration="1500"
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ px: 1, my: 4 }}
            >
              <Typography
                variant="quicksand18"
                color="white.main"
                sx={{ fontWeight: "700" }}
              >
                Chọn danh mục *
              </Typography>
              <Grid item xs={12} md={9} sx={{ px: 1 }}>
                <Container disableGutters={true}>
                  <select
                    className={classes.selectInput}
                    value={category}
                    onChange={selectCategory}
                    id="category"
                  >
                    {listExamRound.length > 0 &&
                      listExamRound.map((examRound, index) => {
                        if (examRound.isDone) {
                          return (
                            <option key={index} value={examRound.id}>
                              Kết quả tuần:{" "}
                              {DateTimeConvert.roundResultDateDisplay(
                                examRound.firstDoW
                              )}
                            </option>
                          );
                        }
                      })}
                    <option value="encourage">Giải khuyến khích</option>
                    <option value="third">Giải ba</option>
                    <option value="second">Giải nhì</option>
                    <option value="first">Giải nhất</option>
                    <option value="special">Giải đặc biệt</option>
                  </select>
                </Container>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            spacing={0}
            className="result-page-winner-submission-wrapper"
          >
            {isMobile ? (
              <CardMedia
                component="img"
                image="/images/Results/bg-center-mobile.png"
                sx={{ position: "absolute", zIndex: 0, top: "4%" }}
              ></CardMedia>
            ) : null}
            <Grid
              item
              xs={11}
              md={9}
              display="flex"
              justifyContent="center"
              style={{ position: "relative" }}
            >
              {listContestEntries.length > 3 && (
                <img
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  onClick={onNextArrowClick}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    right: "calc(0%)",
                    top: "calc(50% - 54px)",
                    zIndex: "9",
                  }}
                  alt="hot submissions next btn"
                  src="images/homepage-slider-icon-next.png"
                />
              )}
              {!isMobile
                ? listContestEntries.length > 3 && (
                    <img
                      data-aos="fade-up"
                      data-aos-duration="1500"
                      onClick={onPrevArrowClick}
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        left: "calc(0%)",
                        top: "calc(50% - 54px)",
                        zIndex: "9",
                      }}
                      alt="hot submissions back btn"
                      src="images/homepage-slider-icon-back.png"
                    />
                  )
                : null}
              {listContestEntries.length > 0 ? (
                <div data-aos="fade-up" data-aos-duration="1500">
                  <Carousel
                    infiniteLoop={true}
                    showStatus={false}
                    showIndicators={false}
                    showThumbs={false}
                    selectedItem={listContestEntries.length > 3 ? -1 : 0}
                    width={isMobile ? "100vw" : "100%"}
                  >
                    {isMobile
                      ? contestDisplayMobile(listContestEntries.slice(0, 20))
                      : contestDisplay(listContestEntries.slice(0, 20))}
                  </Carousel>
                </div>
              ) : (
                <Typography
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  className="contest-entries-blank-text"
                >
                  Dữ liệu đang được cập nhật !
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item md={6} xs={12} sx={{ my: 8 }}>
            <Grid
              className="result-title"
              container
              justifyContent="center"
              alignItems="center"
              sx={{ my: 3 }}
            >
              <Grid item md={6} xs={12} display="flex" justifyContent="center">
                <Box
                  data-aos="fade"
                  data-aos-duration="1500"
                  className="result-title-box"
                  sx={{
                    backgroundImage: "url('/images/SubmitExam/bg-title.png')",
                  }}
                >
                  <Typography variant="crocante36" color="yellow.main">
                    Phần thưởng
                  </Typography>
                </Box>
              </Grid>
              <Grid
                data-aos="fade-up"
                data-aos-duration="1500"
                container
                justifyContent="center"
                sx={{ textAlign: "center", position: "relative", my: 2 }}
              >
                <Grid item xs={12} md={6} sx={{ position: "relative" }}>
                  <Typography
                    className="result-special-title"
                    variant="quicksand18shadow"
                    color="black.main"
                    sx={{ top: "40px", right: "25%", position: "absolute" }}
                  >
                    01 GIẢI ĐẶC BIỆT
                  </Typography>
                  <Typography
                    className="result-special-content"
                    variant="quicksand18shadow"
                    color="black.main"
                    sx={{
                      fontWeight: "700",
                      textAlign: "justify",
                      top: "70px",
                      right: "5%",
                      width: "65%",
                      position: "absolute",
                    }}
                  >
                    Bằng khen của Hội đồng Đội Trung ương, tiền thưởng 7.000.000 đồng và Bộ sưu tập quà KUN trị giá 2 triệu đồng.
                  </Typography>
                  <CardMedia
                    component="img"
                    image="/images/Results/img-special-prize.png"
                  ></CardMedia>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {isMobile ? (
            <Grid container justifyContent="flex-end">
              <Grid item xs={5} data-aos="fade-in" data-aos-duration="1500">
                <CardMedia
                  component="img"
                  image="/images/Results/img-money.png"
                ></CardMedia>
              </Grid>
              <Grid
                data-aos="fade-up"
                data-aos-duration="1500"
                item
                xs={12}
                sx={{ position: "relative" }}
              >
                <Grid
                  container
                  justifyContent="center"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <CardMedia
                    component="img"
                    image="/images/Results/img-prize-1-mobile.png"
                    sx={{ position: "relative" }}
                  ></CardMedia>
                  <Typography
                    fontSize={{ xs: 16 }}
                    variant="quicksand18"
                    color="white.main"
                    sx={prizeTitleStyle}
                  >
                    03 GIẢI NHẤT
                  </Typography>
                  <Typography
                    fontSize={{ xs: 16 }}
                    variant="quicksand18"
                    color="white.main"
                    sx={prizeDescStyle}
                  >
                    Bằng khen của Hội đồng Đội Trung ương, tiền thưởng 5.000.000 đồng và Bộ sưu tập quà KUN trị giá 2 triệu đồng.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ position: "relative" }}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <Typography
                    fontSize={{ xs: 16 }}
                    variant="quicksand18"
                    color="white.main"
                    sx={{ textShadow: "0px 1px #000000", fontWeight: "900" }}
                  >
                    06 GIẢI NHÌ
                  </Typography>
                  <Typography
                    fontSize={{ xs: 16 }}
                    variant="quicksand18"
                    color="white.main"
                    sx={{
                      textShadow: "0px 1px #000000",
                      fontWeight: "900",
                      textAlign: "justify",
                      width: "90%",
                      lineHeight: 1.2,
                    }}
                  >
                    Bằng khen của Hội đồng Đội Trung ương, tiền thưởng 3.000.000 đồng và Bộ sưu tập quà KUN trị giá 2 triệu đồng.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ position: "relative" }}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <CardMedia
                    component="img"
                    image="/images/Results/img-prize-2-mobile.png"
                    sx={{ position: "relative" }}
                  ></CardMedia>
                  <Typography
                    fontSize={{ xs: 16 }}
                    variant="quicksand18"
                    color="white.main"
                    sx={prizeTitleStyle}
                  >
                    10 GIẢI BA
                  </Typography>
                  <Typography
                    fontSize={{ xs: 16 }}
                    variant="quicksand18"
                    color="white.main"
                    sx={prizeDescStyle}
                  >
                    Bằng khen của Hội đồng Đội Trung ương, tiền thưởng 2.000.000 đồng và Bộ sưu tập quà KUN trị giá 1 triệu đồng.
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ position: "relative" }}
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <Grid container alignItems="center" justifyContent="center">
                  <Typography
                    fontSize={{ xs: 16 }}
                    variant="quicksand18"
                    color="white.main"
                    sx={{ textShadow: "0px 1px #000000", fontWeight: "900" }}
                  >
                    15 GIẢI KHUYẾN KHÍCH
                  </Typography>
                  <Typography
                    fontSize={{ xs: 16 }}
                    variant="quicksand18"
                    color="white.main"
                    sx={{
                      textShadow: "0px 1px #000000",
                      fontWeight: "900",
                      textAlign: "justify",
                      width: "90%",
                      lineHeight: 1.2,
                    }}
                  >
                    Giấy chứng nhận của Hội đồng Đội Trung ương, tiền thưởng 1.000.000 đồng và Bộ sưu tập quà KUN trị giá 1 triệu đồng.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={6} data-aos="fade" data-aos-duration="1200">
                <CardMedia
                  component="img"
                  image="/images/Results/img-shoe.png"
                ></CardMedia>
              </Grid>
              <Grid item xs={6} data-aos="fade" data-aos-duration="1200">
                <CardMedia
                  component="img"
                  image="/images/Results/img-helmet.png"
                ></CardMedia>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12} md={12}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ px: 1, mb: 5 }}
              >
                <Grid
                  item
                  xs={3}
                  md={3}
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <Grid
                    container
                    direction="column"
                    sx={{ textAlign: "center", px: 2 }}
                  >
                    <CardMedia
                      component="img"
                      image="/images/Results/img-1st-prize.png"
                      sx={{ width: "80%", margin: "auto" }}
                    ></CardMedia>
                    <Typography
                      variant="quicksand18"
                      color="white.main"
                      sx={{
                        textShadow: "0px 1px #000000",
                        fontWeight: "900",
                        my: 1,
                      }}
                    >
                      03 GIẢI NHẤT
                    </Typography>
                    <Typography
                      variant="quicksand18"
                      color="white.main"
                      sx={{
                        textShadow: "0px 1px #000000",
                        fontWeight: "900",
                        textAlign: "justify",
                      }}
                    >
                      Bằng khen của Hội đồng Đội Trung ương, tiền thưởng 5.000.000 đồng và Bộ sưu tập quà KUN trị giá 2 triệu đồng.
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={3}
                  md={3}
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <Grid
                    container
                    direction="column"
                    sx={{ textAlign: "center", px: 2 }}
                  >
                    <CardMedia
                      component="img"
                      image="/images/Results/img-2nd-prize.png"
                      sx={{ width: "70%", margin: "auto" }}
                    ></CardMedia>
                    <Typography
                      variant="quicksand18"
                      color="white.main"
                      sx={{
                        textShadow: "0px 1px #000000",
                        fontWeight: "900",
                        my: 1,
                      }}
                    >
                      06 GIẢI NHÌ
                    </Typography>
                    <Typography
                      variant="quicksand18"
                      color="white.main"
                      sx={{
                        textShadow: "0px 1px #000000",
                        fontWeight: "900",
                        textAlign: "justify",
                      }}
                    >
                      Bằng khen của Hội đồng Đội Trung ương, tiền thưởng 3.000.000 đồng và Bộ sưu tập quà KUN trị giá 2 triệu đồng.
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={3}
                  md={3}
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <Grid
                    container
                    direction="column"
                    sx={{ textAlign: "center", px: 2 }}
                  >
                    <CardMedia
                      component="img"
                      image="/images/Results/img-3rd-prize.png"
                      sx={{ width: "60%", margin: "auto" }}
                    ></CardMedia>
                    <Typography
                      variant="quicksand18"
                      color="white.main"
                      sx={{
                        textShadow: "0px 1px #000000",
                        fontWeight: "900",
                        my: 1,
                      }}
                    >
                      10 GIẢI BA
                    </Typography>
                    <Typography
                      variant="quicksand18"
                      color="white.main"
                      sx={{
                        textShadow: "0px 1px #000000",
                        fontWeight: "900",
                        textAlign: "justify",
                      }}
                    >
                      Bằng khen của Hội đồng Đội Trung ương, tiền thưởng 2.000.000 đồng và Bộ sưu tập quà KUN trị giá 1 triệu đồng.
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={3}
                  md={3}
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <Grid
                    container
                    direction="column"
                    sx={{ textAlign: "center", px: 2 }}
                  >
                    <CardMedia
                      component="img"
                      image="/images/Results/img-consolation-prize.png"
                      sx={{ width: "70%", margin: "auto" }}
                    ></CardMedia>
                    <Typography
                      variant="quicksand18"
                      color="white.main"
                      sx={{
                        textShadow: "0px 1px #000000",
                        fontWeight: "900",
                        my: 1,
                      }}
                    >
                      15 GIẢI KHUYẾN KHÍCH
                    </Typography>
                    <Typography
                      variant="quicksand18"
                      color="white.main"
                      sx={{
                        textShadow: "0px 1px #000000",
                        fontWeight: "900",
                        textAlign: "justify",
                      }}
                    >
                      Giấy chứng nhận của Hội đồng Đội Trung ương, tiền thưởng 1.000.000 đồng và Bộ sưu tập quà KUN trị giá 1 triệu đồng.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={2}
                  sx={{ my: 2, px: 2, textAlign: "center" }}
                  data-aos="fade"
                  data-aos-duration="1200"
                >
                  <CardMedia
                    component="img"
                    image="/images/Results/img-helmet.png"
                  ></CardMedia>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        {isMobile ? (
          <Grid container sx={{ position: "relative" }}>
            <CardMedia
              component="img"
              image="/images/Results/bg-bottom-mobile.png"
              sx={{ position: "absolute" }}
            ></CardMedia>
            <Container
              sx={{ height: "400px", position: "relative" }}
            ></Container>
          </Grid>
        ) : (
          <Grid
            item
            sx={{
              my: 2,
              px: 6,
              // position: "absolute",
              zIndex: "3",
              bottom: `${mw1440px ? "12%" : "11%"}`,
            }}
          >
            <CardMedia
              component="img"
              image="/images/footer-results.png"
            ></CardMedia>
          </Grid>
        )}

        {isMobile ? (
          <Grid
            container
            justifyContent="center"
            sx={{ position: "relative", paddingBottom: 10, mt: 5 }}
          >
            <CardMedia
              component="img"
              image="/images/footer-bg-mobile.png"
              sx={{ mt: "220px", position: "absolute", bottom: 0 }}
            ></CardMedia>
            <Footer />
          </Grid>
        ) : (
          <div>
            <CardMedia
              component="img"
              image="/images/footer-landscape-background.png"
              sx={{ position: "relative" }}
            ></CardMedia>
            <Grid
              container
              justifyContent="center"
              sx={{ position: "absolute", bottom: "100px" }}
            >
              <Footer />
            </Grid>
          </div>
        )}
      </Container>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        fullScreen={fullScreen}
        open={submissionCardModalOpen}
        onClose={handleSubmissionCardModalClose}
        className={classes.modal}
      >
        <DialogContent className={classes.modal}>
          <Grid
            container
            rowSpacing={2}
            className="submission-card-modal-box-inside-wrapper"
          >
            <Grid
              item
              xs={12}
              className="hot-submission-card-modal-title-wrapper"
            >
              <Grid
                container
                justifyContent="center"
                spacing={isMobile ? 1 : 2}
                style={{ position: "relative" }}
              >
                <Grid item xs={12}>
                  <Typography
                    variant="quicksand18shadow"
                    className="submission-card-modal-title"
                    sx={{ fontWeight: "700" }}
                  >
                    Ảnh mô tả bài thi:
                  </Typography>
                </Grid>
                {selectedData.imgPath.length > 1 && (
                  <img
                    onClick={onModalNextArrowClick}
                    className="submission-modal-next-btn"
                    alt="submissions modal next btn"
                    src="images/homepage-slider-icon-next.png"
                  />
                )}
                {selectedData.imgPath.length > 1 && (
                  <img
                    onClick={onModalPrevArrowClick}
                    className="submission-modal-prev-btn"
                    alt="submissions modal back btn"
                    src="images/homepage-slider-icon-back.png"
                  />
                )}
                <Carousel
                  infiniteLoop={true}
                  showStatus={false}
                  showIndicators={false}
                  showThumbs={false}
                  swipeable={false}
                  width={isTablet ? (isMobile ? 300 : 600) : 1000}
                >
                  {contestEntriesImagesDisplay(selectedData.imgPath)}
                </Carousel>
              </Grid>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Họ tên thí sinh: {selectedData.candidateName}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Tên chủ đề: {selectedData.subject}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Nội dung tác phẩm: {selectedData.content}
              </Typography>
            </Grid>
            {/* <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Ý nghĩa: {selectedData.sense}
              </Typography>
            </Grid> */}
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Trường: {selectedData.candidateSchool}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Quận/ Huyện: {selectedData.district}
              </Typography>
            </Grid>
            <Grid item xs={12} className="submission-card-modal-title-wrapper">
              <Typography
                variant="quicksand18shadow"
                className="submission-card-modal-title"
                sx={{ fontWeight: "700" }}
              >
                Tỉnh/ Thành phố: {selectedData.city}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            size="large"
            className="submission-card-modal-close-button"
            onClick={handleSubmissionCardModalClose}
            autoFocus
          >
            Đóng
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
