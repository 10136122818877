import * as React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DatePicker } from "@mui/lab";
import { makeStyles } from "@material-ui/core/styles";
import viLocale from "date-fns/locale/vi";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-input": {
      color: "#000000",
      fontFamily: "Quicksand",
      fontSize: 16,
      fontWeight: 600,
      paddingTop: "8px",
      paddingBottom: "8px",
      zIndex: 1,
    },

    "& .MuiButtonBase-root": {
      color: "#BABABA",
      paddingTop: "6px",
      paddingBottom: "6px",
      borderRadius: 7,
      zIndex: 2,
    },
  },
});

export default function MaterialUIPickers(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(new Date());
  const setDobData = props.getDobData;

  const handleChange = (newValue) => {
    setValue(newValue);
    setDobData(newValue);
  };

  React.useEffect(() => {
    if (value) {
      setDobData(value);
    }
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={viLocale}>
      <Stack
        spacing={3}
        sx={{
          border: "2px solid #BABABA",
          background: "white",
          borderRadius: "7px",
          py: "1px",
          mt: 1,
        }}
      >
        <DatePicker
          label=""
          inputFormat="dd/MM/yyyy"
          value={value}
          variant="static"
          onChange={handleChange}
          renderInput={(params) => (
            <TextField {...params} className={classes.root} required />
          )}
          disableHighlightToday
          minDate={new Date(2011, 0, 1)}
          maxDate={new Date(2016, 11, 31)}
          openTo="year"
        />
      </Stack>
    </LocalizationProvider>
  );
}
