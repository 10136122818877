import React from "react";
import "./App.css";
import Header from "./components/Header";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import routes from "./routes";
import { Container } from "@mui/material";
import theme from "./components/layout/theme";
import { ThemeProvider } from "@mui/material/styles";
import Aos from "aos";
import "aos/dist/aos.css";
import axios from "axios";

function App() {
  var url = window.location;
  const [isAdmin, setIsAdmin] = React.useState(false);

  React.useEffect(() => {
    if (url.pathname) {
      if (url.pathname.toString().slice(0, 6) === "/admin") {
        setIsAdmin(true);
      }
    }
  }, [url]);
  const showPagesContent = (routes) => {
    var result = null;
    if (routes.length > 0) {
      result = routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            element={route.main}
          />
        );
      });
    }

    return result;
  };

  React.useEffect(() => {
    Aos.init();
    const checkingResultsAnnoucement = () => {
      axios
        .get(process.env.REACT_APP_SERVER_API_ROOT_URL + `/annoucement-time/`)
        .then((res) => {
          if (res.data?.rows.length > 0) {
            res.data?.rows.forEach((anoucementTime) => {
              if (anoucementTime.examRound.isDone) {
                return null;
              }
              var timeString =
                String(anoucementTime.dateString) +
                " " +
                String(anoucementTime.timeString) +
                ":00";

              var timeStamp = Date.parse(timeString);
              var timeNow = Date.now();
              if (timeNow >= timeStamp) {
                axios
                  .put(
                    process.env.REACT_APP_SERVER_API_ROOT_URL +
                      `/exam-round/admin/set-is-done/${anoucementTime.examRoundId}`
                  )
                  .then((res) => {});
              }
            });
          }
        });
    };
    // checkingResultsAnnoucement();
    // setInterval(checkingResultsAnnoucement, 1000 * 60);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Container
          className="App"
          maxWidth={isAdmin ? false : "xl"}
          disableGutters={true}
        >
          <Header />
          <Routes>{showPagesContent(routes)}</Routes>
        </Container>
      </Router>
    </ThemeProvider>
  );
}

export default App;
