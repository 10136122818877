import { Grid, Paper, Typography, CardMedia } from "@mui/material";
import "./WinnerCard.css";

export default function WinnerCard(props) {
  const data = props.cardData;
  const titleDisplay = (data) => {
    var title = [];
    data && title.push(data.candidateName);
    data && title.push(data.candidateSchool);
    data &&
      data.user &&
      data.user.district &&
      title.push(data.user.district.name);
    data && data.user && data.user.city && title.push(data.user.city.name);
    return title.join(" - ");
  };

  return (
    <Paper className="winner-card-wrapper" elevation={0}>
      <Grid container spacing={0}>
        <Grid item xs={12} display="flex" justifyContent="center">
          <img
            className="winner-card-img"
            alt="winner test"
            src={
              data
                ? process.env.REACT_APP_SERVER_ROOT_MEDIA_URL +
                  data.contestEntriesImages[0].image.path
                : ""
            }
            id="winner-card-img"
          />
        </Grid>
      </Grid>
      <CardMedia
        component="img"
        image="/images/Results/ic-reward.png"
        sx={{
          position: "absolute",
          zIndex: 2,
          top: 0,
          right: 0,
          width: "30% !important",
        }}
      ></CardMedia>
      <Grid item xs={12} className="winner-card-title-wrapper" sx={{ my: 3 }}>
        <Typography variant="quicksand18" color="white.main">
          {titleDisplay(data)}
        </Typography>
      </Grid>
    </Paper>
  );
}
