import { Grid } from "@mui/material";
import React from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DataGrid, viVN } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import DateTimeConvert from "../../../utils/DateTimeConvert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SuccessSnackbar from "../Common/SuccessSnackbar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const gender_vi = {
  male: "Nam",
  female: "Nữ",
};

const useStyles = makeStyles({
  root: {
    marginTop: "10px",
    "& .MuiOutlinedInput-input": {
      color: "rgba(0, 0, 0, 0.87)",
      fontFamily: "Quicksand",
      fontSize: "16px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.5) !important",
    },
  },
});

export default function JudgesManagement(props) {
  const accessToken = props.accessToken;
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:768px)");
  const [userList, setUserList] = React.useState([]);

  const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState({
    isOpen: false,
    content: "",
  });

  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const handleBackdropClose = () => {
    setBackdropOpen(false);
  };
  const handleBackdropOpen = () => {
    setBackdropOpen(true);
  };

  // begin get user detail info

  const [userDetailInfo, setUserDetailInfo] = React.useState({
    id: "",
    username: "",
    name: "",
    gender: "",
    phone: "",
    email: "",
    dob: "",
    address: "",
    role: {
      id: "",
      name: "",
    },
    district: {
      id: "",
      name: "",
    },
    city: {
      id: "",
      name: "",
    },
  });

  const [userDetailModalOpen, setUserDetailModalOpen] = React.useState(false);

  const handleUserDetailModalOpen = (userId) => {
    handleBackdropOpen();
    if (accessToken) {
      axios
        .get(process.env.REACT_APP_SERVER_API_ROOT_URL + `/users/${userId}`, {
          headers: { Authorization: `${accessToken}` },
        })
        .then((res) => {
          if (res.data) {
            setUserDetailInfo((prevState) => ({
              ...prevState,
              id: res.data.id,
              username: res.data.username,
              name: res.data.name,
              gender: res.data.gender,
              phone: res.data.phone,
              email: res.data.email,
              dob: res.data.dob
                ? DateTimeConvert.yyyymmddConvert(res.data.dob)
                : "",
              address: res.data.address,
              role: {
                ...prevState.role,
                id: res.data.role.id,
                name: res.data.role.name,
              },
              district: {
                ...prevState.district,
                id: res.data.district ? res.data.district.id : "",
                name: res.data.district ? res.data.district.name : "",
              },
              city: {
                ...prevState.city,
                id: res.data.city ? res.data.city.id : "",
                name: res.data.city ? res.data.city.name : "",
              },
            }));
            handleBackdropClose();
          }
        });
    }
    setUserDetailModalOpen(true);
  };

  const handleUserDetailModalClose = () => {
    setUserDetailModalOpen(false);
  };

  // end get user detail info

  // begin user update info
  const [Cities, setCities] = React.useState([]);
  const [Districts, setDistricts] = React.useState([]);

  const getDistrictsByCity = (cityId) => {
    setDistricts([]);
    axios
      .get(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/districts/find-by-city/${cityId}`
      )
      .then((respone) => {
        if (respone.data.rows) {
          setDistricts(respone.data.rows);
        }
      })
      .catch((error) => console.log(error));
  };

  const [userUpdateFormData, setUserUpdateFormData] = React.useState({
    name: "",
    gender: "",
    email: "",
    dob: "",
    address: "",
    districtId: "",
    cityId: "",
  });

  const handleUpdateFormNameChange = (e) => {
    setUserUpdateFormData((prevState) => ({
      ...prevState,
      name: e.target.value,
    }));
  };

  const handleUpdateFormGenderChange = (e) => {
    setUserUpdateFormData((prevState) => ({
      ...prevState,
      gender: e.target.value,
    }));
  };

  const handleUpdateFormEmailChange = (e) => {
    setUserUpdateFormData((prevState) => ({
      ...prevState,
      email: e.target.value,
    }));
  };

  const handleUpdateFormDobChange = (e) => {
    setUserUpdateFormData((prevState) => ({
      ...prevState,
      dob: e.target.value,
    }));
  };

  const handleUpdateFormAddressChange = (e) => {
    setUserUpdateFormData((prevState) => ({
      ...prevState,
      address: e.target.value,
    }));
  };

  const handleUpdateFormCityChange = (e) => {
    setUserUpdateFormData((prevState) => ({
      ...prevState,
      cityId: e.target.value,
    }));

    getDistrictsByCity(e.target.value);
  };

  const handleUpdateFormDistrictChange = (e) => {
    setUserUpdateFormData((prevState) => ({
      ...prevState,
      districtId: e.target.value,
    }));
  };

  const [isUpdateUser, setIsUpdateUser] = React.useState(false);

  const handleUpdateUser = () => {
    setIsUpdateUser(true);
    setCities([]);
    axios
      .get(process.env.REACT_APP_SERVER_API_ROOT_URL + `/cities`)
      .then((res) => {
        if (res.data) {
          setCities(res.data.rows);
        }
      });

    setUserUpdateFormData((prevState) => ({
      ...prevState,
      name: userDetailInfo.name,
      gender: userDetailInfo.gender,
      email: userDetailInfo.email,
      dob: DateTimeConvert.ddmmyyyyConvert(userDetailInfo.dob),
      address: userDetailInfo.address,
      cityId: userDetailInfo.city.id,
    }));
    getDistrictsByCity(userDetailInfo.city.id);
    setUserUpdateFormData((prevState) => ({
      ...prevState,
      districtId: userDetailInfo.district.id,
    }));
  };

  const handleCancelUpdateUser = () => {
    setIsUpdateUser(false);
  };

  const handleUserUpdateFormSubmit = () => {
    axios
      .put(
        process.env.REACT_APP_SERVER_API_ROOT_URL +
          `/users/?id=${userDetailInfo.id}`,
        userUpdateFormData,
        {
          headers: { Authorization: `${accessToken}` },
        }
      )
      .then((res) => {
        if (res.data) {
          handleCancelUpdateUser();
          getUserTable();
          handleUserDetailModalOpen(res.data.id);
          setUserUpdateFormData((prevState) => ({
            ...prevState,
            name: "",
            gender: "",
            email: "",
            dob: "",
            address: "",
            districtId: "",
            cityId: "",
          }));
          setSuccessSnackbarOpen((prevState) => ({
            ...prevState,
            isOpen: true,
            content: "Cập nhật thông tin thành công !",
          }));
        }
      });
  };

  // end user update info

  // begin create user
  const [userCreateModalOpen, setUserCreateModalOpen] = React.useState(false);

  const [listRole, setListRole] = React.useState([]);

  const handleUserCreateModalOpen = () => {
    setUserCreateModalOpen(true);
    setCities([]);
    setUserCreateFormData((prevState) => ({
      ...prevState,
      username: "",
      name: "",
      gender: "",
      phone: "",
      email: "",
      dob: "",
      address: "",
      cityId: "",
      districtId: "",
      roleId: "",
    }));
    axios
      .get(process.env.REACT_APP_SERVER_API_ROOT_URL + `/cities`)
      .then((res) => {
        if (res.data) {
          setCities(res.data.rows);
        }
      });

    axios
      .get(process.env.REACT_APP_SERVER_API_ROOT_URL + `/roles`)
      .then((res) => {
        if (res.data) {
          var listRole = [];
          res.data.rows.forEach((role) => {
            if (role.name === "Giám khảo") {
              listRole.push(role);
            }
          });
          setListRole(listRole);
        }
      });
  };

  const handleUserCreateModalClose = () => {
    setUserCreateModalOpen(false);
  };

  const [userCreateFormData, setUserCreateFormData] = React.useState({
    username: "",
    name: "",
    gender: "",
    phone: "",
    password: "123456",
  });

  const handleCreateFormNameChange = (e) => {
    setUserCreateFormData((prevState) => ({
      ...prevState,
      name: e.target.value,
    }));
    if (createFormError.field === "name") {
      setCreateFormError((prevState) => ({
        ...prevState,
        field: "",
        text: "",
      }));
    }
  };

  const handleCreateFormUserNameChange = (e) => {
    setUserCreateFormData((prevState) => ({
      ...prevState,
      username: e.target.value,
    }));
    if (createFormError.field === "username") {
      setCreateFormError((prevState) => ({
        ...prevState,
        field: "",
        text: "",
      }));
    }
    if (createFormError.field === "username-phone") {
      setCreateFormError((prevState) => ({
        ...prevState,
        field: "",
        text: "",
      }));
    }
  };

  const handleCreateFormPhoneChange = (e) => {
    setUserCreateFormData((prevState) => ({
      ...prevState,
      phone: e.target.value,
    }));
    if (createFormError.field === "phone") {
      setCreateFormError((prevState) => ({
        ...prevState,
        field: "",
        text: "",
      }));
    }
    if (createFormError.field === "username-phone") {
      setCreateFormError((prevState) => ({
        ...prevState,
        field: "",
        text: "",
      }));
    }
  };

  const handleCreateFormRoleChange = (e) => {
    setUserCreateFormData((prevState) => ({
      ...prevState,
      roleId: e.target.value,
    }));
    if (createFormError.field === "roleId") {
      setCreateFormError((prevState) => ({
        ...prevState,
        field: "",
        text: "",
      }));
    }
  };

  const [createFormError, setCreateFormError] = React.useState({
    field: "",
    text: "",
  });

  const handleUserCreateFormSubmit = () => {
    if (!userCreateFormData.username) {
      setCreateFormError((prevState) => ({
        ...prevState,
        field: "username",
        text: "Tên đăng nhập",
      }));
      return null;
    }
    if (!userCreateFormData.name) {
      setCreateFormError((prevState) => ({
        ...prevState,
        field: "name",
        text: "Họ và tên",
      }));
      return null;
    }
    if (!userCreateFormData.phone) {
      setCreateFormError((prevState) => ({
        ...prevState,
        field: "phone",
        text: "Số điện thoại",
      }));
      return null;
    }
    if (!userCreateFormData.roleId) {
      setCreateFormError((prevState) => ({
        ...prevState,
        field: "roleId",
        text: "Chức danh",
      }));
      return null;
    }
    handleBackdropOpen();
    axios
      .post(
        process.env.REACT_APP_SERVER_API_ROOT_URL + `/users/sign-up`,
        userCreateFormData,
        {
          headers: { Authorization: `${accessToken}` },
        }
      )
      .then((res) => {
        if (res.data) {
          handleBackdropClose();
          getUserTable();
          handleUserCreateModalClose();
          setSuccessSnackbarOpen((prevState) => ({
            ...prevState,
            isOpen: true,
            content: "Tạo mới người dùng thành công !",
          }));
        }
      })
      .catch((error) => {
        setCreateFormError((prevState) => ({
          ...prevState,
          field: "username-phone",
          text: "Tên người dùng hoặc số điện thoại này đã tồn tại !",
        }));
        handleBackdropClose();
      });
  };

  // end create user

  // begin reset password

  const [resetPasswordConfirmModalOpen, setResetPasswordConfirmModalOpen] =
    React.useState(false);

  const handleResetPasswordConfirmModalOpen = () => {
    setResetPasswordConfirmModalOpen(true);
  };

  const handleResetPasswordConfirmModalClose = () => {
    setResetPasswordConfirmModalOpen(false);
  };

  const handleResetPassword = (userId) => {
    if (accessToken) {
      axios
        .put(
          process.env.REACT_APP_SERVER_API_ROOT_URL +
            `/users/reset-password/${userId}`,
          {},
          {
            headers: { Authorization: `${accessToken}` },
          }
        )
        .then((res) => {
          if (res.data) {
            handleResetPasswordConfirmModalClose();
            setSuccessSnackbarOpen((prevState) => ({
              ...prevState,
              isOpen: true,
              content:
                "Reset mật khẩu người dùng thành công !, mật khẩu mới là 123456",
            }));
          }
        });
    }
  };

  // end reset password

  // begin judges management features expand

  const [isJudgesAdminExpand, setJudgesAdminExpand] = React.useState(false);

  // end judges management features expand

  // begin init data

  const getUserTable = () => {
    handleBackdropOpen();
    if (accessToken) {
      axios
        .get(process.env.REACT_APP_SERVER_API_ROOT_URL + `/users/judgements`, {
          headers: { Authorization: `${accessToken}` },
        })
        .then((res) => {
          if (res.data) {
            setUserList(res.data.rows);
            handleBackdropClose();
          } else {
            handleBackdropClose();
          }
        });
    }
  };

  React.useEffect(() => {
    getUserTable();
  }, []);

  const columns = [
    {
      field: "username",
      headerName: "Tên tài khoản",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: "",
      headerName: "Thao tác",
      headerClassName: "super-app-theme--header",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <Button
          fullWidth
          onClick={() => {
            handleUserDetailModalOpen(params.row.id);
          }}
          variant="contained"
          color="info"
          size="small"
        >
          Xem chi tiết
        </Button>
      ),
    },
    {
      field: "name",
      headerName: "Họ và tên",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: "gender",
      headerName: "Giới tính",
      headerClassName: "super-app-theme--header",
      width: 100,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {gender_vi[params.value]}
        </Typography>
      ),
    },
    {
      field: "phone",
      headerName: "Số điện thoại",
      headerClassName: "super-app-theme--header",
      type: "number",
      width: 150,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "super-app-theme--header",
      type: "text",
      width: 250,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: "dob",
      headerName: "Ngày sinh",
      headerClassName: "super-app-theme--header",
      type: "text",
      width: 150,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {DateTimeConvert.yyyymmddConvert(params.value)}
        </Typography>
      ),
    },
    {
      field: "address",
      headerName: "Địa chỉ",
      headerClassName: "super-app-theme--header",
      type: "text",
      width: 400,
      renderCell: (params) => (
        <Typography variant={isMobile ? "body2" : "body1"}>
          {params.row.address}
          {params.row.district ? ", " + params.row.district.name : ""}
          {params.row.city ? ", " + params.row.city.name : ""}
        </Typography>
      ),
    },
  ];

  // end init data

  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
        onClick={handleBackdropClose}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <SupervisedUserCircleIcon
              fontSize={isMobile ? "medium" : "large"}
            />
            <Typography
              variant={isMobile ? "body1" : "quicksand18"}
              style={{
                marginLeft: "10px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              Quản lý giám khảo
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={4}>
            <Accordion
              expanded={isJudgesAdminExpand}
              onChange={() => {
                setJudgesAdminExpand(!isJudgesAdminExpand);
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <AdminPanelSettingsIcon fontSize="medium" />
                  <Typography
                    variant={isMobile ? "body2" : "body1"}
                    style={{ marginLeft: "10px", fontWeight: "bold" }}
                  >
                    Quản trị viên
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container justifyContent="flex-start" spacing={2}>
                  <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                    <Button
                      onClick={handleUserCreateModalOpen}
                      startIcon={<PersonAddIcon />}
                      fullWidth={true}
                      size={isMobile ? "medium" : "large"}
                      color="info"
                      variant="contained"
                    >
                      Thêm mới tài khoản giám khảo
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={4} style={{ padding: "0px" }}>
            <Box
              sx={{
                height: 600,
                width: 1,
                "& .super-app-theme--header": {
                  fontWeight: "bolder",
                  fontSize: "16px",
                  color: "#000000",
                },
              }}
            >
              <DataGrid
                localeText={viVN.components.MuiDataGrid.defaultProps.localeText}
                rows={userList}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={userDetailModalOpen}
        onClose={handleUserDetailModalClose}
      >
        <DialogContent>
          {isUpdateUser ? (
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                  Họ và tên:
                </Typography>
                <TextField
                  value={userUpdateFormData.name}
                  onChange={handleUpdateFormNameChange}
                  className={classes.root}
                  size="small"
                  color="primary"
                  type="text"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                  Giới tính:
                </Typography>
                <FormControl className={classes.root} fullWidth size="small">
                  <Select
                    value={userUpdateFormData.gender}
                    onChange={handleUpdateFormGenderChange}
                  >
                    <MenuItem value="male">Nam</MenuItem>
                    <MenuItem value="female">Nữ</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                  Email:
                </Typography>
                <TextField
                  value={userUpdateFormData.email}
                  onChange={handleUpdateFormEmailChange}
                  className={classes.root}
                  size="small"
                  color="primary"
                  type="email"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                  Ngày sinh:
                </Typography>
                <TextField
                  value={userUpdateFormData.dob}
                  onChange={handleUpdateFormDobChange}
                  className={classes.root}
                  size="small"
                  color="primary"
                  type="date"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                  Địa chỉ:
                </Typography>
                <TextField
                  value={userUpdateFormData.address}
                  onChange={handleUpdateFormAddressChange}
                  className={classes.root}
                  size="small"
                  color="primary"
                  type="text"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                  Tình/Thành phố:
                </Typography>
                <FormControl className={classes.root} fullWidth size="small">
                  <Select
                    value={userUpdateFormData.cityId}
                    onChange={handleUpdateFormCityChange}
                  >
                    {Cities?.length > 0 &&
                      Cities.map((city) => {
                        return (
                          <MenuItem key={city.id} value={city.id}>
                            {city.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                  Quận/Huyện:
                </Typography>
                <FormControl className={classes.root} fullWidth size="small">
                  <Select
                    value={userUpdateFormData.districtId}
                    onChange={handleUpdateFormDistrictChange}
                  >
                    {Districts?.length > 0 &&
                      Districts.map((district) => {
                        return (
                          <MenuItem key={district.id} value={district.id}>
                            {district.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          ) : (
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} display="flex" justifyContent="center">
                <Typography
                  style={{ fontWeight: "bold" }}
                  align="center"
                  variant={isMobile ? "quicksand16" : "quicksand20"}
                >
                  Thông tin chi tiết giám khảo
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                  Tên tài khoản: {userDetailInfo.username}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                  Họ và tên: {userDetailInfo.name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                  Giới tính: {gender_vi[userDetailInfo.gender]}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                  Số điện thoại: {userDetailInfo.phone}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                  Email: {userDetailInfo.email}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                  Ngày sinh: {userDetailInfo.dob}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                  Địa chỉ liên lạc: {userDetailInfo.address}
                  {userDetailInfo.district.id
                    ? ", " + userDetailInfo.district.name
                    : ""}
                  {userDetailInfo.city.id
                    ? ", " + userDetailInfo.city.name
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                  Vai trò: {userDetailInfo.role?.name}
                </Typography>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions style={{ padding: "15px" }}>
          {isMobile ? (
            isUpdateUser ? (
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={handleUserUpdateFormSubmit}
                  >
                    Xác nhận
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="semiBlack"
                    size="medium"
                    onClick={handleCancelUpdateUser}
                  >
                    Hủy
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item xs={12}>
                  <Button
                    onClick={() => {
                      handleResetPasswordConfirmModalOpen();
                    }}
                    fullWidth
                    variant="contained"
                    color="success"
                    size="medium"
                  >
                    Reset mật khẩu
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={handleUpdateUser}
                  >
                    Cập nhật thông tin
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="semiBlack"
                    size="medium"
                    onClick={handleUserDetailModalClose}
                  >
                    Đóng
                  </Button>
                </Grid>
              </Grid>
            )
          ) : isUpdateUser ? (
            <React.Fragment>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={handleUserUpdateFormSubmit}
              >
                Xác nhận
              </Button>
              <Button
                variant="contained"
                color="semiBlack"
                size="medium"
                onClick={handleCancelUpdateUser}
              >
                Hủy
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Button
                onClick={() => {
                  handleResetPasswordConfirmModalOpen();
                }}
                variant="contained"
                color="success"
                size="medium"
              >
                Reset mật khẩu
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={handleUpdateUser}
              >
                Cập nhật thông tin
              </Button>
              <Button
                variant="contained"
                color="semiBlack"
                size="medium"
                onClick={handleUserDetailModalClose}
              >
                Đóng
              </Button>
            </React.Fragment>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={userCreateModalOpen}
        onClose={handleUserCreateModalClose}
      >
        <DialogContent>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                Tên đăng nhập:
              </Typography>
              <TextField
                value={userCreateFormData.username}
                onChange={handleCreateFormUserNameChange}
                className={classes.root}
                size="small"
                color="primary"
                type="text"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                Họ và tên:
              </Typography>
              <TextField
                value={userCreateFormData.name}
                onChange={handleCreateFormNameChange}
                className={classes.root}
                size="small"
                color="primary"
                type="text"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                Số điện thoại:
              </Typography>
              <TextField
                value={userCreateFormData.phone}
                onChange={handleCreateFormPhoneChange}
                className={classes.root}
                size="small"
                color="primary"
                type="tel"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={isMobile ? "quicksand16" : "quicksand18"}>
                Chức danh:
              </Typography>
              <FormControl className={classes.root} fullWidth size="small">
                <Select
                  value={userCreateFormData.roleId}
                  onChange={handleCreateFormRoleChange}
                >
                  {listRole?.length > 0 &&
                    listRole.map((role) => {
                      return (
                        <MenuItem key={role.id} value={role.id}>
                          {role.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: "15px" }}>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={12}>
              {createFormError.field &&
                createFormError.text &&
                (createFormError.field === "username-phone" ? (
                  <Alert
                    severity="error"
                    style={{ fontSize: `${isMobile ? "13px" : "16px"}` }}
                  >
                    <AlertTitle
                      style={{ fontSize: `${isMobile ? "13px" : "16px"}` }}
                    >
                      <strong>{createFormError.text}</strong>
                    </AlertTitle>
                  </Alert>
                ) : (
                  <Alert
                    severity="error"
                    style={{ fontSize: `${isMobile ? "13px" : "16px"}` }}
                  >
                    <AlertTitle
                      style={{ fontSize: `${isMobile ? "13px" : "16px"}` }}
                    >
                      Không được bỏ trống phần{" "}
                      <strong>{createFormError.text}</strong>
                    </AlertTitle>
                  </Alert>
                ))}
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="success"
                    size="medium"
                    onClick={handleUserCreateFormSubmit}
                  >
                    Tạo người dùng
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="semiBlack"
                    size="medium"
                    onClick={handleUserCreateModalClose}
                  >
                    Đóng
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={resetPasswordConfirmModalOpen}
        onClose={handleResetPasswordConfirmModalClose}
      >
        <DialogContent>
          <Alert
            severity="warning"
            style={{ fontSize: `${isMobile ? "13px" : "16px"}` }}
          >
            <AlertTitle style={{ fontSize: `${isMobile ? "13px" : "16px"}` }}>
              <strong>Xác nhận reset mật khẩu</strong>
            </AlertTitle>
            Bạn có muốn reset mật khẩu cho tài khoản này, mật khẩu mới sẽ là{" "}
            <strong>123456</strong>
          </Alert>
        </DialogContent>
        <DialogActions style={{ padding: "15px" }}>
          <Button
            variant="contained"
            color="success"
            size="medium"
            onClick={() => {
              handleResetPassword(userDetailInfo.id);
            }}
          >
            Xác nhận
          </Button>
          <Button
            variant="contained"
            color="semiBlack"
            size="medium"
            onClick={handleResetPasswordConfirmModalClose}
          >
            Hủy
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessSnackbar
        isOpen={successSnackbarOpen.isOpen}
        content={successSnackbarOpen.content}
        onClose={() => {
          setSuccessSnackbarOpen((prevState) => ({
            ...prevState,
            isOpen: false,
            content: "",
          }));
        }}
      />
    </React.Fragment>
  );
}
