const DateTimeConvert = {}

DateTimeConvert.yyyymmddConvert = (date) => {
    if (!date) {
        return ''
    }
    var p = date.split(/\D/g)
    return [p[2], p[1], p[0]].join("/")
}

DateTimeConvert.ddmmyyyyConvert = (date) => {
    if (!date) {
        return ''
    }
    var p = date.split(/\D/g)
    return [p[2], p[1], p[0]].join("-")
}


DateTimeConvert.mySqlDateConvert = (inputDate) => {
    if (!inputDate) {
        return ''
    }
    var year = inputDate.toString().slice(0, 4)
    var month = inputDate.toString().slice(5, 7)
    var day = inputDate.toString().slice(8, 10)

    return day.concat('/').concat(month).concat('/').concat(year)
}

const objectDateFormat = (date) => {
    if (!date) {
        return ''
    }
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('/');
}

DateTimeConvert.roundResultDateDisplay = (date) => {
    if (!date) {
        return ''
    }
    var newDate = new Date(date)
    var lastDow = new Date(newDate.setDate(newDate.getDate() + 6))
    var dateArr = date.split('-')
    return "Từ ngày ".concat(dateArr[2]).concat('/').concat(dateArr[1]).concat('/').concat(dateArr[0]).concat(" đến ").concat(objectDateFormat(lastDow))
}

module.exports = DateTimeConvert