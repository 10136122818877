import SubmitExamForm from "../components/SubmitExam/SubmitExamForm";
import React, { useEffect } from "react";
import {
  CardMedia,
  Container,
  Box,
  Grid,
  Typography,
  Card,
  Button,
} from "@mui/material";
import "../components/SubmitExam/SubmitExam.css";
import Footer from "../components/Footer";
import { makeStyles } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    backgroundColor: "transparent",
    borderRadius: 0,
    textAlign: "center",
  },

  buttonBackHome: {
    textDecoration: "none",
    position: "absolute",
    top: "75%",
    left: 0,
    right: 0,
  },
  font: {
    textAlign: "center",
    position: "absolute",
    top: "60%",
    left: 0,
    width: "100%",
    fontWeight: "500",
    backgroundColor: "transparent",
  },
  font2: {
    textAlign: "center",
    position: "absolute",
    top: "40%",
    left: 0,
    right: 0,
    margin: "auto",
    width: "55%",
    fontWeight: "500",
    zIndex: 5,
    backgroundColor: "transparent",
  },
}));

export default function SubmitExam() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:768px)");

  const [isSubmitedSuccess, setIsSubmitedSuccess] = React.useState(false);

  const [contestEntryCreated, setContestEntryCreated] = React.useState(null);

  const updateIsSubmited = (data) => {
    setIsSubmitedSuccess(data);
  };

  const updateContestEntryCreated = (contestEntry) => {
    setContestEntryCreated(contestEntry);
  };

  // scroll to Contest entry Submission success announcement

  const resultRef = React.useRef(null);

  React.useEffect(() => {
    if (isSubmitedSuccess) {
      setTimeout(() => {
        resultRef.current.scrollIntoView();
      }, 500);
    }
  }, [isSubmitedSuccess]);
  return (
    <React.Fragment>
      <Container className="wrapper" maxWidth="xl" disableGutters={true}>
        <CardMedia
          component="img"
          image={
            isMobile
              ? "/images/SubmitExam/bg-top.png"
              : "/images/SubmitExam/bg-top.png"
          }
          sx={{ position: "relative", zIndex: 1 }}
        ></CardMedia>
        <CardMedia
          component="img"
          image="/images/SubmitExam/bg-grid.png"
          sx={{ position: "absolute", bottom: 0 }}
        ></CardMedia>
        <CardMedia
          component="img"
          image="/images/SubmitExam/bg-gradient.png"
          className="gradient"
        ></CardMedia>
        {isSubmitedSuccess ? (
          <Grid ref={resultRef} container style={{ position: "relative" }}>
            <Grid
              container
              rowSpacing={20}
              justifyContent="center"
              className="successBg"
            >
              <CardMedia
                component="img"
                image="/images/SubmitExam/bg-success.png"
                className="successBg"
              ></CardMedia>
              <Grid item md={6} style={{ position: "absolute", mt: "10%" }}>
                <Card className={classes.root} elevation={0}>
                  <CardMedia
                    component="img"
                    image="/images/SubmitExam/bg-success-notice.png"
                    className="successBg"
                  ></CardMedia>
                  <Typography variant="quicksand18" className={classes.font}>
                    Mã tham gia:
                    <span>
                      <Typography
                        variant="quicksand18"
                        sx={{ fontWeight: "700", paddingLeft: "5px" }}
                      >
                        {contestEntryCreated?.code || ""}
                      </Typography>
                    </span>
                    <br />
                    <span>
                      <Typography
                        variant="quicksand18"
                        sx={{ fontWeight: "700" }}
                      >
                        Thí sinh dùng mã tham gia để tra cứu bài tham gia của mình
                      </Typography>
                    </span>
                  </Typography>
                  <Link to="/" className={classes.buttonBackHome}>
                    <Button
                      type="submit"
                      color="lightGreen"
                      variant="contained"
                      className="back-to-home-page-button"
                    >
                      Trở về trang chủ
                    </Button>
                  </Link>
                </Card>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              className="successBgMobile"
              style={{ display: "none" }}
            >
              <Grid item xs={12} md={0}>
                <CardMedia
                  component="img"
                  image="/images/SubmitExam/bg-success-notice-mobile.png"
                ></CardMedia>
                <Typography variant="quicksand13" className={classes.font2}>
                  Mã tham gia:
                  <span>
                    <Typography
                      variant="quicksand13"
                      sx={{ fontWeight: "900", ml: "2px", lineHeight: "2" }}
                    >
                      {contestEntryCreated?.code || ""}
                    </Typography>
                  </span>
                  <br />
                  <span>
                    <Typography
                      variant="quicksand13"
                      sx={{ fontWeight: "900", ml: "2px" }}
                    >
                      Thí sinh dùng mã tham gia để tra cứu bài tham gia của mình
                    </Typography>
                  </span>
                </Typography>
                <Grid item justifyContent="center" xs={12} md={0}>
                  <Link to="/" style={{ textDecoration: "none" }}>
                    <Button
                      type="submit"
                      color="lightGreen"
                      variant="contained"
                      sx={{ fontSize: "17px" }}
                      className="back-to-home-page-button"
                    >
                      Trở về trang chủ
                    </Button>
                  </Link>
                </Grid>
                <Grid container justifyContent="flex-end">
                  <Grid item xs={4} md={0} lg={0}>
                    <CardMedia
                      component="img"
                      image="/images/SubmitExam/img-artboard.png"
                    ></CardMedia>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <React.Fragment>
            <Box className="book">
              <CardMedia
                component="img"
                image="/images/SubmitExam/img-book.png"
                sx={{
                  width: "70%",
                  position: "absolute",
                  top: "15%",
                  left: "5%",
                }}
              ></CardMedia>
              <CardMedia
                component="img"
                image="/images/SubmitExam/img-pencil.png"
                sx={{ width: "30%", position: "absolute", top: 0, left: "50%" }}
              ></CardMedia>
            </Box>
            <SubmitExamForm
              getIsSubmited={updateIsSubmited}
              getContestEntryCreated={updateContestEntryCreated}
            />
          </React.Fragment>
        )}
        {isMobile ? (
          <Grid
            container
            justifyContent="center"
            sx={{ position: "relative", paddingBottom: 10, mt: 5 }}
          >
            <CardMedia
              component="img"
              image="/images/footer-bg-mobile.png"
              sx={{ mt: "220px", position: "absolute", bottom: 0 }}
            ></CardMedia>
            <Footer />
          </Grid>
        ) : (
          <div>
            {isSubmitedSuccess ? (
              <CardMedia
                component="img"
                image="/images/footer-landscape-background.png"
                sx={{ position: "relative" }}
              ></CardMedia>
            ) : (
              <CardMedia
                component="img"
                image="/images/footer-bg-2.png"
                sx={{ position: "relative" }}
              ></CardMedia>
            )}
            <Grid
              container
              justifyContent="center"
              sx={{ position: "absolute", bottom: "100px" }}
            >
              <Footer />
            </Grid>
          </div>
        )}
      </Container>
    </React.Fragment>
  );
}
