import React from "react";
import { Paper, Grid, Typography, Button } from "@mui/material";

export default function WeekRoundPrizesNumberCard(props) {
  const { text, number, maxNumber } = props;
  return (
    <Paper elevation={4} style={{ padding: "0px 15px 0px 15px" }}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Button
            variant="text"
            color={number === maxNumber ? "success" : "primary"}
          >
            <Typography
              variant="body2"
              style={{ textTransform: "uppercase", fontWeight: "bold" }}
            >
              {text} {number === maxNumber ? "(đã đủ)" : "(chưa đủ)"}:
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography align="right" variant="h6" style={{ fontWeight: "bold" }}>
            {String(number).concat("/").concat(String(maxNumber))}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
